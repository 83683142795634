import { DocCategory } from 'models/DocCategory';
import { UserType } from 'models/Enums';
import { DocumentationStatus } from 'models/documentation/Documentation';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { DocumentationFlowRole, DocumentationRole } from 'user/permissions/model';

export const getUserTypeTK = (t: UserType) => 'user_type.' + t;
export const getUserRoleTK = (r: DocumentationRole | DocumentationFlowRole) => 'user_role.' + r;
export const getDocCategoryTK = (r: DocCategory) => 'document_category.' + r;
export const getDocumentationStatusTK = (r: DocumentationStatus) => 'documentation_status.' + r;
export const getAttributeBooleanTK = (r: string) => 'attribute_boolean.' + r;

export const getAttributeNameTK = (attribute: Pick<DocumentAttribute, 'name' | 'category'>) =>
  `default_attributes.attribute.${attribute.category}:${attribute.name}`;

export const getAttributeCategoryTK = (category: string) => `default_attributes.category.${category}`;
