import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { Alert, AlertTitle } from '@material-ui/lab';

import { useMemberships } from '../../../hooks/useMemberships';

export const UserDashboard: FC = () => {
  const { t } = useTranslation();
  const memberships = useMemberships();

  if (memberships.length < 1) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Alert severity={'info'}>
          <AlertTitle>{t('userDashboard.noOrgsTitle')}</AlertTitle>
          <div>{t('userDashboard.noOrgsContent1')}</div>
          <br />
          <div>{t('userDashboard.noOrgsContent2')}</div>
        </Alert>
      </div>
    );
  }

  // todo this should be eventually removed when we're gonna clean up the mess with the app state that is all over the place
  return <Redirect to={`/organization/${memberships[0].organizationId}/${memberships[0].id}`} />;
};

export default UserDashboard;
