import { ActionType } from '../action-types/action-types';
import { ActionEnum } from '../models/action.enums';
import { Settings } from '../models/settings.state';

const defaultState: Settings = {
  allColumns: [],
  columnsToDisplay: {
    id: '',
    columns: [],
  },
};

const settings = (state = defaultState, action: ActionType): Settings => {
  switch (action.type) {
    case ActionEnum.SET_ALL_COLUMNS: {
      const newColumnsToDisplay = [...action.payload];
      return { ...state, allColumns: newColumnsToDisplay };
    }
    case ActionEnum.SET_COLUMNS_TO_DISPLAY: {
      const newColumnsToDisplay = [...action.payload.columns];
      const updatedColumns = { ...state.columnsToDisplay };
      updatedColumns.id = action.payload.id;
      updatedColumns.columns = newColumnsToDisplay;

      return { ...state, columnsToDisplay: updatedColumns };
    }
    case ActionEnum.CHANGE_COLUMNS_TO_DISPLAY: {
      const newColumnsToDisplay = [...action.payload];
      const updatedColumns = { ...state.columnsToDisplay };
      updatedColumns.columns = newColumnsToDisplay;
      return { ...state, columnsToDisplay: updatedColumns };
    }
    default:
      return state;
  }
};

export default settings;
