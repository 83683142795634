import React, { useEffect, useRef, useState } from 'react';

interface StyledSelectProps {
  size: 'medium' | 'large';
  wide?: boolean;
  label?: string;
  disabled?: boolean;
  value?: string;
  // WARNING! this onChange is not standard React Event! It's props method to handler local action
  // onChange?: any;
  placeholder?: string;
  options: string[];
  isGray?: boolean;
  setSelectValue?: (value: any) => void;
}

const Styledselect = (props: StyledSelectProps): JSX.Element => {
  const [isFocused, setFocusedstatus] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<string>(props.options[0]);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      setFocusedstatus(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return (): void => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [props.options]);

  const handlerChangeoption = (event: any): void => {
    setFocusedstatus(false);
    setSelectValue(event.target.dataset.option);
    if (props.setSelectValue) {
      props.setSelectValue(event.target.dataset.option || props.options[0]);
    }
  };

  const options = props.options.map((option: string, index: number) => (
    <li key={index} className="select__section__item">
      <p className="select__section__option" data-option={option} onClick={(event): void => handlerChangeoption(event)}>
        {option}
      </p>
    </li>
  ));

  return (
    <div className={`select__section ${props.size}`}>
      <div className={`select__section__label ${props.size}`}>{props.label}</div>
      <div ref={ref} className={`select__section__wrapper${isFocused ? '--active' : ''} ${props.size}`}>
        <div
          className={`select__section__field${props.isGray ? '--gray' : ''}`}
          onChange={(): void => setFocusedstatus(!isFocused)}
        >
          <p
            className={`select__section__selected${props.isGray ? '--gray' : ''}`}
            onClick={(): void => setFocusedstatus(!isFocused)}
          >
            {selectValue}
          </p>
          <ul className={`select__section__select`} style={isFocused ? { display: 'block' } : { display: 'none' }}>
            {options}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Styledselect;
