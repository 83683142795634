import { DocumentStyleFont } from 'models/DocumentStyleFont';

export const fontsToFontFaceCss = (fonts: DocumentStyleFont[] | null) => {
  if (!fonts) {
    return '';
  }
  return fonts
    .map(
      (f) => `@font-face {
  font-family: '${f.fontFamily}';
  font-style: ${f.style};
  font-weight: ${f.weight};
  font-display: swap;
  src: ${f.fileLinks.map((url) => `url('${url}')`).join(', ')}
}`
    )
    .join('\n');
};
