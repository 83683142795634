import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Divider } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { OrganizationContextValue } from '../../../../../contexts/organization';
import { ReactComponent as DocumentationList } from '../../../../../images/documentation_list.svg';
import { Paths } from '../../../../../router/paths';
import { white } from '../../../../../styles';
import { OwnerGroupOnly } from '../../../Table/TableCells/OwnerOnly';
import { SidePanelIcon } from './SidePanelIcon';

interface Props {
  organization: OrganizationContextValue;
}

export const UserIcons: FC<Props> = ({ organization }) => {
  const { t } = useTranslation();

  if (!organization) {
    return null;
  }

  const organizationParams = { organizationId: organization.organizationId, membershipId: organization.membershipId };

  return (
    <>
      <OwnerGroupOnly>
        <SidePanelIcon
          tooltip={t('navigation_bar.manage_dropdown.attributes', 'Attributes')}
          icon={<ListAltIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
          to={generatePath(Paths.ORGANIZATION_ATTRIBUTES, organizationParams)}
        />
        <SidePanelIcon
          tooltip={t('navigation_bar.manage_dropdown.users', 'Users')}
          icon={<PeopleIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
          to={generatePath(Paths.ORGANIZATION_USERS, organizationParams)}
        />
        <Divider style={{ backgroundColor: white, margin: '0 8px' }} />
        <SidePanelIcon
          tooltip={t('navigation_bar.manage_dropdown.manage_document_templates', 'Manage document templates')}
          icon={<InsertDriveFileIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
          to={generatePath(Paths.DOCUMENT_TEMPLATES, organizationParams)}
        />
        <SidePanelIcon
          tooltip={t('navigation_bar.manage_dropdown.manage_agreement_templates', 'Manage agreement templates')}
          icon={<FileCopyIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
          to={generatePath(Paths.AGREEMENT_TEMPLATES, organizationParams)}
        />
      </OwnerGroupOnly>

      <SidePanelIcon
        tooltip={t('navigation_bar.documentations', 'Documentations')}
        icon={<DocumentationList style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
        to={generatePath(Paths.DOCUMENTATION_LIST, organizationParams)}
        exact={true}
      />

      <OwnerGroupOnly>
        <SidePanelIcon
          tooltip={t('mass_actions.page.title', 'Mass actions')}
          icon={<OfflineBoltIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
          to={generatePath(Paths.MASS_ACTIONS, organizationParams)}
          exact={true}
        />
      </OwnerGroupOnly>

      <Divider style={{ backgroundColor: white, margin: '0 8px' }} />

      <SidePanelIcon
        tooltip={t('side_panel.settings_tooltip', 'Settings')}
        icon={<SettingsIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
        to={generatePath(Paths.MEMBER_SETTINGS, organizationParams)}
        exact={true}
      />
    </>
  );
};
