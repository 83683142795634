import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar, IconButton, MenuItem, Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';

import { useAuth } from '../../../auth';

interface NavbarDropdownMenuProps {}

export const NavbarDropdownMenu: FC<NavbarDropdownMenuProps> = () => {
  const ref = useRef(null);
  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div style={{ cursor: 'pointer' }}>
      <Tooltip title={`${t('account_actions.tooltip')} ${user?.firstName} ${user?.lastName} (${user?.email})`}>
        <IconButton ref={ref} size="small" onClick={() => setOpened(true)}>
          <Avatar>
            {user?.firstName?.charAt(0)}
            {user?.lastName?.charAt(0)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu anchorEl={ref.current} open={opened} onClose={() => setOpened(false)}>
        <MenuItem component={Link} to="/logout" onClick={() => setOpened(false)}>
          {t('navigation_bar.dropdown.logout', 'Log out')}
        </MenuItem>
      </Menu>
    </div>
  );
};
