import { keyBy } from 'lodash';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import React, { FC, useMemo } from 'react';
import { Controller, UseFormMethods, useFieldArray, useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { BusyButton } from '../BusyButton/BusyButton';
import { DocumentStyle, DocumentStyleFormData } from 'models/DocumentStyle';
import { DocumentStyleFont } from 'models/DocumentStyleFont';

export interface DocumentStyleFormProps {
  onSubmit: (data: DocumentStyleFormData) => void;
  defaultValues: DocumentStyle;
  loading: boolean;
  fonts: DocumentStyleFont[];
}

const prepareData = (
  data: Omit<DocumentStyleFormData, 'options'> & { options: Partial<DocumentStyleFormData['options']> }
): DocumentStyleFormData => ({
  ...data,
  options: {
    color: [],
    customComponents: [],
    fontFamily: [],
    fontSize: [],
    ...data.options,
  },
});

const getFontLabel = (f: DocumentStyleFont) => `${f.fontFamily}: ${f.weight}, ${f.style}`;

export const DocumentStyleForm: FC<DocumentStyleFormProps> = ({ fonts, onSubmit, defaultValues, loading }) => {
  const form = useForm({
    defaultValues,
  });

  const { register, control, handleSubmit, setValue, getValues } = form;

  const indexedFonts = useMemo(() => keyBy(fonts, 'id'), [fonts]);

  return (
    <form onSubmit={handleSubmit((d) => onSubmit(prepareData(d)))}>
      <Box my={2}>
        <TextField
          disabled={loading}
          variant="outlined"
          fullWidth
          label="Style name"
          name="name"
          inputRef={register()}
        />
      </Box>
      <Box>
        <Grid container spacing={2}>
          {['top', 'bottom', 'left', 'right'].map((type) => {
            return (
              <Grid item key={type}>
                <TextField
                  disabled={loading}
                  variant="outlined"
                  label={`${capitalize(type)} margin`}
                  name={`margin.${type}`}
                  inputRef={register()}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {[
        {
          name: 'color',
          label: 'Color palette',
          valueInputProps: { type: 'color' },
        },
        { name: 'fontFamily', label: 'Font family' },
        { name: 'fontSize', label: 'Font sizes' },
        {
          name: 'customComponents',
          label: 'Custom components',
          valueInputProps: { multiline: true, rows: 4 },
        },
      ].map(({ name, ...rest }) => (
        <Box my={2} key={name}>
          <StyleOptionsForm loading={loading} {...rest} name={`options.${name}`} form={form as any} />
        </Box>
      ))}
      <Box my={2}>
        <FormControl fullWidth>
          <InputLabel>Custom Fonts</InputLabel>
          <Controller
            name="customFonts"
            control={control}
            defaultValue={defaultValues.customFonts}
            as={
              <Select
                multiple
                disabled={loading}
                renderValue={(selected) => (
                  <div>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onClick={() => {
                          setValue(
                            'customFonts',
                            (getValues('customFonts') || []).filter((v: string) => v !== value)
                          );
                        }}
                        label={indexedFonts[value] ? getFontLabel(indexedFonts[value]) : `Missing font: ${value}`}
                      />
                    ))}
                  </div>
                )}
              >
                {fonts.map((f: DocumentStyleFont) => {
                  return (
                    <MenuItem key={f.id} value={f.id}>
                      {getFontLabel(f)}
                    </MenuItem>
                  );
                })}
              </Select>
            }
          ></Controller>
        </FormControl>
      </Box>
      <Box my={2}>
        <TextField
          disabled={loading}
          helperText="The CSS content to style CKEditor. You should prefix every rule with .ck-content class."
          variant="outlined"
          fullWidth
          multiline
          rows={10}
          label="CSS"
          name="css"
          inputRef={register()}
        />
      </Box>
      <Box my={2}>
        <BusyButton busy={loading} variant="contained" color="primary" type="submit">
          Save
        </BusyButton>
      </Box>
    </form>
  );
};

interface StyleOptionsFormProps {
  name: string;
  form: UseFormMethods<DocumentStyleFormData>;
  label: string;
  valueInputProps?: Omit<TextFieldProps, 'name' | 'inputRef' | 'label'>;
  loading: boolean;
}

const StyleOptionsForm: FC<StyleOptionsFormProps> = ({ form, name, label, valueInputProps, loading }) => {
  const { control, register, errors } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <Box mt={2} mb={4}>
      <Typography>{label}</Typography>

      {fields.map((f, fieldIndex) => {
        const labelFieldName = `${name}[${fieldIndex}].label`;
        const labelError = get(errors, labelFieldName);
        return (
          <Box my={2} display="flex" alignItems="center" key={f.id} maxWidth="800">
            <Box mr={2}>
              <TextField
                disabled={loading}
                variant="outlined"
                name={labelFieldName}
                inputRef={register({ required: 'Value is required' })}
                error={Boolean(labelError)}
                helperText={labelError?.message}
                label="Label"
                defaultValue={f.label}
              />
            </Box>
            <TextField
              disabled={loading}
              variant="outlined"
              name={`${name}[${fieldIndex}].value`}
              inputRef={register()}
              label="Value"
              defaultValue={f.value}
              fullWidth
              {...valueInputProps}
            />

            <IconButton disabled={loading} onClick={() => remove(fieldIndex)}>
              <Delete />
            </IconButton>
          </Box>
        );
      })}
      <Box mt={2}>
        <Button
          disabled={loading}
          color="primary"
          variant="outlined"
          onClick={() => append({ label: '', value: valueInputProps?.type === 'color' ? '#000000' : '' })}
        >
          Add item
        </Button>
      </Box>
    </Box>
  );
};
