import { Attachment } from 'models/Attachment';
import { DocumentTemplate } from 'models/DocumentTemplate';
import { Documentation } from 'models/documentation/Documentation';
import { DocumentationStatus } from 'models/documentation/Documentation';
import { Attribute, DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { DocumentComment } from 'models/documentation/sub-models/comment/comment.model';
import { DocumentationDocument } from 'models/documentation/sub-models/document/DocumentationDocument';
import { getDocumentAttributeId } from 'shared/helpers/attributes';

const updateDocumentAttribute = (
  attrId: string,
  attrVal: any,
  document: DocumentationDocument
): DocumentationDocument => {
  //TODO Any to fix
  if (document?.documentStatus === DocumentationStatus.Active) {
    return document;
  }
  const newDocument = { ...document };
  const attIndex = document.attributes.findIndex((el: DocumentAttribute) => {
    return getDocumentAttributeId(el) === attrId;
  });
  if (attIndex < 0) {
    return document;
  }
  const newattributes = [...newDocument.attributes];
  const newAttr = { ...document.attributes[attIndex] };
  newAttr.value = attrVal;
  newattributes[attIndex] = newAttr;
  newDocument.attributes = newattributes;
  return newDocument;
};

const updateAttachmentAttribute = (attrId: string, attrVal: any, attachment: Attachment): Attachment => {
  //TODO Any to fix
  const newAttachment = { ...attachment };
  const attIndex = attachment.attributes.findIndex((el: Attribute) => {
    return el.id === attrId;
  });
  if (attIndex < 0) {
    return attachment;
  }
  const newAttributes = [...newAttachment.attributes];
  const newAttr = { ...newAttachment.attributes[attIndex] };
  newAttr.value = attrVal;
  newAttributes[attIndex] = newAttr;
  newAttachment.attributes = newAttributes;
  return newAttachment;
};

export const updateAgreementAttribute = (attrId: string, attrVal: any, agreement: Documentation): Documentation => {
  const newAgreement = { ...agreement };
  newAgreement.attributes = [...agreement.attributes];
  const attrIndex = agreement.attributes.findIndex((el: DocumentAttribute) => {
    return getDocumentAttributeId(el) === attrId;
  });
  if (attrIndex < 0) {
    throw new Error("Attribute doesn't exist!");
  }
  const newAttr = { ...agreement.attributes[attrIndex] };
  newAttr.value = attrVal;
  newAgreement.attributes[attrIndex] = newAttr;

  const newDocs = [];
  for (const doc of agreement.documents) {
    newDocs.push(updateDocumentAttribute(attrId, attrVal, doc));
  }
  newAgreement.documents = newDocs;

  const newAttachments = [];
  for (const attachment of agreement.attachments) {
    newAttachments.push(updateAttachmentAttribute(attrId, attrVal, attachment));
  }
  newAgreement.attachments = newAttachments;

  return newAgreement;
};

export const updateSingleDocumentAttribute = (
  attrId: string,
  attrVal: any,
  agreement: Documentation,
  documentIndex: number
): Documentation => {
  const newAgreement = { ...agreement };
  const newDocs = [...agreement.documents];
  const updatedDoc = updateDocumentAttribute(attrId, attrVal, newDocs[documentIndex]);
  newDocs[documentIndex] = updatedDoc;
  newAgreement.documents = newDocs;
  return newAgreement;
};

export const updateSinglePDFDocumentAttribute = (
  attrId: string,
  attrVal: any,
  agreement: Documentation,
  pdfIndex: number
): Documentation => {
  const newAgreement = { ...agreement };
  const newAttachments = [...agreement.attachments];
  const updatedAttachment = updateAttachmentAttribute(attrId, attrVal, newAttachments[pdfIndex]);
  newAttachments[pdfIndex] = updatedAttachment;
  newAgreement.attachments = newAttachments;
  return newAgreement;
};

export const addAgreementAttribute = (
  agreement: Documentation,
  attribute: Attribute,
  currentDocumentIndex: number
): Documentation => {
  const updatedDocs = [...agreement.documents];

  const updatedDoc: DocumentationDocument = {
    ...agreement.documents[currentDocumentIndex],
    attributes: [...agreement.documents[currentDocumentIndex].attributes, attribute],
  };

  updatedDocs[currentDocumentIndex] = updatedDoc;

  const newAgreement: Documentation = {
    ...agreement,
    documents: updatedDocs,
  };

  return newAgreement;
};

export const addAgreementAttributeToAttachment = (
  agreement: Documentation,
  attribute: Attribute,
  currentPdfIndex: number
): Documentation => {
  const updatedAttachments = [...agreement.attachments];

  const updatedAttachment: Attachment = {
    ...agreement.attachments[currentPdfIndex],
    attributes: [...agreement.attachments[currentPdfIndex].attributes, attribute],
  };

  updatedAttachments[currentPdfIndex] = updatedAttachment;

  const newAgreement: Documentation = {
    ...agreement,
    attachments: updatedAttachments,
  };

  return newAgreement;
};

export const AddDocumentToAgreement = (agreement: Documentation, documents: DocumentTemplate[]): Documentation => {
  const existingDocuments = [...agreement.documents];

  const newDocs: any = []; //TODO - mapper to fix + any
  documents.forEach((document: DocumentTemplate) =>
    newDocs.push({
      content: document.content,
      category: document.category,
      name: `[doc] ${document.name}`,
      documentStatus: DocumentationStatus.InDraft,
      attributes: document.attributes.map((docAttr: DocumentAttribute) => {
        const currentAgreementValue = agreement.attributes.find(
          (agreeAttr: DocumentAttribute) => getDocumentAttributeId(agreeAttr) === getDocumentAttributeId(docAttr)
        )?.value;
        if (currentAgreementValue) {
          docAttr.value = currentAgreementValue;
        }
        return docAttr;
      }),
    })
  );

  const updatedDocuments: DocumentationDocument[] = [...existingDocuments, ...newDocs];

  const newAgreement: Documentation = {
    ...agreement,
    documents: updatedDocuments,
  };

  return newAgreement;
};
export const AddCommentToDocument = (
  agreement: Documentation,
  comment: DocumentComment,
  index: number
): Documentation => {
  const documents = [...agreement.documents];
  documents[index].comments.push(comment);

  const newDocuments = documents;

  const updatedAgreement: Documentation = {
    ...agreement,
    documents: newDocuments,
  };

  return updatedAgreement;
};

export const UpdateCurrentComment = (
  agreement: Documentation,
  comment: DocumentComment,
  documentIndex: number,
  index: number
): Documentation => {
  const documents = [...agreement.documents];
  const comments = [...documents[documentIndex].comments];
  comments[index] = comment;
  documents[documentIndex].comments = comments;

  const updatedAgreement: Documentation = {
    ...agreement,
    documents,
  };
  return updatedAgreement;
};

export const ResolvedComment = (agreement: Documentation, documentIndex: number, index: number): Documentation => {
  const documents = [...agreement.documents];
  const comments = [...documents[documentIndex].comments];
  comments[index].isResolved = true;
  documents[documentIndex].comments = comments;

  const updatedAgreement: Documentation = {
    ...agreement,
    documents,
  };
  return updatedAgreement;
};

export const copyRefreshAgreementAttributes = (reduxAgreement: Documentation): Documentation => {
  if (reduxAgreement.status === DocumentationStatus.Active) {
    return reduxAgreement;
  }

  const updatedAgreement = { ...reduxAgreement };

  const allEditableDocAttributes: DocumentAttribute[] = updatedAgreement.documents
    .map((el: DocumentationDocument) => el.attributes)
    .reduce((a, b) => a.concat(b), []);

  const allAttachmentAttributes: Attribute[] = updatedAgreement.attachments
    .map((el: Attachment) => el.attributes)
    .reduce((a, b) => a.concat(b), []);

  const allDocAttributes = [...allEditableDocAttributes, ...allAttachmentAttributes];

  const finalAttributesIds: string[] = [];
  const finalAttributes = [];
  for (const attribute of allDocAttributes) {
    if (!finalAttributesIds.includes(getDocumentAttributeId(attribute))) {
      finalAttributes.push({ ...attribute });
      finalAttributesIds.push(getDocumentAttributeId(attribute));
    }
  }
  updatedAgreement.attributes = finalAttributes;

  return updatedAgreement;
};
