import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';

import { AttributesSectionProps } from './AttributesSection';
import { ReactComponent as ClearIcon } from 'images/clear.svg';
import { ReactComponent as RemoveIcon } from 'images/remove.svg';
import { AttributeType } from 'models/Enums';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { ConfirmButton } from 'shared/components/Confirm/ConfirmButton';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { getAttributeBooleanTK } from 'shared/helpers/translationKeys';
import { useAttributeTranslation } from 'shared/hooks/useAttributeTranslation';

type AttributeInputProps = DocumentAttribute &
  Pick<AttributesSectionProps, 'removable' | 'handleRemoveAttribute' | 'attrValuesDisabled' | 'updateAttribute'> & {
    id?: string;
  };

const AttributeInput_: FC<AttributeInputProps> = (
  {
    attrValuesDisabled,
    handleRemoveAttribute,
    removable,
    updateAttribute,
    name,
    type,
    value,
    description,
    category,
    blocked,
  },
  ref
) => {
  const { t } = useTranslation();
  const { translateAttributeName } = useAttributeTranslation();

  const isEnum = type === AttributeType.Boolean;

  const shrinkLabel = isEnum || type === AttributeType.Date;

  const labelProps = shrinkLabel
    ? {
        shrink: true,
      }
    : undefined;

  const menuItems =
    type === AttributeType.Boolean
      ? ['true', 'false']
          .map((v) => ({
            label: t(getAttributeBooleanTK(v)),
            value: v,
          }))
          .concat({
            label: '',
            value: '',
          })
      : null;

  const inputType = isEnum ? 'text' : type;
  const attr = { name, type, description, category, blocked, value };
  return (
    <Box display="flex">
      <Tooltip placement="top-start" title={attr.description || ''}>
        <TextField
          variant="outlined"
          type={inputType}
          select={isEnum}
          size="small"
          fullWidth
          onChange={(e: any): void => {
            updateAttribute({ ...attr, value: e.target.value });
          }}
          disabled={attrValuesDisabled || blocked}
          value={value || ''}
          label={translateAttributeName(attr)}
          InputLabelProps={labelProps}
          InputProps={{
            endAdornment:
              attrValuesDisabled || blocked ? null : (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      updateAttribute({ ...attr, value: '' });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
          }}
        >
          {menuItems?.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
      {handleRemoveAttribute && removable && !blocked && (
        <ConfirmButton
          title={t('attributes_section.attribute_confirm_delete', 'Are you sure to delete attribute {{attribute}}?', {
            attribute: getDocumentAttributeId(attr),
          })}
          button={IconButton}
          onConfirm={(): void => {
            handleRemoveAttribute?.(attr);
          }}
        >
          <RemoveIcon />
        </ConfirmButton>
      )}
    </Box>
  );
};

export const AttributeInput = React.memo(AttributeInput_);
