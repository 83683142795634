import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { defaultLanguage } from 'config/language';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(HttpApi)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    lng: defaultLanguage,
    fallbackLng: 'en',
    keySeparator: false,
    nsSeparator: false,
    defaultNS: 'translation',
    ns: ['translation', 'backend'],
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
