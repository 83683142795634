import React, { FC } from 'react';

interface Props {
  text: string;
  textToHighlight: string;
}

export const VerySimpleTextHighlighter: FC<Props> = ({ text, textToHighlight }) => {
  if (!textToHighlight || !text.includes(textToHighlight)) {
    return <>{text}</>;
  }

  const [m1, m2] = text.split(textToHighlight);

  return (
    <>
      {m1}
      <strong>{textToHighlight}</strong>
      {m2}
    </>
  );
};
