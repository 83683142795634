export enum DocCategory {
  Contract = 'Contract',
  HandoverProtocol = 'Handover/Return Protocol',
  Amendment = 'Amendment',
  Annex = 'Annex',
  Attachment = 'Attachment',
  IndexationLetter = 'Indexation Letter',
  Notification = 'Notification',
  ServiceChargeSettlement = 'Service Charge Settlement',
  Schedule = 'Schedule',
  PowerOfAttorney = 'Power of Attorney',
  Other = 'Other',
  PDFAttachment = 'PDF Attachment',
  PDFReferenceDocument = 'PDF Reference document',
}
