import map from 'lodash/map';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Grid, makeStyles } from '@material-ui/core';

import { Accordion } from '../Accordion/Accordion';
import Item from '../ListItem/Item';
import ModalToUpload from '../ModalToUpload/ModalToUpload';
import { DocCategory } from 'models/DocCategory';
import { DocumentListItemModel } from 'pages/TemplateManagement/model';
import { groupDocumentTemplates } from 'shared/helpers/documentTemplate/groupDocumentTemplates';
import { getDocCategoryTK } from 'shared/helpers/translationKeys';

interface ChooseTemplateProps {
  toggleItem: (id: string) => void;
  templates: DocumentListItemModel[];
  itemClassName?: string;
  selectedTemplates: string[];
  defaultExpanded?: boolean;
  flat?: boolean;
  itemContent?: (template: DocumentListItemModel) => React.ReactNode;
}

const useStyles = makeStyles(() => ({
  badge: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));

const ChooseTemplate = ({
  toggleItem,
  templates,
  selectedTemplates,
  defaultExpanded,
  flat,
  itemContent,
  itemClassName,
}: ChooseTemplateProps): JSX.Element => {
  const [uploadTemplateModalOpen, setUploadTemplateModalOpen] = useState<boolean>(false);
  const styles = useStyles();
  const grouped = useMemo(() => groupDocumentTemplates(templates), [templates]);
  const { t } = useTranslation();
  const renderTemplateList = (templates: DocumentListItemModel[]) => {
    return (
      <Grid container spacing={2}>
        {templates.map((template: DocumentListItemModel) => {
          const index = selectedTemplates.indexOf(template.id);
          const selected = index !== -1;
          return (
            <Grid item key={template.id} className={itemClassName}>
              <Item
                type="existing"
                template={template}
                showCategoryName={true}
                title={template.name}
                selected={selected}
                onClick={() => toggleItem(template.id)}
              >
                {selected ? <Badge classes={{ root: styles.badge }} color="primary" badgeContent={index + 1} /> : null}
                {itemContent ? itemContent(template) : null}
              </Item>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid container direction="column" spacing={4}>
      {uploadTemplateModalOpen && (
        <ModalToUpload setModalOpen={setUploadTemplateModalOpen} syncTemplates={() => null} />
      )}

      <Grid item>
        {flat
          ? renderTemplateList(templates)
          : map(grouped, (templates, category) => {
              return (
                <Accordion
                  title={t(getDocCategoryTK(category as DocCategory))}
                  defaultExpanded={defaultExpanded}
                  key={category}
                >
                  {renderTemplateList(templates)}
                </Accordion>
              );
            })}
      </Grid>
    </Grid>
  );
};

export default ChooseTemplate;
