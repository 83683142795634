import { ActionType } from '../action-types/action-types';
import { ActionEnum } from '../models/action.enums';
import { UserData } from '../models/userData.state';

const defaultState: UserData = {
  agreements: [],
  agreementTemplates: [],
};

const userData = (state = defaultState, action: ActionType): UserData => {
  switch (action.type) {
    case ActionEnum.SET_ALL_AGREEMENTS: {
      return { ...state, agreements: action.payload };
    }
    case ActionEnum.SET_ALL_AGREEMENT_TEMPLATES: {
      return { ...state, agreementTemplates: action.payload };
    }
    case ActionEnum.UPDATE_ONE_AGREEMENT: {
      const updatedAgreements = [...state.agreements];
      const index = updatedAgreements.findIndex((agreement) => agreement.id === action.payload.id);
      updatedAgreements[index] = action.payload;
      return { ...state, agreements: updatedAgreements };
    }
    case ActionEnum.UPDATE_ONE_AGREEMENT_TEMPLATE: {
      const updatedAgreementTemplate = [...state.agreementTemplates];
      const index = updatedAgreementTemplate.findIndex(
        (agreementTemplate) => agreementTemplate.id === action.payload.id
      );
      updatedAgreementTemplate[index] = action.payload;
      return { ...state, agreementTemplates: updatedAgreementTemplate };
    }
    default:
      return state;
  }
};

export default userData;
