import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, MenuItem, Tooltip, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { ArrowDropDown } from '@material-ui/icons';

import { useOrganization } from '../contexts/organization';
import { useMemberships } from '../hooks/useMemberships';
import { getUserTypeTK } from 'shared/helpers/translationKeys';

export const OrganizationSelector: FC = () => {
  const ref = useRef(null);
  const [opened, setOpened] = useState(false);

  const { t } = useTranslation();
  const organization = useOrganization();
  const memberships = useMemberships();

  if (!organization || memberships.length < 1) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Tooltip title={t<string>('organization_selection.tooltip', 'Select organization')}>
        <Button
          ref={ref}
          style={{ color: '#fff', cursor: 'pointer', fontWeight: 700, textTransform: 'unset' }}
          endIcon={<ArrowDropDown />}
          onClick={() => setOpened(true)}
        >
          <Typography>{organization?.organizationName}</Typography>
        </Button>
      </Tooltip>
      <Menu anchorEl={ref.current} open={opened} onClose={() => setOpened(false)}>
        <div style={{ maxHeight: '400px' }}>
          {memberships.map((membership) => (
            <MenuItem
              key={membership.id}
              component={Link}
              selected={organization.organizationId === membership.organizationId}
              to={`/organization/${membership.organizationId}/${membership.id}`}
              onClick={() => setOpened(false)}
            >
              {membership.companyName} ({t(getUserTypeTK(membership.userType))})
            </MenuItem>
          ))}
        </div>
      </Menu>
    </Box>
  );
};
