enum ActionEnum {
  'SYNC_AGREEMENT' = 'SYNC_AGREEMENT',
  'UPDATE_CURRENT_DOCUMENT_INDEX' = 'UPDATE_CURRENT_DOCUMENT_INDEX',
  'UPDATE_CURRENT_DOCUMENT_CONTENT' = 'UPDATE_CURRENT_DOCUMENT_CONTENT',
  'ADD_ATTRIBUTE_TO_DOC' = 'ADD_ATTRIBUTE_TO_DOC',
  'UPDATE_ATTRIBUTE_VALUE' = 'UPDATE_ATTRIBUTE_VALUE',
  'UPDATE_ATTRIBUTE_VALUE_FOR_DOC' = 'UPDATE_ATTRIBUTE_VALUE_FOR_DOC',
  'SYNC_EXTERNAL_USERS_LIST' = 'SYNC_EXTERNAL_USERS_LIST',
  'UPDATE_DOC_ATTRS' = 'UPDATE_DOC_ATTRS',
  'ADD_DOCUMENT_TO_AGREEMENT' = 'ADD_DOCUMENT_TO_AGREEMENT',
  'UPDATE_CURRENT_DOCUMENT_NAME' = 'UPDATE_CURRENT_DOCUMENT_NAME',
  'UPDATE_CURRENT_ATTACHMENT_NAME' = 'UPDATE_CURRENT_ATTACHMENT_NAME',
  'ADD_COMMENT_TO_DOCUMENT' = 'ADD_COMMENT_TO_DOCUMENT',
  'UPDATE_COMMENTS_TO_AGREEMENT' = 'UPDATE_COMMENTS_TO_AGREEMENT',
  'UPDATE_CURRENT_COMMENT' = 'UPDATE_CURRENT_COMMENT',
  'RESOLVED_COMMENT' = 'RESOLVED_COMMENT',
  'REFRESH_AGREEMENT_ATTRIBUTES' = 'REFRESH_AGREEMENT_ATTRIBUTES',
  'UPDATE_CURRENT_PDF_INDEX' = 'UPDATE_CURRENT_PDF_INDEX',
  'SET_CURRENT_COMMENT_ID' = 'SET_CURRENT_COMMENT_ID',
  'ADD_ATTRIBUTE_TO_ATTACHMENT' = 'ADD_ATTRIBUTE_TO_ATTACHMENT',
  'UPDATE_ATTACHMENT_ATTRS' = 'UPDATE_ATTACHMENT_ATTRS',
  'UPDATE_ATTRIBUTE_VALUE_FOR_PDF_DOC' = 'UPDATE_ATTRIBUTE_VALUE_FOR_PDF_DOC',
}

export default ActionEnum;
