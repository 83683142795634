import React, { FC } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { generatePath, useHistory } from 'react-router';

import { Box, Button, Typography } from '@material-ui/core';

import { DocumentStyleTable } from './DocumentStyleTable';
import { DocumentStyleListItem } from 'models/DocumentStyle';
import { Paths } from 'router/paths';

interface DocumentStyleListProps {
  organizationId: string;
}

export const DocumentStyleList: FC<DocumentStyleListProps> = ({ organizationId }) => {
  const { query: reload, payload: data = [] } = useSuspenseQuery<DocumentStyleListItem[]>({
    endpoint: `/styles/organization/${organizationId}`,
    method: 'GET',
    credentials: 'include',
  });

  const history = useHistory();

  const onEdit = (styleId: string) => {
    history.push(generatePath(Paths.ADMIN_ORGANIZATION_STYLE_DETAILS, { id: organizationId, styleId }));
  };

  return (
    <>
      <Box my={2}>
        <Typography variant="h5">Styles</Typography>
      </Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => history.push(generatePath(Paths.ADMIN_ORGANIZATION_STYLE_CREATE, { id: organizationId }))}
      >
        + Create new style
      </Button>

      <DocumentStyleTable data={data} onEdit={onEdit} reload={reload} />
    </>
  );
};
