// @ts-ignore
import cssEscape from 'css.escape';

import { getAttributeDisplayValue } from './attributeValue/getAttributeDisplayValue';
import { fragmentFromString, fragmentToString } from './fragment';
import { BaseDocument } from 'models/DocumentTemplate';
import { Documentation } from 'models/documentation/Documentation';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { getDocumentAttributeId } from 'shared/helpers/attributes';

export const rejectAttribute = (arr: DocumentAttribute[], attr: DocumentAttribute) => {
  const id = getDocumentAttributeId(attr);
  return arr.filter((a) => getDocumentAttributeId(a) !== id);
};

export const updateAttribute = (arr: DocumentAttribute[], attr: DocumentAttribute) => {
  const id = getDocumentAttributeId(attr);
  const clone = [...arr];
  const index = clone.findIndex((a) => getDocumentAttributeId(a) === id);
  if (index !== -1) {
    clone.splice(index, 1, attr);
  }

  return clone;
};
export const updateAttributes = (arr: DocumentAttribute[], attrs: DocumentAttribute[]) => {
  return arr.map((v) => {
    const id = getDocumentAttributeId(v);
    const udpated = attrs.find((a) => getDocumentAttributeId(a) === id);
    return udpated || v;
  });
};

export const documentationAttributeAction = <T extends DocumentAttribute | DocumentAttribute[]>(
  documentation: Documentation,
  action: (d: DocumentAttribute[], attribute: T) => DocumentAttribute[],
  attr: T
): Documentation => {
  return {
    ...documentation,
    documents: documentation.documents.map((d) => ({
      ...d,
      attributes: action(d.attributes, attr),
    })),
    attributes: action(documentation.attributes, attr),
  };
};

export const getDocumentContentWithAttributes = (doc: BaseDocument) => {
  const fragment = fragmentFromString(doc.content);

  doc.attributes.forEach((a) => {
    const id = getDocumentAttributeId(a);

    fragment.querySelectorAll(`[data-pactt-attribute-id="${cssEscape(id)}"`).forEach((e) => {
      const v = getAttributeDisplayValue(a, {
        displayValue: true,
        metaData: (e as HTMLSpanElement).dataset.pacttAttributeMeta,
      });

      if (v) {
        e.textContent = v;
      }
    });
  });

  return fragmentToString(fragment);
};
