import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { Action } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '@material-ui/core';

import { InviteForm } from './InviteForm';
import { useMutation } from 'api/useMutation';

const inviteUser: (endpoint: string) => (data: any) => Action = (endpoint: string) => (data: any) => ({
  endpoint,
  method: 'POST',
  credentials: 'include',
  body: data,
});

interface InvitiationProps {
  endpoint: string;
  additionalBody?: {};
  onSuccess?: () => void;
  title?: string;
  tooltipBody?: string;
}

const Invitation: FC<InvitiationProps> = ({ title, endpoint, additionalBody, tooltipBody = '', onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { payload, mutate, error, loading } = useMutation(inviteUser(endpoint));
  const { enqueueSnackbar } = useSnackbar();
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    const { error, payload } = await mutate({ ...data, ...additionalBody });

    if (error) {
      enqueueSnackbar(t('common.error.unknown') + `${JSON.stringify(payload)}`, { variant: 'error' });
    } else {
      enqueueSnackbar(t('invitation.success_message', 'User "{{email}}" has been invited', { email: data.email }), {
        variant: 'success',
      });
      onSuccess && onSuccess();
    }
    close();
  };

  return (
    <>
      <Tooltip title={tooltipBody}>
        <Button variant="contained" color="primary" onClick={open}>
          + {title || t('invitation.invite_user_default_text', 'Invite User')}
        </Button>
      </Tooltip>
      <InviteForm
        open={isOpen}
        error={error ? (payload as any)?.error : null}
        onSubmit={onSubmit}
        onCancel={close}
        loading={loading}
      />
    </>
  );
};

export default Invitation;
