import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { Action, QueryResponse, useMutation } from 'react-fetching-library';
import { generatePath, useHistory } from 'react-router';

import { Box, Typography } from '@material-ui/core';

import { Paths } from 'router/paths';

interface BaseFormProps<D = any, F = D> {
  loading: boolean;
  defaultValues: D;
  onSubmit: (data: F) => void;
}

export interface FormWrapperProps<T extends FC<BaseFormProps> = FC<BaseFormProps>> {
  title: string;
  data: Parameters<T>[0]['defaultValues'];
  organizationId: string;
  actionCreator: (data: Parameters<Parameters<T>[0]['onSubmit']>[0]) => Action;
  Form: T;
}

export const FormWrapper = ({
  actionCreator,
  title,
  data,
  organizationId,
  Form,
}: FormWrapperProps<FC<BaseFormProps>>): JSX.Element => {
  const { mutate, loading } = useMutation(actionCreator);
  const snackBar = useSnackbar();
  const history = useHistory();

  const handleResponse = ({ error, payload }: QueryResponse) => {
    if (error) {
      snackBar.enqueueSnackbar(JSON.stringify(payload, null, 2), { variant: 'error' });
    } else {
      history.push(generatePath(Paths.ADMIN_ORGANIZATION_DETAILS, { id: organizationId }));
    }
  };

  return (
    <div>
      <Box pb={2}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Form
        loading={loading}
        defaultValues={data}
        onSubmit={(data) => {
          mutate(data).then(handleResponse);
        }}
      />
    </div>
  );
};

export default FormWrapper;
