import React, { FC } from 'react';

import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MuiAccordion,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

interface AccordionProps {
  title: string;
  defaultExpanded?: boolean;
}
const useAccordionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: 'transparent',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);
export const Accordion: FC<AccordionProps> = ({ title, children, defaultExpanded }) => {
  const styles = useAccordionStyles();
  return (
    <MuiAccordion
      classes={{
        root: styles.root,
      }}
      elevation={0}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography classes={{ root: styles.heading }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
