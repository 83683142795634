import { useAuth } from 'auth';
import React, { useEffect } from 'react';
import { useMutation, useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { Backdrop, Box, Button, Fade, Modal, Typography } from '@material-ui/core';

import { useOrganization } from 'contexts/organization';
import { MemberSettingsForm, MemberSetttingsModel } from 'pages/MemberSettings/components/MemberSettingsForm';

const ModalUserData = (props: any) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const [showStandbyInfo, setShowStandByInfo] = React.useState(false);
  const { organizationId, membershipId, setModalTutorialVisible } = useOrganization();

  useEffect(() => {
    props.open && setOpen(true);
  }, [props.open]);

  const handleClose = (): void => {
    props.setShowModalUserData(false);
    setOpen(false);
  };

  const endpoint = `/organization/${organizationId}/${membershipId}/settings`;

  const { refresh } = useAuth();
  const { payload, query } = useSuspenseQuery<MemberSetttingsModel>({
    endpoint,
    method: 'GET',
    credentials: 'include',
  });

  const { mutate } = useMutation((body: MemberSetttingsModel) => ({
    endpoint,
    method: 'PUT',
    credentials: 'include',
    body,
  }));

  const onSubmit = (data: MemberSetttingsModel) => {
    mutate(data)
      .then(query)
      .then(refresh)
      .then(() => {
        setShowStandByInfo(true);
      });
  };

  const closeWithShowTutorial = React.useCallback(() => {
    handleClose();
    setModalTutorialVisible(true);
  }, [setModalTutorialVisible]);

  const closeWithoutTutorial = React.useCallback(() => {
    handleClose();
  }, []);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      className="modal"
      closeAfterTransition
      disableBackdropClick={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <>
          <Box
            p={6}
            style={{
              borderRadius: '5px',
              width: '583px',
              backgroundColor: '#f9f7f5',
              position: 'relative',
              textAlign: 'center',
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {!showStandbyInfo && (
              <>
                <Typography variant="h4">{t('welcome_modal.head', 'Welcome in Pactt!')}</Typography>
                <Typography component="p">
                  {t(
                    'welcome_modal.start-info',
                    'To start, we will ask you for information so that your colleagues and contractors can see who you are.'
                  )}
                </Typography>
                <MemberSettingsForm data={payload!} onSubmit={onSubmit} shouldShowAutentiToken={false} />
              </>
            )}
            {showStandbyInfo && (
              <>
                <Typography component="p">
                  {t(
                    'welcome_modal.standby-info',
                    'Done! You can now use Pactt. Check out the tutorial to see where you can start!'
                  )}
                </Typography>
                <Box m={4} display="flex">
                  <Box m={2}>
                    <Button onClick={closeWithShowTutorial} variant="contained" color="primary">
                      {t('welcome_modal.show-tutorial', 'I want see tutorial now')}
                    </Button>
                  </Box>
                  <Box m={2}>
                    <Button onClick={closeWithoutTutorial}>
                      {t('welcome_modal.avoid-tutorial', "I'll see tutorial later")}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </>
      </Fade>
    </Modal>
  );
};

export default ModalUserData;
