import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';

import { OrganizationUser, UserStatus } from '../model';
import { useOrganization } from 'contexts/organization';
import { UserType } from 'models/Enums';
import { DeleteAction } from 'shared/components/DeleteButton/DeleteAction';
import { getUserTypeTK } from 'shared/helpers/translationKeys';
import { OrganizationPermission } from 'user/permissions/model';

interface UsersTableProps {
  organizationId: string;
  membershipId: string;
  data: OrganizationUser[];
  reload: () => void;
}

interface IColumn {
  field: keyof OrganizationUser;
  header: string;
  translate?: (s: string) => string;
}

const useRowStyles = makeStyles(() => ({
  inactive: {
    opacity: 0.5,
  },
}));

const canDeleteUserPermissionMap: Record<UserType, OrganizationPermission> = {
  [UserType.Creator]: OrganizationPermission.DELETE_CREATOR,
  [UserType.Member]: OrganizationPermission.DELETE_MEMBER,
  [UserType.Owner]: OrganizationPermission.DELETE_OWNER,
};

const renderRow = (
  {
    t,
    columns,
    membershipId,
    organizationId: orgId,
    canDelete,
  }: {
    t: any;
    columns: IColumn[];
    canDelete: boolean;
    organizationId: string;
    membershipId: string;
  },
  row: OrganizationUser,
  rowStyles: ReturnType<typeof useRowStyles>,
  reload: () => void
) => {
  return (
    <TableRow
      key={row.tokenId || row.membershipId}
      classes={{
        root: row.userStatus === UserStatus.AWAITING ? rowStyles.inactive : undefined,
      }}
    >
      {columns.map(({ field, translate }) => (
        <TableCell component="td" scope="row" key={field}>
          {translate ? translate(row[field]) : row[field]}
        </TableCell>
      ))}
      <TableCell component="th" scope="row">
        {canDelete && row.userStatus === UserStatus.ACTIVE && row.membershipId !== membershipId ? (
          <DeleteAction
            endpoint={`/organization/${orgId}/organizationMembership/${membershipId}/members/${row.membershipId}`}
            onComplete={reload}
            title={t(
              'organization_users.confirm_delete_user',
              'Are you sure to delete the user "{{email}}" ({{userType}})?',
              {
                email: row.email,
                userType: t(getUserTypeTK(row.userType)),
              }
            )}
          />
        ) : null}
        {canDelete && row.userStatus === UserStatus.AWAITING ? (
          <DeleteAction
            endpoint={`/organization/${orgId}/organizationMembership/${membershipId}/token/${row.tokenId}`}
            onComplete={reload}
            title={t(
              'organization_users.confirm_delete_invitation',
              'Are you sure to delete the invitation "{{email}}" ({{userType}})?',
              {
                email: row.email,
                userType: t(getUserTypeTK(row.userType)),
              }
            )}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export const UsersTable: FC<UsersTableProps> = ({ organizationId, membershipId, data, reload }) => {
  const rowStyles = useRowStyles();
  const { hasPermission } = useOrganization();

  const { t } = useTranslation();
  const columns: IColumn[] = [
    {
      field: 'email',
      header: t('organization_users.column_email', 'e-mail'),
    },
    {
      field: 'firstName',
      header: t('organization_users.column_first_name', 'Name'),
    },
    {
      field: 'lastName',
      header: t('organization_users.column_last_name', 'Last Name'),
    },
    {
      field: 'jobTitle',
      header: t('organization_users.column_job_title', 'Function'),
    },
    {
      field: 'companyName',
      header: t('organization_users.column_company_name', 'Company Name'),
    },
    {
      field: 'userType',
      header: t('organization_users.column_user_type', 'User Type'),
      translate: (s: string) => t(`user_type.${s}`) as string,
    },
    {
      field: 'userStatus',
      header: t('organization_users.column_user_status', 'Status'),
      translate: (s: string) => t(`user_status.${s}`) as string,
    },
  ];

  const body =
    data && data.length > 0
      ? data.map((r) =>
          renderRow(
            {
              t,
              columns,
              organizationId,
              membershipId,
              canDelete: hasPermission(canDeleteUserPermissionMap[r.userType]),
            },
            r,
            rowStyles,
            reload
          )
        )
      : [];

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {columns.map(({ header, field }) => (
            <TableCell component="th" key={field}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{body}</TableBody>
    </Table>
  );
};
