import React, { FC } from 'react';

import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

interface Props extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton: FC<Props> = ({ loading, children, ...props }) => {
  if (loading) {
    return (
      <div style={{ position: 'relative' }}>
        <Button {...props} disabled={true}>
          {children}
        </Button>
        <CircularProgress
          size={25}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      </div>
    );
  }

  return <Button {...props}>{children}</Button>;
};
