import React, { ComponentType, PropsWithChildren, ReactNode, useState } from 'react';

import { Button } from '@material-ui/core';

import { ConfirmDialog } from './ConfirmDialog';

export interface ConfirmButtonProps<T> {
  onConfirm?: () => void;
  onClose?: () => void;
  title: string;
  button?: T;
  buttonProps?: T extends ComponentType<infer P> ? Omit<P, 'onClick'> : any;
  confirmText?: string;
  closeText?: string;
  dialogContent?: ReactNode;
}

export const ConfirmButton: <T extends ComponentType>(
  p: PropsWithChildren<ConfirmButtonProps<T>>
) => React.ReactElement = ({
  onConfirm,
  onClose,
  title,
  children,
  button: B = Button,
  confirmText,
  closeText,
  buttonProps,
  dialogContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  return (
    <>
      <B {...buttonProps} onClick={() => setIsOpen(true)}>
        {children}
      </B>
      <ConfirmDialog
        open={isOpen}
        title={title}
        confirmText={confirmText}
        closeText={closeText}
        onConfirm={
          onConfirm
            ? () => {
                close();
                onConfirm();
              }
            : undefined
        }
        onClose={() => {
          close();
        }}
      >
        {dialogContent}
      </ConfirmDialog>
    </>
  );
};
