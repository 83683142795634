import { difference } from 'lodash';

import { DocCategory } from 'models/DocCategory';

const pdfCategories = [DocCategory.PDFAttachment, DocCategory.PDFReferenceDocument];
export const nonPDFCategories = difference(Object.values(DocCategory), pdfCategories);

export const isPDFCategory = (category: DocCategory) => pdfCategories.includes(category);

export const getCompatibleCategories = (category: DocCategory) => {
  const isPDF = isPDFCategory(category);

  return isPDF ? pdfCategories : nonPDFCategories;
};
