import { useAuth } from 'auth';
import React, { FC, useMemo } from 'react';
import { Action, useSuspenseQuery } from 'react-fetching-library';
import { Redirect, generatePath } from 'react-router';

import { Paths } from 'router/paths';
import { useQueryParams } from 'shared/hooks/useQueryParams';

export const ShareDocumentationsLink: FC = () => {
  const params = useQueryParams();
  const { isLoggedIn } = useAuth();
  const [organizationId, userType, tokenId] = ['organizationId', 'userType', 'tokenId'].map((v) => params.get(v));

  const queryAction = useMemo(
    (): Action => ({
      endpoint: `/user/invite/token/${tokenId}`,
      method: 'GET',
    }),
    [tokenId]
  );

  const { status } = useSuspenseQuery(queryAction);

  const tokenExists = status === 200;

  const documentations = params.getAll('documentationId[]');

  if (!organizationId || !userType || !tokenId || documentations.length === 0) {
    return <Redirect to="/login" />;
  }

  if (isLoggedIn) {
    const link = generatePath(Paths.DOCUMENTATION_DETAILS);
    return <Redirect to={link} />;
  }

  const path =
    documentations.length === 1
      ? generatePath(Paths.DOCUMENTATION_DETAILS, {
          organizationId,
          membershipId: userType,
          documentationId: documentations[0],
        })
      : generatePath(Paths.DOCUMENTATION_LIST, { organizationId, membershipId: userType });

  if (!tokenExists) {
    return <Redirect to={path} />;
  }

  return <Redirect to={generatePath(Paths.INVITE_USER_ACCEPT_TOKEN, { token: tokenId }) + `?redirect=${path}`} />;
};
