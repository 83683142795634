import { Action, useMutation, useSuspenseQuery } from 'react-fetching-library';

import { useOrganization } from 'contexts/organization';

export const useOrganizationMembershipUrl = (): string => {
  const { organizationId, membershipId } = useOrganization();
  return `/organization/${organizationId}/${membershipId}`;
};

export const useOrganizationUrl = (): string => {
  const { organizationId } = useOrganization();
  return `/organization/${organizationId}`;
};
export const useOrganizationQuery = <T extends {}>(action: Action) => {
  const baseUrl = useOrganizationMembershipUrl();

  action.endpoint = baseUrl + action.endpoint;

  return useSuspenseQuery<T>(action);
};

export const useOrganizationMutation = <T extends {}>(actionCreator: (arg: T) => Action) => {
  const { organizationId, membershipId } = useOrganization();

  const newActionCreator = (nArg: T) => {
    const action = actionCreator(nArg);
    const baseUrl = `/organization/${organizationId}/${membershipId}`;
    action.endpoint = baseUrl + action.endpoint;
    return action;
  };
  return useMutation(newActionCreator);
};
