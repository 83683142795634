import React, { FC } from 'react';
import { Action } from 'react-fetching-library';
import { useRouteMatch } from 'react-router';

import OrganizationStyleDetailsForm from './components/OrganizationStyleDetailsForm';
import { DocumentStyleFormData } from 'models/DocumentStyle';
import { defaultStyle } from 'shared/helpers/documentStyle';

export const OrganizationStyleCreate: FC = () => {
  const {
    params: { id: organizationId },
  } = useRouteMatch<{ id: string }>();

  const createStylesActionCreator = (body: DocumentStyleFormData): Action => ({
    endpoint: '/styles',
    method: 'POST',
    credentials: 'include',
    body: {
      ...body,
      organizationId,
    },
  });

  return (
    <OrganizationStyleDetailsForm
      title="Create new style"
      data={defaultStyle}
      actionCreator={createStylesActionCreator}
      organizationId={organizationId}
    />
  );
};

export default OrganizationStyleCreate;
