// colors
export const white = '#fff';
export const grey = '#636363';
export const lightGrey = '#d2d2d2';
export const lighterGrey = '#f3f3f3';
export const violet = '#8161aa';
export const orangeBad = '#f38622';

// fonts
export const fontSizeBig = '18px';
export const fontSizeNormal = '12px';
export const fontSizeSmall = '10px';

// font weights
export const fontBold = 700;
