import { Middleware, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import reducer from './rootReducer';

const middlewares: Middleware[] = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
export default store;
