import { format, formatDistanceToNow, isValid } from 'date-fns';
import { de, enUS, pl } from 'date-fns/locale';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

interface Props {
  date: string;
}

export const NotificationDate: FC<Props> = (props) => {
  const { i18n } = useTranslation();

  if (!isValid(new Date(props.date))) {
    console.warn('Invalid notification date: ', props.date);

    return <div>--</div>;
  }

  return (
    <Tooltip title={format(new Date(props.date), 'dd/MM/yyyy HH:mm')}>
      <div>
        {formatDistanceToNow(new Date(props.date), {
          addSuffix: true,
          locale: getLang(i18n.language),
        })}
      </div>
    </Tooltip>
  );
};

const getLang = (currentLanguageIso: string) => {
  switch (currentLanguageIso) {
    case 'en': {
      return enUS;
    }
    case 'pl': {
      return pl;
    }
    case 'de': {
      return de;
    }
    default: {
      return enUS;
    }
  }
};
