import React, { FC, useEffect, useState } from 'react';
import { Action, useMutation, useQuery } from 'react-fetching-library';

import * as yup from 'yup';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { DragIndicator as DragIndicatorIcon } from '@material-ui/icons';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { TokenAutentiComponent } from './TokenAutentiComponent';
import { SigningProvider, signingProviders } from 'config/signing-provider';
import { TokenSigniusComponent } from './TokenSigniusComponent';

interface SigningConfiguration {
  autentiConfiguration: AutentiConfiguration;
  signiusConfiguration: SigniusConfiguration;
}

interface AutentiConfiguration {
  clientId: string | null;
  clientSecret: string | null;
  isEnabled: boolean;
  priority: number;
  isTestEnvironment: boolean;
  isOrganizationCustom: boolean;
}

interface SigniusConfiguration {
  token: string | null;
  isEnabled: boolean;
  priority: number;
  isTestEnvironment: boolean;
  isOrganizationCustom: boolean;
}

interface TokenComponentProps {
  organizationId: string;
}

const validationSchema = yup.object().shape({
  autentiConfiguration: yup.lazy((value) =>
    value && value.isOrganizationCustom ?
      yup.object().shape({
        clientId: yup.string().trim().required('org_configuration.form.require'),
        clientSecret: yup.string().trim().required('org_configuration.form.require'),
      }) : yup.object().shape({})),
  signiusConfiguration: yup.lazy((value) =>
    value && value.isOrganizationCustom ?
      yup.object().shape({
        token: yup.string().trim().required('org_configuration.form.require'),
      }) : yup.object().shape({})),
});

export const TokenComponent: FC<TokenComponentProps> = ({
  organizationId
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { payload } = useQuery<SigningConfiguration>({
    endpoint: `/admin/signing/configuration/${organizationId}`,
    method: 'GET',
    credentials: 'include',
  });

  const form = useForm<SigningConfiguration>({
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [isFormDisabled, setIsFormDisabled] = React.useState<boolean>(true);
  const [customConfigurationState, setCustomConfigurationState] =
    React.useState<Map<SigningProvider, boolean>>(new Map([
      [SigningProvider.AUTENTI, false],
      [SigningProvider.SIGNIUS, false],
    ]));
  const [errors, setErrors] = React.useState<object>({});
  const [components, setComponents] = useState<Array<any>>([]);
  const [draggedComponent, setDraggedComponent] = useState<string>("");

  const disableForm = () => {
    setIsFormDisabled(true);
    setCustomConfigurationState(new Map([
      [SigningProvider.AUTENTI, false],
      [SigningProvider.SIGNIUS, false],
    ]));
  };

  const getProviderPriority = (provider: SigningProvider, configuration: SigningConfiguration): number => {
    const configKey = `${provider.toLowerCase()}Configuration` as keyof SigningConfiguration;
    return configuration[configKey]?.priority;
  };

  const getProviderEnabled = (provider: SigningProvider, configuration: SigningConfiguration): boolean => {
    const configKey = `${provider.toLowerCase()}Configuration` as keyof SigningConfiguration;
    return configuration[configKey]?.isOrganizationCustom;
  };

  const componentList = (autentiVisible: boolean, signiusVisible: boolean) => [
    {
      id: SigningProvider.AUTENTI,
      component:
        <TokenAutentiComponent form={form} errors={errors}
          isComponentDisabled={!autentiVisible || isFormDisabled} />
    },
    {
      id: SigningProvider.SIGNIUS,
      component:
        <TokenSigniusComponent form={form} errors={errors}
          isComponentDisabled={!signiusVisible || isFormDisabled} />
    },
  ];

  useEffect(() => {
    if (payload) {
      form.reset({
        autentiConfiguration: {
          ...payload?.autentiConfiguration
        },
        signiusConfiguration: {
          ...payload?.signiusConfiguration
        }
      });
      setComponents(componentList(
        getProviderEnabled(SigningProvider.AUTENTI, payload),
        getProviderEnabled(SigningProvider.SIGNIUS, payload)
      ).sort((a, b) => getProviderPriority(b.id, payload) - getProviderPriority(a.id, payload)));
    }
  }, [payload]);

  const { mutate } = useMutation((action: Action) => action);

  useEffect(() => {
    if (components.length != 0) {
      setComponents(componentList(
        customConfigurationState.get(SigningProvider.AUTENTI) || false,
        customConfigurationState.get(SigningProvider.SIGNIUS) || false
      ).sort((a, b) => getProviderPriority(b.id, form.getValues()) - getProviderPriority(a.id, form.getValues())));
    }
  }, [customConfigurationState, errors]);

  const handleDragStart = (componentId: string) => {
    setDraggedComponent(componentId);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const updateComponentOrder = (targetIndex: number) => {
    const newComponents = Array.from(components);
    const draggedComponentIndex = newComponents.findIndex((component) => component.id === draggedComponent);
    const [draggedComponentData] = newComponents.splice(draggedComponentIndex, 1);
    newComponents.splice(targetIndex, 0, draggedComponentData);
    setComponents(newComponents);
    setDraggedComponent("");
  };

  const updateFormPriorities = (provider: SigningProvider, sourceIndex: number, targetIndex: number) => {
    signingProviders
      .filter(signingProvider => signingProvider !== provider)
      .forEach(signingProvider => {
        const priority = getProviderPriority(signingProvider, form.getValues());
        if (priority == targetIndex) {
          form.setValue(signingProvider.toLowerCase() + "Configuration.priority", sourceIndex);
        }
      });
    form.setValue(provider.toLowerCase() + "Configuration.priority", targetIndex);
  };

  const handleDrop = (event: any, provider: SigningProvider, targetIndex: number, sourceIndex: number) => {
    event.preventDefault();
    updateComponentOrder(targetIndex);
    updateFormPriorities(provider, sourceIndex, targetIndex);
  };

  const handleEditSaveButton = () => {
    if (isFormDisabled) {
      updateIsCustomEnabled();
      setIsFormDisabled(false);
    } else {
      saveSigningOrganizationConfiguration();
    }
  };
  const updateIsCustomEnabled = () => {
    signingProviders.forEach(signingProvider => {
      handleIsCustom(getProviderEnabled(signingProvider, form.getValues()), signingProvider);
    });
  };

  const saveSigningOrganizationConfiguration = () => {
    form.handleSubmit((data) => {
      mutate({
        endpoint: '/admin/signing/configuration/' + organizationId,
        method: 'PUT',
        credentials: 'include',
        body: {
          autentiConfiguration: data.autentiConfiguration,
          signiusConfiguration: data.signiusConfiguration
        },
      }).then(({ payload, status: status }) => {
        if (status === 200) {
          snackbar.enqueueSnackbar(t("org_configuration.form.success"), { variant: 'success' });
          disableForm();
        } else {
          snackbar.enqueueSnackbar(payload?.data ?? "Internal error", { variant: 'error' });
        }
      });
    },
      (errors) => setErrors(errors)
    )();
  };

  const handleResetButton = () => {
    form.reset({
      autentiConfiguration: {
        ...payload?.autentiConfiguration
      },
      signiusConfiguration: {
        ...payload?.signiusConfiguration
      }
    });
    disableForm();
  };

  const handleIsCustom = (checked: boolean, signingProvider: SigningProvider) => {
    setCustomConfigurationState((prevCustomConfigurationState) => {
      const newMap = new Map(prevCustomConfigurationState);
      newMap.set(signingProvider, checked);
      return newMap;
    });
  };

  return (
    <>
      <Box my={2} style={{ display: 'flex', justifyContent: 'start', marginBottom: '10px' }}>
        <Typography variant="h5">Tokens</Typography>
        <Button style={{ marginLeft: '16px' }} color="primary" variant="outlined" onClick={() => handleEditSaveButton()}>
          {isFormDisabled ? t("org_configuration.form.button.edit") : t("org_configuration.form.button.save")}
        </Button>
        {!isFormDisabled ?
          <Button style={{ marginLeft: '16px' }} color="primary" variant="outlined" onClick={() => handleResetButton()}>
            {t("org_configuration.form.button.cancel")}
          </Button> : <div></div>}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' component="th">{t("org_configuration.form.label.provider")}</TableCell>
            <TableCell align='center' component="th">{t("org_configuration.form.label.is.enabled")}</TableCell>
            <TableCell align='center' component="th">{t("org_configuration.form.label.is.test")}</TableCell>
            <TableCell align='center' component="th">{t("org_configuration.form.label.is.custom")}</TableCell>
            <TableCell align='center' component="th">{t("org_configuration.form.label.is.data")}</TableCell>
            <TableCell align='center' component="th">{t("org_configuration.form.label.is.priority")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {components.map((component, index) => (
            <TableRow
              key={component.id}
            >
              <TableCell>
                {t("org_configuration.form.label." + component.id.toLowerCase())}
              </TableCell>
              <TableCell style={{ "width": 50 }}>
                <Controller
                  name={component.id.toLowerCase() + `Configuration.isEnabled`}
                  control={form.control}
                  defaultValue={false}
                  render={({ onChange, value }) => (
                    <>
                      <Checkbox
                        checked={Boolean(value)}
                        onChange={(event) => onChange(event.target.checked)}
                        disabled={isFormDisabled}
                      />
                    </>
                  )}>
                </Controller>
              </TableCell>
              <TableCell style={{ fontSize: 10, "width": 50 }}>
                <Controller
                  name={component.id.toLowerCase() + `Configuration.isTestEnvironment`}
                  control={form.control}
                  defaultValue={false}
                  render={({ onChange, value }) => (
                    <>
                      <Checkbox
                        checked={Boolean(value)}
                        onChange={(event) => {
                          onChange(event.target.checked);
                        }}
                        disabled={isFormDisabled}
                      />
                    </>
                  )}>
                </Controller>
              </TableCell>
              <TableCell style={{ fontSize: 10, "width": 50 }}>
                <Controller
                  name={component.id.toLowerCase() + `Configuration.isOrganizationCustom`}
                  control={form.control}
                  defaultValue={false}
                  render={({ onChange, value }) => (
                    <>
                      <Checkbox
                        checked={Boolean(value)}
                        onChange={(event) => {
                          handleIsCustom(event.target.checked, component.id);
                          onChange(event.target.checked);
                        }}
                        disabled={isFormDisabled}
                      />
                    </>
                  )}>
                </Controller>
              </TableCell>
              <TableCell>
                {component.component}
              </TableCell>
              <TableCell style={{ "width": 50 }}>
                <Controller
                  name={component.id.toLowerCase() + `Configuration.priority`}
                  control={form.control}
                  render={({ value }) => (
                    <>
                      <IconButton
                        disabled={isFormDisabled}
                        draggable={!isFormDisabled}
                        onDragStart={() => handleDragStart(component.id)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, component.id, index, value)}
                      >
                        <DragIndicatorIcon />
                      </IconButton>
                    </>
                  )}>
                </Controller>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
