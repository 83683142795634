import { groupBy } from 'lodash';

import { UserType } from 'models/Enums';

const ownerGroup: UserType[] = [UserType.Owner, UserType.Creator];

export const isOwnerGroup = (userType: UserType) => ownerGroup.includes(userType);

export const isOwner = (userType: UserType) => [UserType.Owner].includes(userType);

export const getUserWithHighestRole = <T extends { userType: UserType }>(users: T[]): T => {
  return users.sort((a, b) => {
    if (a.userType === UserType.Owner) {
      return -1;
    }

    if (b.userType === UserType.Owner) {
      return 1;
    }

    if (a.userType === UserType.Creator) {
      return -1;
    }

    if (b.userType === UserType.Creator) {
      return 1;
    }

    return 1;
  })[0];
};

export const getHighestUserTypeByEmail = <T extends { userType: UserType; email: string }>(users?: T[]): T[] => {
  return Object.values(groupBy(users, 'email')).map((usersGroupedByEmail) => {
    return getUserWithHighestRole(usersGroupedByEmail);
  });
};
