import { useAuth } from 'auth';
import React, { FC, createContext, useContext } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { Typography } from '@material-ui/core';

import ModalUserData from './ModalUserData/ModalUserData';
import { UserType } from 'models/Enums';
import { Attribute } from 'models/documentation/sub-models/attribute/Attribute';
import { usePermissions } from 'user/permissions/PermissionsContext';
import { OrganizationPermission } from 'user/permissions/model';

export interface OrganizationContextValue {
  organizationId: string;
  organizationName: string;
  membershipId: string;
  userType: UserType;
  hasPermission: (permission: OrganizationPermission) => boolean;
  showModalTutorial: boolean;
  setModalTutorialVisible: (isVisible: boolean) => void;
}

const OrganizationContext = createContext<OrganizationContextValue>(null!);

interface OrganizationRouteMatch {
  organizationId: string;
  membershipId: string;
}

export const OrganizationContextProvider: FC = ({ children }) => {
  const {
    params: { membershipId, organizationId },
  } = useRouteMatch<OrganizationRouteMatch>();
  const { t } = useTranslation();
  const [showModalTutorial, setModalTutorialVisible] = React.useState(false);

  const { user } = useAuth();

  const [showModalUserData, setShowModalUserData] = React.useState(
    user?.status === 'NEW' || !user?.firstName
  );
  const organizationUrl = `/organization/${organizationId}`;

  const { payload } = useSuspenseQuery<{ userType: UserType }>({
    endpoint: `${organizationUrl}/${membershipId}`,
    method: 'GET',
    credentials: 'include',
  });

  const { hasOrganizationPermission } = usePermissions();

  const { payload: organization } = useSuspenseQuery<{ name: string; defaultAttributes: Attribute[] }>({
    endpoint: `/organization/${organizationId}`,
    method: 'GET',
    credentials: 'include',
  });

  const role = payload?.userType;

  if (!role || !payload) {
    return <Typography color="error">{t('common.error.unknown')}</Typography>;
  }

  return (
    <OrganizationContext.Provider
      value={{
        organizationName: organization!.name,
        membershipId,
        organizationId,
        userType: role,
        hasPermission: (permission: OrganizationPermission) => hasOrganizationPermission(role, permission),
        showModalTutorial,
        setModalTutorialVisible,
      }}
    >
      {<ModalUserData open={showModalUserData} setShowModalUserData={setShowModalUserData} />}
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);
