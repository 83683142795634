import { PDFDocument } from 'pdf-lib';

export const mergePdfs = async (buffs: ArrayBuffer[]): Promise<Blob> => {
  const doc = await PDFDocument.create();
  const docs = await Promise.all(buffs.map((buff) => PDFDocument.load(buff)));

  for (let d of docs) {
    const pages = await doc.copyPages(d, d.getPageIndices());

    pages.forEach((p) => {
      doc.addPage(p);
    });
  }

  const arr: Uint8Array = await doc.save();

  return new Blob([arr.buffer]);
};
