import { UserType } from './models/Enums';
import {
  reduxAddUserDataAfterLogin,
  reduxClearUserDataAfterLogout,
} from './redux/action-creators/user-action-creators';
import store from './redux/store';

// import { UpdateLastLoggedIn } from './login-handler';
// import { reduxUpdatePanelVisibility } from 'pages/DiscussionPage/redux/action-creators/ui-action-creator';
// import { Panel } from 'pages/DiscussionPage/redux/models/panel-enums';

export const loggedInHandler = async (name: string, email: string): Promise<void> => {
  store.dispatch(reduxAddUserDataAfterLogin({ email, name, role: 'landlord' }));

  // if (agreement && typeof agreement === 'string') syncAgreement(agreement);
  // await syncAgreement(sessionStorage.getItem('agreement') || '');
  // const columnsToSet = await getAllDefaultColumn();
  // store.dispatch(reduxSetAllColumns(columnsToSet));
  // const settedColumns = await getAllSettedColumn();
  // if (settedColumns.length < 2) {
  //   const leaseIDColumn = columnsToSet?.find((column: ColumnToDisplay) => column.Header === 'Lease ID');
  //   const leaseStart = columnsToSet?.find((column: ColumnToDisplay) => column.Header === 'Lease start');
  //   const leaseEnd = columnsToSet?.find((column: ColumnToDisplay) => column.Header === 'Lease end');
  //   const rentCharge = columnsToSet?.find((column: ColumnToDisplay) => column.Header === 'Rent charge');
  //   const area = columnsToSet?.find((column: ColumnToDisplay) => column.Header === 'Area');
  //   const status = columnsToSet?.find((column: ColumnToDisplay) => column.Header === 'Status');
  //   const id = await createSettedColumn({
  //     displayedColumn: [leaseIDColumn, leaseStart, leaseEnd, rentCharge, area, status],
  //   });
  //   store.dispatch(
  //     reduxSetColumnsToDisplay({
  //       id,
  //       columns: [leaseIDColumn, leaseStart, leaseEnd, rentCharge, area, status],
  //     })
  //   );
  // } else {
  //   const id = settedColumns._id;
  //   const columns = settedColumns.displayedColumn;
  //   store.dispatch(reduxSetColumnsToDisplay({ id, columns }));
  // }
  // store.dispatch(reduxUpdateColumnsToDisplay(columnsToSet));

  if (store.getState().user?.role !== UserType.Owner) {
    // const firstLogin = await UpdateLastLoggedIn();
    // if (firstLogin) store.dispatch(reduxUpdatePanelVisibility(Panel.ShowFirstLoginDialog, true ));
  }
};

export const logoutHandler = (): void => {
  store.dispatch(reduxClearUserDataAfterLogout());
};

export const hideFirstLoginDialog = (): void => {
  // store.dispatch(reduxUpdatePanelVisibility(Panel.ShowFirstLoginDialog, false ));
};
