import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@material-ui/core';

interface InviteFormProps {
  onSubmit: (data: any) => any;
  onCancel: () => any;
  loading: boolean;
  error: string;
  open: boolean;
}

export const InviteForm: FC<InviteFormProps> = ({ open, error, onCancel, onSubmit, loading }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    errors: formErrors,
  } = useForm<{ email: string }>({
    criteriaMode: 'all',
  });
  const errorMessage = formErrors?.email?.message;
  return (
    <Dialog open={open} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('invitation_dialog.title', 'Invite new user')}</DialogTitle>
        <DialogContent>
          <FormHelperText error>{error}</FormHelperText>
          <TextField
            fullWidth
            className="input"
            name="email"
            inputRef={register({
              required: t('common.validation_error.required') as string,
            })}
            label={t('invitation_dialog.email_label', 'Email')}
            type="email"
            helperText={errorMessage}
            error={Boolean(errorMessage)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel}>
            {t('invitation_dialog.cancel', 'Cancel')}
          </Button>
          <Button disabled={loading} type="submit" color="primary" variant="contained">
            {t('invitation_dialog.confirm', 'Invite')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
