import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid, MenuItem, TextField } from '@material-ui/core';

import { AttributeType } from 'models/Enums';
import { Attribute } from 'models/documentation/sub-models/attribute/Attribute';

interface AttributeFormModel extends Omit<Attribute, 'id' | 'type'> {
  type: Attribute['type'] | '';
}

export const AttributeForm: FC = () => {
  const { t } = useTranslation();
  const { register, errors, control } = useFormContext<AttributeFormModel>();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          label={t('attribute_form.category_label', 'Category')}
          name="category"
          fullWidth
          inputRef={register({
            required: t('common.validation_error.required') as string,
          })}
          error={Boolean(errors.category)}
          helperText={errors.category?.message}
        />
      </Grid>
      <Grid item>
        <TextField
          label={t('attribute_form.name_label', 'Name')}
          name="name"
          fullWidth
          inputRef={register({
            required: t('common.validation_error.required') as string,
          })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name="type"
          defaultValue=""
          rules={{
            required: t('common.validation_error.required') as string,
            minLength: 1,
          }}
          as={
            <TextField
              fullWidth
              label={t('attribute_form.type_label', 'Type')}
              select
              error={Boolean(errors.type)}
              helperText={errors.type?.message}
            >
              <MenuItem value="" />
              {Object.values(AttributeType).map((v) => (
                <MenuItem value={v} key={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          }
        ></Controller>
      </Grid>
      <Grid item>
        <TextField
          label={t('attribute_form.description_label', 'Description')}
          fullWidth
          name="description"
          inputRef={register({
            required: false,
          })}
        />
      </Grid>{' '}
      <Grid item>
        <TextField
          label={t('attribute_form.default_value_label', 'Default Value')}
          fullWidth
          name="value"
          inputRef={register({
            required: false,
          })}
        />
      </Grid>
    </Grid>
  );
};
