import 'pdfjs-dist/build/pdf.worker.entry';
import React, { FC, useState } from 'react';
import { Document, Page } from 'react-pdf';

import { Box, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import styles from './pdfdocument.module.sass';

interface PdfDocumentProps {
  file: string;
  width?: number;
  showAll: boolean;
  numPages: number;
  setNumPages: (numPages: number) => void;
}

export const PdfDocument: FC<PdfDocumentProps> = ({ file, width = 800, showAll, numPages, setNumPages }) => {
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const useStyles = makeStyles(() => ({
    root: {
      '& > ul > li > button': {
        border: '2px solid #392632',
      },
    },
  }));

  const paginationClasses = useStyles();

  const pagination =
    showAll || numPages < 2 ? null : (
      <Box my={2} display="flex" justifyContent="center">
        <Pagination
          className={paginationClasses.root}
          color="primary"
          size="large"
          count={numPages}
          onChange={(_e, page) => {
            setPageNumber(page);
          }}
        />
      </Box>
    );
  return (
    <div>
      {pagination}
      <Document file={file} options={{ withCredentials: true }} onLoadSuccess={onDocumentLoadSuccess}>
        {showAll ? (
          Array.from({ length: numPages }).map((_v, i) => (
            <Page width={width} pageNumber={i + 1} className={styles.page} />
          ))
        ) : (
          <Page width={width} pageNumber={pageNumber} className={styles.page} />
        )}
      </Document>
    </div>
  );
};
