import React, { FC } from 'react';

import {
  Box,
  TextField,
} from '@material-ui/core';

import { OrganizationConfiguration } from '../containers/OrganizationDetails';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SigningProvider } from 'config/signing-provider';


interface TokenAutentiComponentProps {
  organizationConfiguration?: OrganizationConfiguration;
  isComponentDisabled: boolean;
  form: any;
  errors: any;
}

export const TokenAutentiComponent: FC<TokenAutentiComponentProps> = ({
  isComponentDisabled,
  form,
  errors
}) => {
  const { t } = useTranslation();
  const translateIfExist = (value?: string) => value && t(value);
  const borderlessCellStyle = { "display": "flex", "flexDirection": "row" };
  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="start" >
        <Controller
          name={`autentiConfiguration.clientId`}
          control={form.control}
          defaultValue={''}
          render={({ onChange, onBlur, value }) => (
            <TextField
              style={{ "marginRight": 10, "width": "100%" }}
              label={t("org_configuration.form.field.label.clientId")}
              disabled={isComponentDisabled}
              error={Boolean(errors?.autentiConfiguration?.clientId)}
              helperText={translateIfExist(errors?.autentiConfiguration?.clientId?.message)}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              value={value}
              variant="standard"
              fullWidth={true}
            />
          )}
        />
        <Controller
          name={`autentiConfiguration.clientSecret`}
          control={form.control}
          defaultValue={''}
          render={({ onChange, onBlur, value }) => (
            <TextField
              style={{ "width": "100%" }}
              label={t("org_configuration.form.field.label.clientSecret")}
              disabled={isComponentDisabled}
              error={Boolean(errors?.autentiConfiguration?.clientSecret)}
              helperText={translateIfExist(errors?.autentiConfiguration?.clientSecret?.message)}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              value={value}
              variant="standard"
              fullWidth={true}
            />
          )}
        />
      </Box>

    </>
  );
};
