import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, Typography } from '@material-ui/core';

import { BusyButton } from '../BusyButton/BusyButton';
import { DocumentStyleFontBaseFormFields } from './DocumentStyleFontBaseFormFields';
import { DocumentStyleFontBase, DocumentStyleFontCreateFormData } from 'models/DocumentStyleFont';

interface DocumentStyleFontFormProps {
  onSubmit: (data: DocumentStyleFontCreateFormData) => void;
  defaultValues: DocumentStyleFontBase;
  loading: boolean;
}

export const DocumentStyleFontCreateForm: FC<DocumentStyleFontFormProps> = ({ onSubmit, defaultValues, loading }) => {
  const form = useForm<DocumentStyleFontCreateFormData>({
    defaultValues,
  });

  const { handleSubmit, register, watch } = form;

  const files = watch('files');

  return (
    <form onSubmit={handleSubmit((d) => onSubmit(d))}>
      <DocumentStyleFontBaseFormFields register={register} loading={loading} />
      <Button variant="contained" component="label">
        Upload Font files
        <input
          type="file"
          name="files"
          multiple
          hidden
          ref={register({ required: true })}
          accept=".woff,.woff2,.eot,.ttf,.otf"
        />
      </Button>
      {files ? (
        <Box my={2}>
          <Typography>Files to upload: {!files.length ? 'No files selected' : null} </Typography>
          <ul>
            {[...files].map((e) => (
              <li>{e.name}</li>
            ))}
          </ul>
        </Box>
      ) : null}

      <Box my={2}>
        <BusyButton busy={loading} variant="contained" color="primary" type="submit">
          Save
        </BusyButton>
      </Box>
    </form>
  );
};
