import React, { FC } from 'react';

import { ReactComponent as Logo } from '../../images/logo.svg';

interface Props {}

export const GuestPageWrapper: FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <nav className="navigation">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Logo />
        </div>
      </nav>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '30px',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
