import { Action } from 'react-fetching-library';

export interface CreateFromTemplateArgs {
  organizationId: string;
  membershipId: string;
  id: string;
  parentDocumentationId?: string;
}

export const createFromTemplate = ({ organizationId, membershipId, ...body }: CreateFromTemplateArgs): Action => ({
  endpoint: `/documentation/${organizationId}/${membershipId}`,
  credentials: 'include',
  method: 'POST',
  body,
});

interface GetSubdocumentationsArgs {
  organizationId: string;
  membershipId: string;
  documentationId: string;
}

interface getModalDataArgs {
  organizationId: string;
  membershipId: string;
  documentationId: string;
  flowId: string;
}

export const getSubdocumentationsAction = ({
  organizationId,
  membershipId,
  documentationId,
}: GetSubdocumentationsArgs): Action => {
  return {
    endpoint: `/documentation/${organizationId}/${membershipId}/${documentationId}/subdocumentation`,
    method: 'GET',
    credentials: 'include',
  };
};

export const getDocumentationDeleteUrl = ({
  organizationId,
  membershipId,
  documentationId,
}: {
  organizationId: string;
  membershipId: string;
  documentationId: string;
}) => `/documentation/${organizationId}/${membershipId}/${documentationId}`;

export const getDocumentationModalData = ({
  organizationId,
  membershipId,
  documentationId,
  flowId,
}: getModalDataArgs): Action => {
  return {
    endpoint: `/organization/${organizationId}/${membershipId}/documentation/${documentationId}/flow/${flowId}`,
    method: 'GET',
    credentials: 'include',
  };
};
