// @ts-nocheck
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import List from '@ckeditor/ckeditor5-list/src/list';

import { LIST_START } from './constants';

export class AllowListStartPlugin extends Plugin {
  requires() {
    return [List];
  }
  init() {
    const editor = this.editor;
    // 1.extend schema

    editor.model.schema.extend('listItem', { allowAttributes: LIST_START });

    // 2.set conversion up/down
    editor.conversion.for('downcast').add((dispatcher) => {
      dispatcher.on('attribute', (evt, data, conversionApi) => {
        const viewWriter = conversionApi.writer;
        const viewElement = conversionApi.mapper.toViewElement(data.item);

        const containerElement = viewElement?.parent;

        const { item } = data;

        if (item.name !== 'listItem') {
          return;
        }

        if (data.attributeKey === LIST_START && data.attributeNewValue) {
          viewWriter.setAttribute('start', data.attributeNewValue, containerElement);
        }
      });
    });

    editor.conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'ol',
        key: 'start',
      },
      model: {
        key: LIST_START,

        name: 'start',
      },
      converterPriority: 'highest',
    });
  }
}

export default AllowListStartPlugin;
