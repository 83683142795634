import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { AttributeForm } from './AttributeForm';
import { Attribute } from 'models/documentation/sub-models/attribute/Attribute';

interface AttributeDialogProps {
  initialData?: Attribute;
  onSubmit: (data: Attribute) => void;
  onCancel: () => void;
  title: string;
  confirmText: string;
  cancelText: string;
}

export const AttributeDialog: FC<AttributeDialogProps> = ({
  initialData,
  onSubmit,
  onCancel,
  title,
  confirmText,
  cancelText,
}) => {
  const form = useForm({
    defaultValues: {
      category: '',
      description: '',
      name: '',
      type: '',
      value: '',
      ...initialData,
    },
  });

  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <AttributeForm />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onCancel()}>
          {cancelText}
        </Button>
        <Button color="primary" variant="contained" onClick={form.handleSubmit(onSubmit)}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
