import Command from '@ckeditor/ckeditor5-core/src/command';

import { ATTRIBUTE_MODEL_NAME } from './constants';

export class AttributeCommand extends Command {
  execute({ value: attr }) {
    const editor = this.editor;

    editor.model.change((writer) => {
      // Create a <attribute> elment with the "name" attribute...

      const {
        id,
        attribute: { value, meta = '' },
      } = attr;
      const attribute = writer.createElement(ATTRIBUTE_MODEL_NAME, { id, value, meta });

      // ... and insert it into the document.
      editor.model.insertContent(attribute);

      // Put the selection on the inserted element.
      writer.setSelection(attribute, 'on');
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;

    const isAllowed = model.schema.checkChild(selection.focus.parent, ATTRIBUTE_MODEL_NAME);

    this.isEnabled = isAllowed;
  }
}
