import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { useSnackbar } from 'notistack';
import React, { FC, useMemo, useState } from 'react';
import { Action, useMutation, useParameterizedQuery } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Add, ArrowBack, ArrowForward, Edit, GetApp } from '@material-ui/icons';

import { createFromTemplate } from '../api';
import { API_BASE_URL } from 'api/config';
import { useOrganization } from 'contexts/organization';
import { DocCategory } from 'models/DocCategory';
import { DocumentTemplate } from 'models/DocumentTemplate';
import { DocumentationTemplate } from 'models/DocumentationTemplate';
import { Attribute } from 'models/documentation/sub-models/attribute/Attribute';
import { useOrganizationMutation, useOrganizationQuery } from 'pages/TemplateManagement/hooks/api-helper';
import { DocumentListItemModel } from 'pages/TemplateManagement/model';
import { Paths } from 'router/paths';
import { BusyButton } from 'shared/components/BusyButton/BusyButton';
import ChooseTemplate from 'shared/components/ChooseTemplate/ChooseTemplate';
import { DocumentEditor } from 'shared/components/DocumentEditor/components/DocumentEditor';
import { DocumentListItem } from 'shared/components/ListItem/ListItem';
import { PdfModalUpload } from 'shared/components/PdfModalUpload/PdfModalUpload';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { isPDFCategory } from 'shared/helpers/document/isPdf';

const prepareDoc = (doc: any) => omit(doc, ['id']);

interface NewDocumentState {
  open: boolean;
  id: string | null;
  oldId: string | null;
  document: Omit<DocumentTemplate, 'id'> | null;
}

const useStyles = makeStyles((theme) => ({
  stepper: {
    background: 'transparent',
    width: 550,
  },
  stepLabel: {
    width: 50,
    height: 50,
  },
  pointer: {
    cursor: 'pointer',
  },
  newDocumentIcon: {
    width: 80,
    borderRadius: theme.shape.borderRadius,
    height: 105,
    borderWidth: 1,
    borderStyle: 'dashed',
    margin: '0 auto',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  uploadIcion: {
    width: 172,
    borderRadius: theme.shape.borderRadius,
    height: 108,
    borderWidth: 1,
    borderStyle: 'dashed',
    margin: '0 auto',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    minWidth: 130,
  },

  documentListItem: {
    '&:hover $documentButtons': {
      display: 'block',
    },
  },
  documentButtons: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
}));

const getPdfFileEndpoint = (organizationId: string, membershipId: string, id: string) =>
  `${API_BASE_URL}/organization/${organizationId}/${membershipId}/document-template/${id}/file`;

interface DocumentationCreateNewProps {
  parentDocumentationId?: string;
  isAmendment: boolean;
  getOnConfirmRedirectPath: (newDocumentationId: string) => string;
}

const DocumentationCreateNew: FC<DocumentationCreateNewProps> = ({
  isAmendment,
  getOnConfirmRedirectPath,
  parentDocumentationId,
}) => {
  const styles = useStyles();

  const { t } = useTranslation();
  const { organizationId, membershipId } = useOrganization();
  const { payload: _documentTemplates, query: reloadDocumentTemplates } = useOrganizationQuery<DocumentListItemModel[]>(
    {
      endpoint: '/document-template',
      method: 'GET',
      credentials: 'include',
    }
  );
  const documentTemplates = useMemo(() => sortBy(_documentTemplates, 'id'), [_documentTemplates]);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [confirmedTemplates, setConfirmedTemplates] = useState<string[]>([]);

  const toggleItem = (id: string) => {
    setSelectedTemplates((ids: string[]) => (ids.includes(id) ? ids.filter((e) => e !== id) : ids.concat(id)));
    setConfirmedTemplates((ids: string[]) => (ids.includes(id) ? ids.filter((e) => e !== id) : ids.concat(id)));
  };

  const toggleConfirmedItem = (id: string) => {
    setConfirmedTemplates((ids: string[]) => (ids.includes(id) ? ids.filter((e) => e !== id) : ids.concat(id)));
  };
  const [step, setStep] = useState(0);

  const next = () => {
    setStep((v) => Math.min(v + 1, 2));
  };
  const prev = () => setStep((v) => Math.max(v - 1, 0));

  const hasNext = step < 2;
  const hasPrev = step > 0;

  const [newDocumentState, setNewDocumentState] = useState<NewDocumentState>({
    open: false,
    id: null,
    document: null,
    oldId: null,
  });

  const { mutate: createDocumentTemplate } = useOrganizationMutation((body) => ({
    endpoint: '/document-template',
    method: 'POST',
    credentials: 'include',
    body,
  }));

  const { mutate: updateDocumentTemplate } = useMutation<any>((body: DocumentTemplate) => ({
    endpoint: `/organization/${organizationId}/${membershipId}/document-template`,
    method: 'PUT',
    credentials: 'include',
    body: {
      ...body,
      attributes: body.attributes.map(
        (attr): Attribute => ({
          ...attr,
          id: getDocumentAttributeId(attr),
        })
      ),
    },
  }));

  const pdfUploadEndpoint = `/organization/${organizationId}/${membershipId}/document-template/file`;

  const pdfDocumentTemplateActionCreator = ({
    file,
    name,
    request,
  }: {
    file: Blob;
    name: string;
    request: any;
  }): Action => {
    const formData = new FormData();

    formData.append('file', file, name);
    formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));
    return {
      endpoint: pdfUploadEndpoint,
      method: 'POST',
      credentials: 'include',
      body: formData,
    };
  };

  const [pdfModalOpen, setPdfModalOpen] = useState(false);

  const noSelected = (
    <Box my={2}>
      <Typography color="error">
        {t('documentation_create_new.no_templates_selected', 'No templates selected')}
      </Typography>
    </Box>
  );

  const { mutate: createDocumentationTemplateMutation } = useOrganizationMutation(
    (body: Omit<DocumentationTemplate, 'id' | 'creatorMembershipId'>) => ({
      endpoint: '/agreement-template',
      method: 'POST',
      credentials: 'include',
      body,
    })
  );
  const { mutate: createPdfDocumentationTemplateMutation } = useMutation(pdfDocumentTemplateActionCreator);

  const snackBar = useSnackbar();

  const form = useForm<Pick<DocumentationTemplate, 'name' | 'description'>>();
  const history = useHistory();

  const [isNewDocumentLoading, setNewDocumentLoading] = useState(false);
  const onSubmit = (data: Pick<DocumentationTemplate, 'name' | 'description'>) =>
    createDocumentationTemplateMutation({ ...data, docTemplateIDs: confirmedTemplates }).then((r) => {
      if (r.error) {
        snackBar.enqueueSnackbar(`${t('common.error.unknown')}${r.errorObject?.message}`, { variant: 'error' });
      }
      return r;
    });
  const { mutate: createFromTemplateMutation } = useMutation(createFromTemplate);
  const { loading: documentTemplateLoading, query: getDocumentTemplate } = useParameterizedQuery<DocumentTemplate>(
    (id: string) => ({
      endpoint: `/organization/${organizationId}/${membershipId}/document-template/${id}`,
      method: 'GET',
      credentials: 'include',
    })
  );

  const createDocumentationFromTemplate = (data: Pick<DocumentationTemplate, 'name' | 'description'>) =>
    onSubmit(data).then(({ error, payload: documentationTemplate }) => {
      if (error) {
        throw new Error(t('common.error.unknown'));
      }

      return createFromTemplateMutation({
        id: documentationTemplate.id,
        membershipId,
        organizationId,
        parentDocumentationId,
      });
    });

  const updateTemplate = (data: DocumentTemplate) =>
    updateDocumentTemplate(data).then(() => {
      reloadDocumentTemplates();
      setNewDocumentState((nd) => ({
        ...nd,
        oldId: null,
        id: data.id,
        document: prepareDoc({ ...nd.document, ...data }),
      }));
    });

  return newDocumentState.open && newDocumentState.document ? (
    <DocumentEditor
      leftColumnHeader={
        <Typography variant="h5">{t('documentation_create_new.editor_title', 'Document Template')}</Typography>
      }
      editableStyle
      canEditAttribute
      canAddAttribute
      canRemoveAttribute
      displayAttributeValues={false}
      editableContent
      commentsEnabled={false}
      document={newDocumentState.document}
      onConfirm={async (d) => {
        setNewDocumentLoading(true);

        if (newDocumentState?.id) {
          await updateTemplate({
            ...newDocumentState.document!,
            ...d,
            organizationId,
            id: newDocumentState.id,
          });
          setNewDocumentLoading(false);
          return;
        }

        const isPDF = isPDFCategory(d.category);

        if (isPDF) {
          if (!newDocumentState.oldId) {
            return;
          }
          const pdfFileBlob = await fetch(getPdfFileEndpoint(organizationId, membershipId, newDocumentState.oldId), {
            credentials: 'include',
          }).then((r) => r.blob());

          const { payload } = await createPdfDocumentationTemplateMutation({
            file: pdfFileBlob,
            name: d.name,
            request: {
              docCategory: d.category,
            },
          });
          if (payload.id) {
            const { payload: newExistingDocTemplate } = await getDocumentTemplate(payload.id);

            await updateTemplate({
              ...newExistingDocTemplate!,
              ...d,
              organizationId,
              id: payload.id,
            });
          }
        } else {
          const r = await createDocumentTemplate({ organizationId, ...d });

          setNewDocumentState((nd) => ({
            ...nd,
            document: prepareDoc({ ...nd.document!, ...d }),
            id: r.payload.id,
            oldId: null,
          }));
          await reloadDocumentTemplates();
        }

        setNewDocumentLoading(false);
      }}
      pdfFile={getPdfFileEndpoint(organizationId, membershipId, newDocumentState.id || newDocumentState.oldId || '')}
      additionalButtons={
        <>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setNewDocumentState({
                  open: false,
                  id: null,
                  document: null,
                  oldId: null,
                });
              }}
            >
              {t('documentation_create_new.go_back', 'Go Back')}
            </Button>
          </Grid>
          <Grid item>
            <BusyButton
              disabled={!newDocumentState?.id}
              busy={isNewDocumentLoading}
              variant="contained"
              color="primary"
              onClick={() => {
                if (newDocumentState.id && !selectedTemplates.includes(newDocumentState.id)) {
                  toggleItem(newDocumentState.id);
                }
                setNewDocumentState({
                  open: false,
                  id: null,
                  document: null,
                  oldId: null,
                });
              }}
            >
              {t('documentation_create_new.add_to_template', 'Add to template')}
            </BusyButton>
          </Grid>
        </>
      }
    />
  ) : (
    <>
      {pdfModalOpen ? (
        <PdfModalUpload
          endpoint={pdfUploadEndpoint}
          onConfirm={(ids: string[]) => {
            reloadDocumentTemplates();
            setConfirmedTemplates((t) => t.concat(ids.filter((i) => !t.includes(i))));
            setSelectedTemplates((t) => t.concat(ids.filter((i) => !t.includes(i))));

            setPdfModalOpen(false);
          }}
          onCancel={() => {
            setPdfModalOpen(false);
          }}
        />
      ) : null}
      <Typography variant="h3">
        {isAmendment
          ? t('documentation_create_new.amendment_title', 'Create New Amendment')
          : t('documentation_create_new.documentation_title', 'Create New Documentation')}
      </Typography>

      <Container maxWidth="md">
        <Box display="flex" justifyContent="center">
          <Stepper activeStep={step} classes={{ root: styles.stepper }}>
            <Step>
              <StepLabel StepIconProps={{ classes: { root: styles.stepLabel } }}></StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconProps={{ classes: { root: styles.stepLabel } }}></StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconProps={{ classes: { root: styles.stepLabel } }}></StepLabel>
            </Step>
          </Stepper>
        </Box>

        {step === 0 ? (
          <Box textAlign="center">
            <Typography variant="h3">
              {t('documentation_create_new.add_document_templates.title', 'Add document templates')}
            </Typography>
            <Typography>
              {t(
                'documentation_create_new.add_document_templates.description',
                'Create new or select existing document templates you need to include'
              )}
            </Typography>
          </Box>
        ) : null}
        {step === 1 ? (
          <Box textAlign="center">
            <Typography variant="h3">
              {t('documentation_create_new.upload_documents.title', 'Upload documents from your drive')}
            </Typography>
            <Typography>
              {t(
                'documentation_create_new.upload_documents.description',
                'Upload your pdf-files that you would like to attach to this Documentation Template'
              )}
            </Typography>
          </Box>
        ) : null}
        {step === 2 ? (
          <Box textAlign="center">
            <Typography variant="h3">
              {t('documentation_create_new.name_documentation_template.title', 'Name your Documentation Template')}
            </Typography>
            <Typography>
              {t(
                'documentation_create_new.name_documentation_template.description',
                'Use a name and description to identify Template and save it'
              )}
            </Typography>
          </Box>
        ) : null}

        <Box my={4} display="flex" alignItems="center" justifyContent="space-between">
          <Box flexGrow={0} flexShrink={0} className={styles.button}>
            {hasPrev ? (
              <Button variant="outlined" color="primary" startIcon={<ArrowBack />} onClick={() => prev()}>
                {t('documentation_create_new.back_button', 'Back')}
              </Button>
            ) : null}
          </Box>
          {step === 0 ? (
            <Box
              flexGrow={0}
              flexShrink={0}
              textAlign="center"
              className={styles.pointer}
              onClick={() => {
                setNewDocumentState({
                  open: true,
                  id: null,
                  oldId: null,
                  document: {
                    attributes: [],
                    category: DocCategory.Contract,
                    content: '<p></p>',
                    name: t('documentation_create_new.new_document_template.default_name', 'Document'),
                    commentThreads: [],
                    creatorMembershipId: '',
                    organizationId: organizationId,
                  },
                });
              }}
            >
              <Box display="flex" alignItems="center" justifyContent="center" className={styles.newDocumentIcon}>
                <Add />
              </Box>
              <Typography>
                {t('documentation_create_new.new_document_template.text', 'New document template')}
              </Typography>
            </Box>
          ) : null}
          {step === 1 ? (
            <Box
              flexGrow={0}
              flexShrink={0}
              textAlign="center"
              className={styles.pointer}
              onClick={() => {
                setPdfModalOpen(true);
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className={styles.uploadIcion}
              >
                <GetApp />
                <Typography>{t('documentation_create_new.upload_documents.choose_file', 'Choose a file')}</Typography>
              </Box>
              <Typography>
                {t('documentation_create_new.upload_documents.upload_templates', 'Upload your pdf template(s)')}
              </Typography>
            </Box>
          ) : null}

          <Box flexGrow={0} flexShrink={0} className={styles.button}>
            {hasNext ? (
              <Button variant="outlined" color="primary" startIcon={<ArrowForward />} onClick={() => next()}>
                {t('documentation_create_new.next_step_button', 'Next Step')}
              </Button>
            ) : null}
          </Box>
        </Box>
        {step === 0 && documentTemplates ? (
          <ChooseTemplate
            defaultExpanded
            templates={documentTemplates}
            selectedTemplates={selectedTemplates}
            toggleItem={toggleItem}
            itemClassName={styles.documentListItem}
            itemContent={({ id }) => {
              return (
                <ButtonGroup className={styles.documentButtons}>
                  <BusyButton
                    busy={documentTemplateLoading}
                    onClick={() => {
                      getDocumentTemplate(id).then(({ payload, error }) => {
                        if (error) {
                          return;
                        }
                        setNewDocumentState({
                          open: true,
                          id: null,
                          oldId: id,
                          document: prepareDoc(payload!),
                        });
                      });
                    }}
                  >
                    <Edit />
                  </BusyButton>
                </ButtonGroup>
              );
            }}
          />
        ) : null}
        {step === 1 && documentTemplates ? (
          <>
            <Typography>
              {t(
                'documentation_create_new.document_templates_summary',
                'Document templates in this Documentation Template:'
              )}
            </Typography>
            {selectedTemplates.length ? null : noSelected}
            <ChooseTemplate
              flat
              templates={documentTemplates.filter((d) => selectedTemplates.includes(d.id))}
              selectedTemplates={confirmedTemplates}
              toggleItem={toggleConfirmedItem}
            />
          </>
        ) : null}
        {step === 2 && documentTemplates ? (
          <>
            <Box my={2}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('documentation_create_new.form.name_label', 'Name')}
                    placeholder={t('documentation_create_new.form.name_placeholder', 'Documentation Template Name')}
                    name="name"
                    inputRef={form.register({
                      required: t('common.validation_error.required', 'Value is required') as string,
                    })}
                    error={Boolean(form.errors?.name)}
                    helperText={
                      form.errors?.name?.message ??
                      t('documentation_create_new.form.name_error', 'Name your Documentation Template')
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('documentation_create_new.form.description_label', 'Description')}
                    name="description"
                    inputRef={form.register()}
                  />
                </Grid>
              </Grid>
            </Box>
            <Typography>
              {t(
                'documentation_create_new.document_templates_summary',
                'Document templates in this Documentation Template:'
              )}
            </Typography>
            {confirmedTemplates.length ? (
              <Grid container spacing={2}>
                {confirmedTemplates.map((id) => {
                  return (
                    <Grid item key={id}>
                      <DocumentListItem item={documentTemplates.find((e) => e.id === id)!} actionButton={null} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              noSelected
            )}
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(generatePath(Paths.DOCUMENTATION_LIST, { organizationId, membershipId }));
                  }}
                >
                  {t('documentation_create_new.cancel_button', 'Cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={form.handleSubmit((data: Pick<DocumentationTemplate, 'name' | 'description'>) => {
                    onSubmit(data).then(() => {
                      history.push(
                        generatePath(Paths.DOCUMENTATION_LIST, {
                          organizationId,
                          membershipId,
                        })
                      );
                    });
                  })}
                >
                  {t('documentation_create_new.save_documentation_template_button', 'Save')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={form.handleSubmit((data) => {
                    createDocumentationFromTemplate(data).then(({ payload: newDocumentation }) => {
                      history.push(getOnConfirmRedirectPath(newDocumentation.id));
                    });
                  })}
                >
                  {isAmendment
                    ? t('documentation_create_new.save_go_amendment', 'Save and go to Amendment')
                    : t('documentation_create_new.save_go_documentation', ' Save and go to Documentation')}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default DocumentationCreateNew;
