import React, { FC, useEffect, useState } from 'react';

import { Box, Card, CardContent } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Action, useMutation } from 'react-fetching-library';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BillingsGraphsComponent } from './BillingsGraphsComponent';
import { BillingsGraphsSearchComponent } from './BillingsGraphsSearch';
import { BillingsPriceSummaryComponent } from './BillingsPriceSummary';
import { TransformedData, transformPayload } from './graphPayloadTransformator';
import Yup from 'libs/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';

interface BillingsSummaryComponentProps {
  organizationId: string;
}

export interface SigningOrganizationGraphInput {
  graphPeriod: SigningGraphPeriodType;
  from: Date;
  to: Date;
}

export type SigningGraphPeriodType = "DAY" | "MONTH";
export const graphPeriods: Array<SigningGraphPeriodType> = ["DAY", "MONTH"];

export const BillingsSummaryComponent: FC<BillingsSummaryComponentProps> = ({
  organizationId,
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [graphsData, setGraphsData] = useState<TransformedData>();

  const form = useForm<SigningOrganizationGraphInput>(
    {
      defaultValues: {
        graphPeriod: 'DAY',
        from: (() => {
          const date = new Date();
          date.setMonth(date.getMonth() - 1);
          return date.toLocaleDateString('en-CA');
        })(),
        to: new Date().toLocaleDateString('en-CA')
      },
    }
  );

  const { mutate } = useMutation((body: SigningOrganizationGraphInput): Action => ({
    method: 'POST',
    endpoint: `/organization/signing/graphs/${organizationId}`,
    credentials: 'include',
    body,
  }));

  const debouncedSubmit = debounce(() => {
    form.handleSubmit((data) => {
      mutate(data).then(({ payload, status }) => {
        if (status === 200) {
          setGraphsData(transformPayload(payload));
        }
        if (status && payload && status >= 400) {
          snackbar.enqueueSnackbar(payload?.message, { variant: 'error' });
        }
      });
    })();
  }, 300);

  const searchGraph = () => {
    debouncedSubmit();
  };

  useEffect(() => searchGraph(), []);

  return (
    <>
      <Card style={{ width: '100%' }}>
        <CardContent>
          <Box style={{ display: "flex", flexWrap: 'wrap', justifyContent: 'start', flexDirection: 'row' }}>
            <BillingsPriceSummaryComponent organizationId={organizationId} />
            <BillingsGraphsComponent graphsData={graphsData} />
          </Box>
          <BillingsGraphsSearchComponent form={form} searchGraph={searchGraph} />
        </CardContent>
      </Card>
    </>
  );
};