// @ts-ignore
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// @ts-ignore
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// @ts-ignore
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// @ts-ignore
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// @ts-ignore
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
// @ts-ignore
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
// @ts-ignore
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// @ts-ignore
import Comments from '@ckeditor/ckeditor5-comments/src/comments';
// @ts-ignore
import CommentsRepository from '@ckeditor/ckeditor5-comments/src/comments/commentsrepository';
// @ts-ignore
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// @ts-ignore
import ExportPdf from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';
// @ts-ignore
import Font from '@ckeditor/ckeditor5-font/src/font';
// @ts-ignore
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
// @ts-ignore
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
// @ts-ignore
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
// @ts-ignore
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// @ts-ignore
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
// @ts-ignore
import Image from '@ckeditor/ckeditor5-image/src/image';
// @ts-ignore
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
// @ts-ignore
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// @ts-ignore
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// @ts-ignore
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
// @ts-ignore
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// @ts-ignore
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
// @ts-ignore
import Link from '@ckeditor/ckeditor5-link/src/link';
// @ts-ignore
import List from '@ckeditor/ckeditor5-list/src/list';
// @ts-ignore
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
// @ts-ignore
// @ts-ignore
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';
// @ts-ignore
import Pagination from '@ckeditor/ckeditor5-pagination/src/pagination';
// @ts-ignore
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// @ts-ignore
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
// @ts-ignore
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
// @ts-ignore
import SpecialCharactersArrows from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows';
// @ts-ignore
import SpecialCharactersCurrency from '@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency';
// @ts-ignore
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
// @ts-ignore
import SpecialCharactersLatin from '@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin';
// @ts-ignore
import SpecialCharactersMathematical from '@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical';
// @ts-ignore
import SpecialCharactersText from '@ckeditor/ckeditor5-special-characters/src/specialcharacterstext';
// @ts-ignore
import Table from '@ckeditor/ckeditor5-table/src/table';
// @ts-ignore
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
// @ts-ignore
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
// @ts-ignore
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// @ts-ignore
import TrackChanges from '@ckeditor/ckeditor5-track-changes/src/trackchanges';
// @ts-ignore
import TrackChangesData from '@ckeditor/ckeditor5-track-changes/src/trackchangesdata';
// @ts-ignore
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import i18n from 'i18next';
import uniq from 'lodash/uniq';

import { pageFormat } from './config';
import { TrackChangesIntegration } from './plugins/TrackChangesIntegration';
import { AllowNestedTablePlugin } from './plugins/allowNestedTablePlugin';
// @ts-ignore
import { Attribute as AttributePlugin } from './plugins/attribute/Attribute';
import { Comments as CommentsPlugin } from './plugins/comments/Comments';
import { createClassPlugin } from './plugins/createClassPlugin';
import { createStylePlugin } from './plugins/createStylePlugin';
import { AllowListStartPlugin } from './plugins/listStart/AllowListStartPlugin';
import { SetListStartPlugin } from './plugins/listStart/SetListStartPlugin';
import { DocumentStyle } from 'models/DocumentStyle';
import { DocumentStyleFont } from 'models/DocumentStyleFont';
import { customComponentClassPrefix } from 'shared/helpers/documentStyle';

export const getPlugins = ({ commentsEnabled, style }: { commentsEnabled: boolean; style: DocumentStyle }) => [
  Essentials,
  Paragraph,
  AttributePlugin,
  createClassPlugin('customComponent', {
    label: i18n.t('editor.custom_components_plugin.dropdown_label', 'Custom components'),
    forceValue: true,
    options: style.options.customComponents.map(({ label: name, value }) => ({
      name,
      value,
      className: `${customComponentClassPrefix}${name}`,
    })),
    priority: 'highest',
  }),

  createStylePlugin('lineHeight', {
    label: i18n.t('editor.line_height_plugin.dropdown_label', 'Line height'),
    block: true,
    options: [0.5, 0.75, 1, 1.15, 1.25, 1.5, 2, 2.5].map((value) => {
      return {
        name: String(value),
        style: {
          'line-height': String(value),
        },
      };
    }),
    priority: 'highest',
    forceValue: true,
  }),

  Heading,
  Bold,
  Italic,
  Underline,
  Link,
  Table,
  AllowNestedTablePlugin,
  TableToolbar,
  TableProperties,
  TableCellProperties,

  Alignment,
  Font,
  FontFamily,
  List,
  Pagination,
  PageBreak,
  ExportPdf,

  Image,
  ImageUpload,
  ImageResize,
  ImageToolbar,
  ImageStyle,
  Base64UploadAdapter,
  FontBackgroundColor,
  FontColor,
  Superscript,
  Subscript,
  Strikethrough,
  SpecialCharacters,
  SpecialCharactersEssentials,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Indent,
  IndentBlock,
  HorizontalLine,
  ListStyle,
  AllowListStartPlugin,
  SetListStartPlugin,
  ...(commentsEnabled ? [Comments, CommentsRepository, CommentsPlugin] : []),
  createStylePlugin('width', {
    label: i18n.t('editor.width_plugin.dropdown_label', 'Width'),
    options: Array.from({ length: 10 })
      .map((i, v) => ({ name: v + 1 + 'em', style: { width: v + 1 + 'em', display: 'inline-block' } }))
      .concat(
        Array.from({ length: 20 }).map((_v, i) => {
          const v = `${(i + 1) * 5}%`;
          return { name: v, style: { width: v, display: 'inline-block' } };
        })
      ),
    forceValue: true,
    priority: 'low',
  }),
  // TrackChanges,
  // TrackChangesData,
  // TrackChangesIntegration,
  PasteFromOffice,
];

export const getPluginsForExport = ({ commentsEnabled, style }: { commentsEnabled: boolean; style: DocumentStyle }) => [
  Essentials,
  Paragraph,
  AttributePlugin,
  createClassPlugin('customComponent', {
    label: i18n.t('editor.custom_components_plugin.dropdown_label', 'Custom components'),
    forceValue: true,
    options: style.options.customComponents.map(({ label: name, value }) => ({
      name,
      value,
      className: `${customComponentClassPrefix}${name}`,
    })),
    priority: 'highest',
  }),

  createStylePlugin('lineHeight', {
    label: i18n.t('editor.line_height_plugin.dropdown_label', 'Line height'),
    block: true,
    options: [0.5, 0.75, 1, 1.15, 1.25, 1.5, 2, 2.5].map((value) => {
      return {
        name: String(value),
        style: {
          'line-height': String(value),
        },
      };
    }),
    priority: 'highest',
    forceValue: true,
  }),

  Heading,
  Bold,
  Italic,
  Underline,
  Link,
  Table,
  AllowNestedTablePlugin,
  TableToolbar,
  TableProperties,
  TableCellProperties,

  Alignment,
  Font,
  FontFamily,
  List,
  Pagination,
  PageBreak,
  ExportPdf,

  Image,
  ImageUpload,
  ImageResize,
  ImageToolbar,
  ImageStyle,
  Base64UploadAdapter,
  FontBackgroundColor,
  FontColor,
  Superscript,
  Subscript,
  Strikethrough,
  SpecialCharacters,
  SpecialCharactersEssentials,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Indent,
  IndentBlock,
  HorizontalLine,
  ListStyle,
  AllowListStartPlugin,
  SetListStartPlugin,
  ...(commentsEnabled ? [Comments, CommentsRepository, CommentsPlugin] : []),
  createStylePlugin('width', {
    label: i18n.t('editor.width_plugin.dropdown_label', 'Width'),
    options: Array.from({ length: 10 })
      .map((i, v) => ({ name: v + 1 + 'em', style: { width: v + 1 + 'em', display: 'inline-block' } }))
      .concat(
        Array.from({ length: 20 }).map((_v, i) => {
          const v = `${(i + 1) * 5}%`;
          return { name: v, style: { width: v, display: 'inline-block' } };
        })
      ),
    forceValue: true,
    priority: 'low',
  }),
  // TrackChanges,
  // TrackChangesData,
  // TrackChangesIntegration,
  PasteFromOffice,
];

export const getPluginsConfig = ({ options, margin }: DocumentStyle, customFonts: DocumentStyleFont[] | null) => {
  const pluginOptions: Record<string, any> = {
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
    },
    pagination: {
      pageWidth: pageFormat.width,
      pageHeight: pageFormat.height,
      pageMargins: margin,
    },
    collaboration: {
      channelId: 'default-channel',
    },
  };

  if (options.fontFamily.length || customFonts?.length) {
    pluginOptions.fontFamily = {
      options: uniq(options.fontFamily.map((f) => f.value).concat(customFonts?.map((c) => c.fontFamily) || [])),
    };
  }

  if (options.fontSize.length) {
    pluginOptions.fontSize = {
      options: options.fontSize.map(({ value: model, label: title }) => ({ title, model })),
    };
  }
  if (options.color.length) {
    const colors = options.color.map(({ label, value: color }) => ({ label, color }));
    pluginOptions.fontColor = {
      colors,
    };
    pluginOptions.fontBackgroundColor = {
      colors,
    };
    const tableColors = {
      borderColors: colors,
      backgroundColors: colors,
    };

    pluginOptions.table.tableCellProperties = tableColors;
    pluginOptions.table.tableProperties = tableColors;
  }

  return pluginOptions;
};

export const getToolbar = ({ comments, trackChanges }: { comments: boolean; trackChanges?: boolean }) => [
  'pagebreak',
  'previousPage',
  'nextPage',
  'pageNavigation',
  ...(comments ? ['|', 'comment', '|'] : []),
  'pactt-attribute',
  '|',
  'heading',
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  'strikethrough',
  ...(trackChanges ? ['|', 'trackChanges', '|'] : []),
  'subscript',
  'superscript',
  'bold',
  'italic',
  'underline',
  '|',
  'outdent',
  'indent',
  '|',
  'bulletedList',
  'numberedList',
  'alignment',
  '|',
  'horizontalLine',
  'specialCharacters',
  '|',
  'imageUpload',
  'link',
  'insertTable',
  'width',
  'lineHeight',
  'customComponent',
  '|',
  'listStart',
  '|',
  'undo',
  'redo',
];
