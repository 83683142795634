import React, { FC } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { generatePath, useHistory } from 'react-router';

import { Box, Button, Typography } from '@material-ui/core';

import { DocumentStyleFontTable } from './DocumentStyleFontTable';
import { DocumentStyleFont } from 'models/DocumentStyleFont';
import { Paths } from 'router/paths';

interface DocumentStyleFontListProps {
  organizationId: string;
}

export const DocumentStyleFontList: FC<DocumentStyleFontListProps> = ({ organizationId }) => {
  const { query: reload, payload: data = [] } = useSuspenseQuery<DocumentStyleFont[]>({
    endpoint: `/font?organizationId=${organizationId}`,
    method: 'GET',
    credentials: 'include',
  });

  const history = useHistory();

  const onEdit = (fontId: string) => {
    history.push(generatePath(Paths.ADMIN_ORGANIZATION_STYLE_FONT_DETAILS, { id: organizationId, fontId }));
  };

  return (
    <>
      <Box my={2}>
        <Typography variant="h5">Fonts</Typography>
      </Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => history.push(generatePath(Paths.ADMIN_ORGANIZATION_STYLE_FONT_CREATE, { id: organizationId }))}
      >
        + Create new font
      </Button>

      <DocumentStyleFontTable data={data} onEdit={onEdit} reload={reload} />
    </>
  );
};
