import React, { FC } from 'react';
import { useQuery } from 'react-fetching-library';

import FormWrapper, { FormWrapperProps } from './FormWrapper';
import { DocumentStyleFont } from 'models/DocumentStyleFont';
import { DocumentStyleForm } from 'shared/components/DocumentStyle/DocumentStyleForm';

interface OrganizationStyleDetailsFormProps extends Omit<FormWrapperProps, 'Form' | 'formProps'> {
  organizationId: string;
}
export const OrganizationStyleDetailsForm: FC<OrganizationStyleDetailsFormProps> = ({ organizationId, ...props }) => {
  const { payload: fonts } = useQuery<DocumentStyleFont[]>({
    endpoint: `/font?organizationId=${organizationId}`,
    method: 'GET',
    credentials: 'include',
  });
  return fonts ? (
    <FormWrapper organizationId={organizationId} Form={(p) => <DocumentStyleForm {...p} fonts={fonts} />} {...props} />
  ) : null;
};

export default OrganizationStyleDetailsForm;
