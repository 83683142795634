import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Card, CardContent, CardHeader, Link } from '@material-ui/core';

import { BusyButton } from '../BusyButton/BusyButton';
import { DocumentStyleFontBaseFormFields } from './DocumentStyleFontBaseFormFields';
import { DocumentStyleFont, DocumentStyleFontUpdateFormData } from 'models/DocumentStyleFont';

interface DocumentStyleFontFormProps {
  onSubmit: (data: DocumentStyleFontUpdateFormData) => void;
  defaultValues: DocumentStyleFont;
  loading: boolean;
}

export const DocumentStyleFontUpdateForm: FC<DocumentStyleFontFormProps> = ({ onSubmit, defaultValues, loading }) => {
  const form = useForm({
    defaultValues,
  });

  const { handleSubmit, register } = form;

  return (
    <form onSubmit={handleSubmit((d) => onSubmit(d))}>
      <DocumentStyleFontBaseFormFields register={register} loading={loading} />
      <Card>
        <CardHeader title="Uploaded files"></CardHeader>
        <CardContent>
          <ul>
            {defaultValues.fileLinks.map((url: string) => {
              const href = `//${url}`;
              return (
                <li>
                  <Link href={href}>{href}</Link>
                </li>
              );
            })}
          </ul>
        </CardContent>
      </Card>
      <Box my={2}>
        <BusyButton busy={loading} variant="contained" color="primary" type="submit">
          Save
        </BusyButton>
      </Box>
    </form>
  );
};
