import React, { HTMLProps } from 'react';

import { Tooltip } from '@material-ui/core';

interface StyledInputProps {
  size: 'medium' | 'large';
  wide?: boolean;
  label?: string;
  name?: string;
  disabled?: boolean;
  value?: any;
  onChange?: any;
  placeholder?: string;
  attributeId?: string;
  description?: string | null;
  type?: HTMLProps<HTMLInputElement>['type'];
  gray?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

const StyledInput = (props: StyledInputProps): JSX.Element => {
  return (
    <div className={`input__section ${props.wide ? 'input__section--wide' : ''} ${props.size}`}>
      {props.description ? (
        <Tooltip title={props.description} placement="top-start">
          <div className={`input__section__label${props.wide ? '--wide' : ''} ${props.size}`}>{props.label}</div>
        </Tooltip>
      ) : (
        <div className={`input__section__label${props.wide ? '--wide' : ''} ${props.size}`}>{props.label}</div>
      )}
      <input
        id={props.attributeId}
        type={props.type}
        ref={props.inputRef}
        name={props.name}
        className={`input__section__field${props.wide ? '--wide' : ''}${props.gray ? '--gray' : ''} ${props.size}`}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.label}
        disabled={props.disabled}
      />
    </div>
  );
};

export default StyledInput;
