export enum Language {
    PL = 'pl',
    EN = 'en',
    DE = 'de',
}

export const defaultLanguage: Language = Language.PL;

export const supportedLanguages: Language[] = Object.values(Language);

export const languageLabels = {
    [Language.PL]: 'language.polish',
    [Language.EN]: 'language.english',
    [Language.DE]: 'language.german',
};

export const getLanguageLabelKey = (value: Language) => languageLabels[value];