import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';

interface ConfirmDialogProps extends DialogProps {
  onConfirm?: () => void;
  onClose: () => void;
  title: string;
  confirmText?: string;
  closeText?: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  onConfirm,
  onClose,
  title,
  closeText,
  confirmText,
  children,
  ...dialogProps
}: ConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{title}</DialogTitle>
      {children ? <DialogContent>{children}</DialogContent> : null}
      <DialogActions>
        {onConfirm ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {confirmText ? confirmText : t('confirm_dialog.default_confirm_text', 'Yes')}
          </Button>
        ) : null}
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          {closeText ? closeText : t('confirm_dialog.default_close_text', 'No')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
