import filter from 'lodash/filter';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Company, useBIRApi } from './useBIRApi';
import { useWatchAttribute } from './useWatchAttribute';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { BIRQuery } from 'shared/hooks/useBIRApi';

const autoCompleteCategory = 'Contractor';
const KRSQuery = {
  name: 'KRS',
  category: autoCompleteCategory,
};
const NIPQuery = {
  name: 'NIP',
  category: autoCompleteCategory,
};
const REGONQuery = {
  name: 'REGON',
  category: autoCompleteCategory,
};

const attributesMap: Record<string, keyof Company> = {
  KRS: 'krs',
  Name: 'name',
  'Seat Address': 'seatAddress',
  'Post Address': 'postAddress',
  'Registry Court': 'registryCourt',
  NIP: 'nip',
  REGON: 'regon',
};
export const useAttributeCompanyAutocomplete = (
  attributes: DocumentAttribute[],
  updateAttributes: (attr: DocumentAttribute[]) => void
) => {
  const filteredAttributes = useMemo(() => filter(attributes, { category: autoCompleteCategory }), [attributes]);

  const { t } = useTranslation();
  const [birQuery, setBirQuery] = useState<BIRQuery | null>(null);

  const [birResult, birReset] = useBIRApi(birQuery);
  const pendingUpdate = useRef(false);

  const update = useCallback(({ name: key, value }) => {
    if (pendingUpdate.current) {
      return;
    }
    setBirQuery({ key, value });
  }, []);

  useWatchAttribute(filteredAttributes, NIPQuery, update);
  useWatchAttribute(filteredAttributes, KRSQuery, update);
  useWatchAttribute(filteredAttributes, REGONQuery, update);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!birResult) {
      pendingUpdate.current = false;
      return;
    }
    birReset();
    pendingUpdate.current = true;
    const names = Object.keys(attributesMap);

    const attributesToUpdate = filteredAttributes.flatMap((attr) => {
      return names.includes(attr.name)
        ? {
            ...attr,
            value: birResult[attributesMap[attr.name]],
          }
        : [];
    });
    updateAttributes(attributesToUpdate);

    enqueueSnackbar(
      t('company_autocomplete.success_message', 'The {{category}} category values has been successfully completed.', {
        category: autoCompleteCategory,
      }),
      {
        variant: 'success',
      }
    );
  }, [updateAttributes, birReset, birResult, filteredAttributes, enqueueSnackbar, t]);
};
