import React, { FC, forwardRef } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface BusyButtonProps extends ButtonProps {
  busy?: boolean;
  component?: any;
}
const useStyles = makeStyles(() => ({
  absolute: {
    position: 'absolute',
  },
}));
export const BusyButton: FC<BusyButtonProps> = forwardRef(({ busy, children, disabled, ...buttonProps }, ref) => {
  const styles = useStyles();

  return (
    <Button ref={ref} {...buttonProps} disabled={Boolean(busy || disabled)}>
      {children}
      {busy ? <CircularProgress className={styles.absolute} size={24} /> : null}
    </Button>
  );
});
