import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { BillingsPriceDialog } from './BillingsPriceDialog';
import { BillingsSummaryComponent } from './BillingsSummaryComponent';
import { BillingsCsvExport } from './BillingsCsvExport';

interface BillingsComponentProps {
  organizationId: string;
}

export const BillingsComponent: FC<BillingsComponentProps> = ({
  organizationId
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box style={{ display: 'flex' }}>
        <Typography variant="h6">{t('org_configuration.billings.pricing.label')}</Typography>
        <BillingsPriceDialog organizationId={organizationId} />
        <BillingsCsvExport organizationId={organizationId} />
      </Box>
      <BillingsSummaryComponent organizationId={organizationId} />
    </>
  );
};
