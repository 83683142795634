import React, { CSSProperties, FC, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Divider, IconButton, useTheme } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#614455',
  border: '1px dashed #614455',
  borderRadius: '4px',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  position: 'relative' as 'relative',
  cursor: 'pointer',
  padding: '20px',
  borderWidth: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
};

const acceptStyle = {
  borderColor: '#4F93E2',
};

const rejectStyle = {
  borderColor: '#D1104A',
};

interface Props {
  disabled?: boolean;
  style?: CSSProperties;
  onChange(files: File[]): void;
}

export const UploadDropzone: FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const handleSetCurrentFiles = (files: File[]) => {
    setCurrentFiles(files);
    props.onChange(files);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isFocused, isDragReject } = useDropzone({
    accept: ['.pdf'],
    onDropAccepted: (newFiles) => handleSetCurrentFiles([...currentFiles, ...newFiles]),
    disabled: props.disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? { borderColor: theme.palette.secondary.main } : {}),
      ...(isDragActive ? { borderColor: theme.palette.secondary.main } : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, theme.palette.secondary.main, isDragActive, isDragAccept, isDragReject]
  );

  return (
    <div style={props.style}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={{ textAlign: 'center', fontSize: '14px' }}>{t('uploadDropzone.text')}</p>
      </div>
      <div>
        {currentFiles.map((file, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <Divider />}
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'space-between',
                maxWidth: '100%',
              }}
            >
              <div>{file.name}</div>
              <IconButton
                disabled={props.disabled}
                onClick={() => {
                  handleSetCurrentFiles(currentFiles.filter((cf, i) => i !== index));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
