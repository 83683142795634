import { Documentation } from '../../../models/documentation/Documentation';
import { ActionType } from './action-types/action-types';
import ActionEnum from './actions-enum';
import {
  AddCommentToDocument,
  AddDocumentToAgreement,
  ResolvedComment,
  UpdateCurrentComment,
  addAgreementAttribute,
  addAgreementAttributeToAttachment,
  copyRefreshAgreementAttributes,
  updateAgreementAttribute,
  updateSingleDocumentAttribute,
  updateSinglePDFDocumentAttribute,
} from './helpers';
import { DocumentationStatus } from 'models/documentation/Documentation';
import { AgreementState } from 'pages/DraftingAgreement/redux/models/agreement.state';

const defaultState: AgreementState = {
  currentAgreement: {
    attributes: [],
    documents: [],
    status: DocumentationStatus.InDraft,
    tenantId: '',
    id: '',
    attachments: [],
    comments: [],
    lastUpdate: new Date().toLocaleString(),
    creatorMembershipId: '',
    mergedAttributes: [],
    name: '',
    fullName: '',
    activeFlowId: null,
    withdrawCount: null,
    lastDocumentationId: '',
    lastDocumentStatus: DocumentationStatus.InDraft,
  } as Documentation,
  currentDocumentIndex: 0,
  currentCommentId: '',
  externalUsers: [],
  currentPdfIndex: 0,
};

const currentAgreement = (state = defaultState, action: ActionType): AgreementState => {
  switch (action.type) {
    case ActionEnum.SYNC_AGREEMENT: {
      return { ...state, currentAgreement: action.payload };
    }

    case ActionEnum.UPDATE_CURRENT_DOCUMENT_INDEX: {
      return { ...state, currentDocumentIndex: action.payload };
    }

    case ActionEnum.UPDATE_CURRENT_PDF_INDEX: {
      return { ...state, currentPdfIndex: action.payload };
    }

    case ActionEnum.UPDATE_CURRENT_DOCUMENT_CONTENT: {
      if (state.currentDocumentIndex === undefined) {
        throw Error('current document index is not specified');
      }
      const updatedCurrentAgreement = { ...state.currentAgreement };

      updatedCurrentAgreement.documents = [...state.currentAgreement.documents];
      const newDocument = { ...updatedCurrentAgreement.documents[state.currentDocumentIndex] };
      newDocument.content = action.payload;
      updatedCurrentAgreement.documents[state.currentDocumentIndex] = newDocument;

      return { ...state, currentAgreement: updatedCurrentAgreement };
    }

    case ActionEnum.UPDATE_ATTRIBUTE_VALUE: {
      const newAgreement = updateAgreementAttribute(
        action.payload.attrId,
        action.payload.attrValue,
        state.currentAgreement
      );
      return { ...state, currentAgreement: newAgreement };
    }

    case ActionEnum.UPDATE_ATTRIBUTE_VALUE_FOR_DOC: {
      const newAgreement = updateSingleDocumentAttribute(
        action.payload.attrId,
        action.payload.attrValue,
        state.currentAgreement,
        action.payload.documentIndex
      );
      return { ...state, currentAgreement: newAgreement };
    }

    case ActionEnum.UPDATE_ATTRIBUTE_VALUE_FOR_PDF_DOC: {
      const newAgreement = updateSinglePDFDocumentAttribute(
        action.payload.attrId,
        action.payload.attrValue,
        state.currentAgreement,
        action.payload.pdfIndex
      );
      return { ...state, currentAgreement: newAgreement };
    }

    case ActionEnum.UPDATE_DOC_ATTRS: {
      const updatedCurrentAgreement = { ...state.currentAgreement };
      if (state.currentDocumentIndex === undefined) {
        throw Error('current document index is not specified');
      }

      updatedCurrentAgreement.documents = [...state.currentAgreement.documents];
      const newDocument = { ...updatedCurrentAgreement.documents[state.currentDocumentIndex] };
      newDocument.attributes = action.payload;
      updatedCurrentAgreement.documents[state.currentDocumentIndex] = newDocument;

      return { ...state, currentAgreement: updatedCurrentAgreement };
    }

    case ActionEnum.UPDATE_ATTACHMENT_ATTRS: {
      const updatedCurrentAgreement = { ...state.currentAgreement };
      if (state.currentPdfIndex === undefined) {
        throw Error('current pdf index is not specified');
      }

      updatedCurrentAgreement.attachments = [...state.currentAgreement.attachments];
      const newAttachment = { ...updatedCurrentAgreement.attachments[state.currentPdfIndex] };
      newAttachment.attributes = action.payload;
      updatedCurrentAgreement.attachments[state.currentPdfIndex] = newAttachment;

      return { ...state, currentAgreement: updatedCurrentAgreement };
    }

    case ActionEnum.REFRESH_AGREEMENT_ATTRIBUTES: {
      const updatedCurrentAgreement = copyRefreshAgreementAttributes(state.currentAgreement);
      return { ...state, currentAgreement: updatedCurrentAgreement };
    }

    case ActionEnum.ADD_ATTRIBUTE_TO_DOC: {
      const newAgreement = addAgreementAttribute(state.currentAgreement, action.payload, state.currentDocumentIndex);
      return { ...state, currentAgreement: newAgreement };
    }

    case ActionEnum.ADD_ATTRIBUTE_TO_ATTACHMENT: {
      const newAgreement = addAgreementAttributeToAttachment(
        state.currentAgreement,
        action.payload,
        state.currentPdfIndex
      );
      return { ...state, currentAgreement: newAgreement };
    }

    case ActionEnum.ADD_DOCUMENT_TO_AGREEMENT: {
      const newAgreement = AddDocumentToAgreement(state.currentAgreement, action.payload);
      return { ...state, currentAgreement: newAgreement };
    }
    case ActionEnum.ADD_COMMENT_TO_DOCUMENT: {
      const newAgreement = AddCommentToDocument(state.currentAgreement, action.payload, state.currentDocumentIndex);
      return { ...state, currentAgreement: newAgreement };
    }
    case ActionEnum.UPDATE_CURRENT_COMMENT: {
      const newAgreement = UpdateCurrentComment(
        state.currentAgreement,
        action.payload.comment,
        state.currentDocumentIndex,
        action.payload.index
      );
      return { ...state, currentAgreement: newAgreement };
    }
    case ActionEnum.RESOLVED_COMMENT: {
      const newAgreement = ResolvedComment(state.currentAgreement, state.currentDocumentIndex, action.payload);
      return { ...state, currentAgreement: newAgreement };
    }

    case ActionEnum.SET_CURRENT_COMMENT_ID: {
      return { ...state, currentCommentId: action.commentId };
    }

    case ActionEnum.UPDATE_COMMENTS_TO_AGREEMENT: {
      const agreement = { ...state.currentAgreement };
      agreement.comments = action.payload;

      return { ...state, currentAgreement: agreement };
    }

    case ActionEnum.UPDATE_CURRENT_DOCUMENT_NAME: {
      const updatedCurrentAgreement = { ...state.currentAgreement };
      if (state.currentDocumentIndex === undefined) {
        throw Error('current document index is not specified');
      }

      updatedCurrentAgreement.documents = [...state.currentAgreement.documents];
      const newDocument = { ...updatedCurrentAgreement.documents[state.currentDocumentIndex] };
      newDocument.name = action.payload;
      updatedCurrentAgreement.documents[state.currentDocumentIndex] = newDocument;

      return { ...state, currentAgreement: updatedCurrentAgreement };
    }

    case ActionEnum.UPDATE_CURRENT_ATTACHMENT_NAME: {
      const updatedCurrentAgreement = { ...state.currentAgreement };
      if (state.currentPdfIndex === undefined) {
        throw Error('current pdf index is not specified');
      }

      updatedCurrentAgreement.documents = [...state.currentAgreement.documents];
      const newAttachment = { ...updatedCurrentAgreement.attachments[state.currentPdfIndex] };
      newAttachment.name = action.payload;
      updatedCurrentAgreement.attachments[state.currentPdfIndex] = newAttachment;

      return { ...state, currentAgreement: updatedCurrentAgreement };
    }

    case ActionEnum.SYNC_EXTERNAL_USERS_LIST:
      return { ...state, externalUsers: action.payload };

    default:
      return state;
  }
};

export default currentAgreement;
