export enum BatchDialogActionEnum {
  OPEN_BATCH_DIALOG = 'OPEN_BATCH_DIALOG',
  CLOSE_BATCH_DIALOG = 'CLOSE_BATCH_DIALOG',
  ALLOW_OPEN_DIALOG = 'ALLOW_OPEN_DIALOG',
  PROHIBIT_OPEN_DIALOG = 'PROHIBIT_OPEN_DIALOG',
}

export interface OpenBatchDialog {
  type: BatchDialogActionEnum.OPEN_BATCH_DIALOG;
}

export interface CloseBatchDialog {
  type: BatchDialogActionEnum.CLOSE_BATCH_DIALOG;
}

export interface AllowOpenDialog {
  type: BatchDialogActionEnum.ALLOW_OPEN_DIALOG;
}

export interface ProhibitOpenDialog {
  type: BatchDialogActionEnum.PROHIBIT_OPEN_DIALOG;
}

export type BatchDialogActionType = CloseBatchDialog | OpenBatchDialog | AllowOpenDialog | ProhibitOpenDialog;
