import React, { useEffect, useState } from 'react';

import { Backdrop, Fade, Modal } from '@material-ui/core';

import { postFile } from 'api-handlers/convert-file-handler';
import { ReactComponent as Check } from 'images/check.svg';
import { ReactComponent as Export } from 'images/export.svg';
import { DocCategory } from 'models/DocCategory';
import StyledButton from 'shared/ui/StyledButton/StyledButton';
import StyledInput from 'shared/ui/StyledInput/StyledInput';
import StyledSelect from 'shared/ui/StyledSelect/StyledSelect';

interface ModalToUploadProps {
  open?: boolean;
  setModalOpen: (info: boolean) => void;
  syncTemplates: () => void;
}

const ModalToUpload = (props: ModalToUploadProps): JSX.Element => {
  const [open, setOpen] = useState(true);
  const [nameOfTemplate, setNameOfTemplate] = useState('');
  const [categoryOfTemplate, setCategoryOfTemplate] = useState<string>(DocCategory.Amendment);
  const [file, setFile] = useState<any>(); //TODO - any

  useEffect(() => {
    // tslint:disable-next-line: no-unused-expression
    props.open && setOpen(true);
  }, [props.open]);

  const handleGetFile = (event: any): void => {
    //TODO - any
    setNameOfTemplate(event.target.files[0].name);
    setFile(event.target.files[0]);
  };

  const handleSendFile = async (): Promise<void> => {
    if (file && categoryOfTemplate && nameOfTemplate) {
      const formData = new FormData();
      formData.append('category', categoryOfTemplate);
      formData.append('name', nameOfTemplate);
      formData.append('pdf', file);
      await postFile(formData);
      await props.syncTemplates();
    } else {
      alert('Nothing to send!');
    }
  };

  const handleClose = (): void => {
    props.setModalOpen(false);
    setOpen(false);
  };

  const handleCloseWithSendFile = (): void => {
    props.setModalOpen(false);
    setOpen(false);
    handleSendFile();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="modal"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modalToUpload__container">
          <div className="modalToUpload__title">Upload file: </div>
          <form className="modalToUpload__form">
            <label htmlFor="file" className="modalToUpload__label button--primary">
              <Export />
              <span className="button--primary__text">Choose a file</span>
            </label>
            <input
              className="modalToUpload__input"
              type="file"
              id="file"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => handleGetFile(event)}
              accept="application/pdf"
            />
            <StyledInput
              size="large"
              label="Template name"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setNameOfTemplate(event.target.value)}
              value={nameOfTemplate}
            />
            <StyledSelect
              label="Template type"
              size="large"
              options={[
                DocCategory.Amendment,
                DocCategory.Annex,
                DocCategory.HandoverProtocol,
                DocCategory.IndexationLetter,
                DocCategory.Contract,
                DocCategory.Other,
                DocCategory.Schedule,
                DocCategory.ServiceChargeSettlement,
              ]}
              setSelectValue={setCategoryOfTemplate}
            />
            <div className="modalToUpload__buttonsContainer">
              <StyledButton type="primary" icon={<Check />} text="OK" onClick={handleCloseWithSendFile} />
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalToUpload;
