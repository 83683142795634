interface PayloadItem {
    count: number;
    priceSum: number;
    seq: number;
}

export interface TransformedData {
    labels: string[];
    datasets: Array<any>
}

export const transformPayload = (payload: any): TransformedData => {
    const autentiCount = payload.autentiValues.map((item: PayloadItem) => item.count);
    const autentiPriceSum = payload.autentiValues.map((item: PayloadItem) => item.priceSum);
    const signiusCount = payload.signiusValues.map((item: PayloadItem) => item.count);
    const signiusPriceSum = payload.signiusValues.map((item: PayloadItem) => item.priceSum);
    const backgroundColors = ['rgb(75, 192, 192)', 'rgb(255, 99, 132)'];

    const transformedData: TransformedData = {
        labels: payload.labels,
        datasets: [
            {
                label: 'Autenti',
                data: autentiCount,
                backgroundColor: backgroundColors[0],
                priceSum: autentiPriceSum,
            },
            {
                label: 'Signius',
                data: signiusCount,
                backgroundColor: backgroundColors[1],
                priceSum: signiusPriceSum,
            },

        ],
    };

    return transformedData;
};