import { UserType } from '../../models/Enums';
import { ActionType } from '../action-types/action-types';
import { ActionEnum } from '../models/action.enums';
import { User } from 'redux/models/user.state';

const defaultUser = null;

const user = (state: User | null = defaultUser, action: ActionType): User | null => {
  switch (action.type) {
    case ActionEnum.ADD_USER_DATA_AFTER_LOGIN:
      if (!action.payload.upn) {
        action.payload.upn = action.payload.email;
      }
      action.payload.role = action.payload.upn === 'admin@pactt.co' ? UserType.Owner : UserType.Member;
      action.payload.isAuthorized = true;
      return action.payload;

    case ActionEnum.CLEAR_USER_DATA_AFTER_LOGOUT:
      return null;

    case ActionEnum.SYNC_USER_DATA:
      if (state !== null) {
        return {
          ...state,
          isAuthorized: action.payload.isAuthorized,
        };
      }
      return state;
    default:
      return state;
  }
};

export default user;
