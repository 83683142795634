import React, { FC, useMemo } from 'react';
import { Action, useQuery } from 'react-fetching-library';

import { InvitationCard } from '../components/InvitationCard';
import { UsersTable } from '../components/UsersTable';
import { OrganizationUser } from '../model';
import { useOrganization } from 'contexts/organization';

const OrganizationUsers: FC = () => {
  const { organizationId, membershipId } = useOrganization();
  const getUsers: Action = useMemo(
    () => ({
      endpoint: `/organization/${organizationId}/organizationMembership/${membershipId}/users`,
      method: 'GET',
      credentials: 'include',
    }),
    [membershipId, organizationId]
  );

  const { payload, query } = useQuery<OrganizationUser[]>(getUsers);

  return (
    <>
      <InvitationCard onSuccess={query} />

      <UsersTable membershipId={membershipId} organizationId={organizationId} data={payload || []} reload={query} />
    </>
  );
};
export default OrganizationUsers;
