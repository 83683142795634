import { DocumentAttribute } from './sub-models/attribute/Attribute';
import { Comment } from './sub-models/comment/comment.model';
import { DocumentationDocument } from './sub-models/document/DocumentationDocument';
import { Attachment } from 'models/Attachment';
import { DocumentationRole } from 'user/permissions/model';

export interface DocumentComment {
  id: string;
  authorId: string;
  createdAt: number;
  content: string;
}

export interface DocumentCommentThread {
  id: string;
  author: string;
  comments: DocumentComment[];
}
export interface Documentation {
  name: string;
  fullName: string;
  creatorMembershipId: string;
  rootDocumentationId?: string;
  parentDocumentationId?: string;
  subDocumentationId?: string;
  attributes: DocumentAttribute[];
  mergedAttributes: DocumentAttribute[];
  documents: DocumentationDocument[];
  status: DocumentationStatus;
  id: string;
  attachments: Attachment[];
  comments: Comment[];
  lastUpdate: string;
  esignDocumentIds?: string;
  membershipRoles?: {
    [name: string]: DocumentationRole;
  };
  activeFlowId: string | null;
  withdrawCount: number | null;
  lastDocumentationId: string;
  lastDocumentStatus: DocumentationStatus;
}
export interface DocumentationListView
  extends Pick<Documentation, 'mergedAttributes' | 'id' | 'lastUpdate' | 'status'> {
  name: string;
  fullName: string;
  createdAt: string;
  lastUpdateAt: Date;
  creatorMembershipId: string;
  docsCount: number;
  mergedAttributes: DocumentAttribute[];
  activeFlowId?: string;
  amendedByNames: Array<string>;
  amendedDated: Array<string>;
  withdrawCount: number | null;
}

export interface DocumentationListResponse {
  content: DocumentationListView[];
  number: number;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export enum DocumentationStatus {
  InDraft = 'draft',
  Pending = 'pending',
  Signed = 'signed',
  Failed = 'failed',
  Error = 'error',
  SentToTenant = 'sent',
  InNegotiation = 'negotiating',
  TenantSuggestedChanges = 'tenant suggested changes',
  LandlordSuggestedChanges = 'landlord suggested changes',
  AwaitsTenantsSignature = 'awaits tenants signiture',
  InForce = 'in force',
  SignedByTenant = 'signed by tenant',
  Archived = 'archived',
  Active = 'active',
  Renegotiating = 'renegotiating',
  Terminated = 'terminated',
  Preliminary = 'preliminary',
  Expired = 'expired',
}
