import React, { FC } from 'react';

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { DeleteAction } from '../DeleteButton/DeleteAction';
import { DocumentStyleFont } from 'models/DocumentStyleFont';

interface DocumentStyleFontTableProps {
  data: DocumentStyleFont[];
  reload: () => void;
  onEdit: (id: string) => void;
}
export const DocumentStyleFontTable: FC<DocumentStyleFontTableProps> = ({ data, reload, onEdit }) => {
  const body = data.map((row) => (
    <TableRow key={row.id}>
      <TableCell component="td" scope="row">
        {row.id}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.fontFamily}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.weight}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.style}
      </TableCell>
      <TableCell>
        <IconButton onClick={() => onEdit(row.id)}>
          <Edit />
        </IconButton>
        <DeleteAction title="Are you sure?" onComplete={reload} endpoint={`/font/${row.id}`} />
      </TableCell>
    </TableRow>
  ));
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th">Id</TableCell>
          <TableCell component="th">Font family</TableCell>
          <TableCell component="th">Font weight</TableCell>
          <TableCell component="th">Font style</TableCell>
          <TableCell component="th">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{body}</TableBody>
    </Table>
  );
};
