import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent, CardHeader, Tooltip } from '@material-ui/core';

import { useOrganization } from 'contexts/organization';
import { UserType } from 'models/Enums';
import Invitation from 'shared/components/Invitation/Invitation';
import { getUserTypeTK } from 'shared/helpers/translationKeys';
import { OrganizationPermission } from 'user/permissions/model';

interface InvitationCardProps {
  onSuccess(): void;
}

const canInviteUserPermissionMap: Record<UserType, OrganizationPermission> = {
  [UserType.Creator]: OrganizationPermission.INVITE_CREATOR,
  [UserType.Member]: OrganizationPermission.INVITE_MEMBER,
  [UserType.Owner]: OrganizationPermission.INVITE_OWNER,
};

export const InvitationCard: FC<InvitationCardProps> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { organizationId, membershipId, hasPermission } = useOrganization();

  const buttons = useMemo(() => {
    const baseUrl = `/organization/${organizationId}/${membershipId}/invite/user`;
    return (Object.entries(canInviteUserPermissionMap) as [UserType, OrganizationPermission][]).flatMap(
      ([type, perm]) => {
        if (!hasPermission(perm)) {
          return [];
        }
        return [
          <Box mr={2} key={type}>
            <Invitation
              endpoint={`${baseUrl}/${type}`}
              title={t('invite_user.invite_button', 'Invite {{userType}}', { userType: t(getUserTypeTK(type)) })}
              onSuccess={onSuccess}
              tooltipBody={type === 'member'
                ? t('invite_user.invite_button_member.tooltip', 'Invite {{userType}}')
                : type === 'creator'
                ? t('invite_user.invite_button_creator.tooltip', 'Invite {{userType}}')
                : 'Invite user'}
            />
          </Box>,
        ];
      }
    );
  }, [hasPermission, membershipId, onSuccess, organizationId, t]);

  return (
    <Card>
      <CardHeader title={t('invite_user.card_header', 'Invite to organization')}></CardHeader>
      <CardContent>
        <Box display="inline-flex">{buttons}</Box>
      </CardContent>
    </Card>
  );
};
