import React, { FC } from 'react';
import { Action, useMutation } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { Avatar, Chip } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';

import { useNotifications } from '../../contexts/notifications';
import { fontSizeNormal, fontSizeSmall, grey } from '../../styles';
import { NotificationDate } from './NotificationDate';
import { InvitationNotification, NotificationStatus } from './model';

interface Props {
  notification: InvitationNotification;
}

// todo build FE message dynamically using additionalParameters, do not use `notification.message`
export const InvitationNotificationItem: FC<Props> = ({ notification }) => {
  const { t } = useTranslation();
  const { reloadNotifications } = useNotifications();

  const { mutate: accept } = useMutation(
    (id: string): Action => ({
      endpoint: `/organization/invitation/${id}/accept`,
      method: 'POST',
      credentials: 'include',
    })
  );
  const { mutate: ignore } = useMutation(
    (id: string): Action => ({
      endpoint: `/organization/invitation/${id}/reject`,
      method: 'POST',
      credentials: 'include',
    })
  );

  const acceptInvitation = (id: string) => {
    accept(id).then(() => reloadNotifications());
  };
  const ignoreInvitation = (id: string) => {
    ignore(id).then(() => reloadNotifications());
  };

  const notificationWasRed = notification.status === NotificationStatus.READ;

  return (
    <div
      style={{
        display: 'flex',
        padding: '10px 20px',
        gap: '10px',
        backgroundColor: notificationWasRed ? 'inherit' : '#fef6f0',
      }}
    >
      <Avatar variant="rounded" style={{ backgroundColor: '#bfaacc' }}>
        <EventOutlinedIcon style={{ fill: '#614455' }} />
      </Avatar>
      <div style={{ fontSize: fontSizeNormal }}>
        <div>{notification.message}</div>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', fontSize: fontSizeSmall, color: grey }}
        >
          <NotificationDate date={notification.createdAt} />
        </div>
        <div style={{ display: 'flex', gap: '5px' }}>
          <Chip
            icon={<Check />}
            label={t('notifications.acceptInvitation')}
            size="small"
            component="div"
            color="primary"
            disabled={notificationWasRed}
            onClick={() => acceptInvitation(notification.additionalParameters.TOKEN_ID)}
          />
          <Chip
            icon={<Clear />}
            label={t('notifications.rejectInvitation')}
            variant="outlined"
            size="small"
            component="div"
            color="secondary"
            disabled={notificationWasRed}
            onClick={() => ignoreInvitation(notification.additionalParameters.TOKEN_ID)}
          />
        </div>
      </div>
    </div>
  );
};
