// @ts-nocheck
import Command from '@ckeditor/ckeditor5-core/src/command';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';

import { customComponentClassRegexp } from './config';

export const createClassPlugin = (pluginName, { label, options, priority, forceValue }) =>
  class extends Plugin {
    static get pluginName() {
      return pluginName;
    }

    static get requires() {
      const command = createCommand();
      return [
        command,
        createEditing({ command, pluginName, options, priority }),
        createUi({ pluginName, label, options, forceValue }),
      ];
    }
  };

const createUi = ({ label, options, pluginName, forceValue }) =>
  class extends Plugin {
    init() {
      const editor = this.editor;

      editor.ui.componentFactory.add(pluginName, (locale) => {
        const dropdownView = createDropdown(locale);

        const items = new Collection();

        dropdownView.buttonView.set({
          label: label,
          tooltip: true,
          withText: true,
        });

        const command = editor.commands.get(pluginName);

        dropdownView.bind('isEnabled').to(command, 'isEnabled');

        options.forEach((v) => {
          items.add(prepareDefinition(v));
        });

        addListToDropdown(dropdownView, items);

        this.listenTo(dropdownView, 'execute', (evt) => {
          editor.execute(pluginName, { value: evt.source.commandParam, forceValue });
          editor.editing.view.focus();
        });

        return dropdownView;
      });
    }
  };

function prepareDefinition(v) {
  return {
    type: 'button',
    model: new Model({
      commandParam: v.className,
      label: v.name,
      withText: true,
    }),
  };
}

const createEditing = ({ command, pluginName, options, priority }) =>
  class extends Plugin {
    init() {
      const editor = this.editor;

      editor.model.schema.extend('$text', { allowAttributes: pluginName });
      editor.model.schema.extend('paragraph', { allowAttributes: pluginName });
      editor.model.schema.setAttributeProperties(pluginName, {
        isFormatting: true,
        // copyOnEnter: true,
      });
      editor.conversion.for('upcast').elementToAttribute(
        {
          view: {
            name: 'span',
            classes: new RegExp(customComponentClassRegexp),
          },

          model: {
            name: '$text',
            key: pluginName,
            value: (viewElement) => viewElement.getAttribute('class'),
          },
        },
        {
          priority: 100,
        }
      );

      this.editor.conversion.for('downcast').attributeToElement({
        model: pluginName,
        view: (modelAttributeValue, { writer: viewWriter }) => {
          return viewWriter.createAttributeElement(
            'span',
            {
              class: modelAttributeValue,
            },
            {
              priority: 7,
            }
          );
        },
      });

      editor.commands.add(pluginName, new command(editor, pluginName));
    }
  };

const createCommand = () =>
  class extends Command {
    constructor(editor, attributeKey) {
      super(editor);
      this.attributeKey = attributeKey;
    }

    /**
     * @inheritDoc
     */
    refresh() {
      const model = this.editor.model;
      const doc = model.document;

      this.value = this._getValueFromFirstAllowedNode();

      this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, this.attributeKey);
    }

    execute({ value, forceValue }) {
      const model = this.editor.model;
      const doc = model.document;
      const selection = doc.selection;

      const selected = forceValue === undefined ? value !== this.value : forceValue;

      model.change((writer) => {
        if (selection.isCollapsed) {
          if (selected) {
            writer.setSelectionAttribute(this.attributeKey, value);
          } else {
            writer.removeSelectionAttribute(this.attributeKey);
          }
        } else {
          const ranges = model.schema.getValidRanges(selection.getRanges(), this.attributeKey);

          for (const range of ranges) {
            if (selected) {
              writer.setAttribute(this.attributeKey, value, range);
            } else {
              writer.removeAttribute(this.attributeKey, range);
            }
          }
        }
      });
    }

    _getValueFromFirstAllowedNode() {
      const model = this.editor.model;
      const schema = model.schema;
      const selection = model.document.selection;

      if (selection.isCollapsed) {
        return selection.getAttribute(this.attributeKey);
      }

      for (const range of selection.getRanges()) {
        for (const item of range.getItems()) {
          if (schema.checkAttribute(item, this.attributeKey)) {
            return item.getAttribute(this.attributeKey);
          }
        }
      }

      return false;
    }
  };
