import { combineReducers } from 'redux';

import { RootState } from './models/root.state';
import settings from './reducers/settingsReducer';
import userData from './reducers/userDataReducer';
import user from './reducers/userReducer';
import agreement from 'pages/DraftingAgreement/redux/agreementReducer';
import batchDialogFormFlow from 'shared/components/DocumentationFlow/redux/flow.reducer';

const rootReducer = combineReducers<RootState>({
  user,
  settings,
  userData,
  agreement,
  batchDialogFormFlow,
});

export default rootReducer;
