import { API_BASE_URL } from 'api/config';
import { DocumentStyle } from 'models/DocumentStyle';
import { DocumentStyleFont } from 'models/DocumentStyleFont';

export const defaultStyle: DocumentStyle = {
  organizationId: '',
  name: 'default',
  css: '',
  margin: {
    top: '20mm',
    bottom: '20mm',
    left: '12mm',
    right: '12mm',
  },
  options: {
    color: [],
    fontFamily: [],
    fontSize: [],
    customComponents: [],
  },
  customFonts: [],
  id: 'n/a',
};

export const customComponentClassPrefix = 'pactt-custom-component-';

export const getDocumentStyleCss = (style: DocumentStyle) => {
  return [
    style.css,
    style.options.customComponents
      .map((e) => `.ck-content .${customComponentClassPrefix}${e.label} {${e.value} }`)
      .join(' '),
  ]
    .filter(Boolean)
    .join(' ');
};

export const prepareCustomFonts = (payload?: DocumentStyleFont[]) =>
  (payload || []).map((f) => ({
    ...f,
    fileLinks: f.fileLinks.map((l) => `${API_BASE_URL}/fonts/file?link=${l.split('/').slice(-1)}`),
  }));
