import React, { FC } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import styles from '../styles.module.sass';
import { DocumentationTemplate } from 'models/DocumentationTemplate';
import { useOrganizationMembershipUrl } from 'pages/TemplateManagement/hooks/api-helper';

interface DocumentationCreateFromTemplateProps {
  onConfirm(id: string): void;
}
export const DocumentationCreateFromTemplate: FC<DocumentationCreateFromTemplateProps> = ({ onConfirm }) => {
  const organizationUrl = useOrganizationMembershipUrl();

  const { payload } = useSuspenseQuery<DocumentationTemplate[]>({
    endpoint: `${organizationUrl}/agreement-template`,
    credentials: 'include',
    method: 'GET',
  });

  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={4}>
        {payload!.map((template) => (
          <Grid item key={template.id}>
            <div className={styles.papers}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onConfirm(template.id);
                }}
              >
                {t('documentation_create_from_template.use_template', 'Use')}
              </Button>
            </div>
            <Box my={2}>
              <Typography>{template.name}</Typography>
              <Typography variant="button">
                {t('documentation_create_from_template.template_label', 'Id: {{templateId}}', {
                  templateId: template.id,
                })}
              </Typography>
            </Box>
            <Box my={2}></Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DocumentationCreateFromTemplate;
