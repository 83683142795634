import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core';

import { ReactComponent as Upload } from 'images/export.svg';
import { DocumentListItemModel } from 'pages/TemplateManagement/model';
import StyledButton from 'shared/ui/StyledButton/StyledButton';

interface ItemProps {
  type: string;
  title: string;
  selected?: boolean;
  onClick?: () => void;
  showCategoryName?: boolean;
  template?: DocumentListItemModel;
  setUploadTemplateModalOpen?: (info: boolean) => void;
  setUploadFileModalOpen?: (info: boolean) => void;
  showUploadAttachment?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));
const Item: FC<ItemProps> = (props): JSX.Element => {
  const styles = useStyles();
  const handleClick = (): void => {
    props.onClick && props.onClick();
  };

  return (
    <div className={styles.root}>
      {props.children}
      <div
        className={`item ${props.selected ? 'selected' : 'unselected'}`}
        onClick={(): void => {
          handleClick();
        }}
      >
        <div className={`item__${props.type}Doc`}>
          {props.type === 'new' && props.setUploadTemplateModalOpen && (
            <StyledButton
              type="primary"
              icon={<Upload />}
              text="Upload template"
              onClick={props.setUploadTemplateModalOpen}
            />
          )}
          {props.type === 'new' && props.setUploadFileModalOpen && props.showUploadAttachment && (
            <StyledButton
              type="primary"
              icon={<Upload />}
              text="Upload attachment"
              onClick={props.setUploadFileModalOpen}
            />
          )}
        </div>
        <div className="item__title">{props.title}</div>
      </div>
    </div>
  );
};

export default Item;
