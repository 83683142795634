import CKEditorComments from '@ckeditor/ckeditor5-comments/src/comments';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { CommentsCommand } from './CommentsCommand';
import { CommentUI } from './CommentsUI';

export class Comments extends Plugin {
  static get requires() {
    return [CKEditorComments, CommentsCommand, CommentUI];
  }
  init() {
    const { editor } = this;

    const usersPlugin = editor.plugins.get('Users');
    const commentsRepository = editor.plugins.get('CommentsRepository');
    const config = this.editor.config.get('commentsAdapter');

    usersPlugin.users.clear();
    for (const user of config.users) {
      usersPlugin.addUser(user);
    }
    usersPlugin.defineMe(config.currentUserId);
    commentsRepository.adapter = config.adapter;

    editor.model.markers.on('update:comment', (_evt, marker, _oldRange, newRange) => {
      if (newRange) {
        return;
      }
      try {
        const threadId = marker.name.split(':')[1];

        config.adapter.removeCommentThread({ threadId });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Could not remove comment thread [%s] %s ', marker.name, e.message);
      }
    });
  }
}
