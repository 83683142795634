import keyBy from 'lodash/keyBy';
import lowerCase from 'lodash/lowerCase';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, ListSubheader, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { groupAttributes } from 'helpers/leftPanelHelpers';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { defaultAttributes } from 'shared/attributes/defaultAttributes';
import { AttributeDialog } from 'shared/components/CreateAttribute/AttributeDialog';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { useAttributeTranslation } from 'shared/hooks/useAttributeTranslation';

interface Props {
  attributes: DocumentAttribute[];
  defaultAttributes: DocumentAttribute[];
  handleAddAttributes: (attribute: DocumentAttribute[]) => void;
}

const AddAttributeSection = (props: Props): JSX.Element => {
  const [showCreateAttr, setShowCreateAttr] = useState(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { translateAttributeCategory, translateAttributeName } = useAttributeTranslation();
  const triggerCreateANText = t('add_attribute_section.create_new_option', 'Create new attribute...');
  const allDefaultAttributes = useMemo(() => {
    return props.defaultAttributes.concat(defaultAttributes);
  }, [props.defaultAttributes]);
  const onChange = (selectedOption: string) => {
    if (selectedOption === triggerCreateANText) {
      setShowCreateAttr(true);
      return;
    }

    const attrToAdd = allDefaultAttributes.find(
      (el: DocumentAttribute) => getDocumentAttributeId(el) === selectedOption
    );
    if (attrToAdd) {
      props.handleAddAttributes?.([attrToAdd]);
    }
  };

  const indexedAttributes = useMemo(
    () => keyBy(props.attributes, (attr) => lowerCase(getDocumentAttributeId(attr))),
    [props.attributes]
  );

  const isValid = (id: string) => !indexedAttributes[lowerCase(id)];

  const attrsToAdd: Record<string, DocumentAttribute[]> = useMemo(() => {
    const currentAttrsIds = props.attributes.map(getDocumentAttributeId);

    const toAdd = allDefaultAttributes.filter((el: any) => !currentAttrsIds.includes(getDocumentAttributeId(el)));
    return groupAttributes(toAdd);
  }, [props.attributes, allDefaultAttributes]);

  return (
    <div>
      <TextField
        select
        fullWidth
        variant="outlined"
        onChange={(e) => {
          onChange(e.target.value as string);
        }}
        value=""
        label={t('add_attribute_section.add_attribute_label', 'Add attribute')}
      >
        <MenuItem value={triggerCreateANText}>{triggerCreateANText}</MenuItem>
        {Object.entries(attrsToAdd).map(([category, attrs]) => {
          const cat = (
            <ListSubheader>
              <Tooltip title={t<string>('add_attribute_section.add_whole_category.tooltip', 'Add all')}>
                <Box display="flex" key={category} justifyContent="space-between">
                  {translateAttributeCategory(category)}
                  <IconButton onClick={() => props.handleAddAttributes(attrsToAdd[category])}>
                    <Add />
                  </IconButton>
                </Box>
              </Tooltip>
            </ListSubheader>
          );
          const menuItems = attrs.map((el: DocumentAttribute) => {
            const v = getDocumentAttributeId(el);
            return (
              <MenuItem key={v} value={v}>
                {translateAttributeName(el)}
              </MenuItem>
            );
          });
          return [cat].concat(menuItems);
        })}
      </TextField>

      {showCreateAttr && (
        <AttributeDialog
          title={t('add_attribute_section.create_attribute_dialog.title', 'Create attribute')}
          onCancel={() => setShowCreateAttr(false)}
          onSubmit={(attr) => {
            const id = getDocumentAttributeId(attr);
            if (isValid(id)) {
              setShowCreateAttr(false);
              props.handleAddAttributes([attr]);
            } else {
              snackbar.enqueueSnackbar(
                t(
                  'add_attribute_section.duplicate_attribute_error',
                  'Attribute with given name and category already exists.'
                ),
                {
                  variant: 'error',
                }
              );
            }
          }}
          confirmText={t('add_attribute_section.create_attribute_dialog.confirm_text', 'Add')}
          cancelText={t('add_attribute_section.create_attribute_dialog.cancel_text', 'Close')}
        ></AttributeDialog>
      )}
    </div>
  );
};

export default AddAttributeSection;
