/* eslint-disable react/jsx-no-undef */
import React, { FC, useEffect, useMemo } from 'react';
import { Action, useQuery, useSuspenseQuery } from 'react-fetching-library';
import { useRouteMatch } from 'react-router';

import { Box, Paper, TableContainer, Typography } from '@material-ui/core';

import { DemoParameters } from '../components/DemoParameters';
import { DocumentStyleList } from 'shared/components/DocumentStyle/DocumentStyleList';
import { DocumentStyleFontList } from 'shared/components/DocumentStyleFont/DocumentStyleFontList';
import Invitation from 'shared/components/Invitation/Invitation';
import { TokenComponent } from '../components/TokenComponent';
import { SigningProvider } from 'config/signing-provider';
import { BillingsComponent } from '../components/Billings/BillingsComponent';

export interface AutentiConfiguration {
  id: string;
  token: string;
  url: string;
  clientId: string;
  clientSecret: string;
  organizationId: string;
  isEnabled: boolean;
  isOrganizationCustom: boolean;
}

export interface SigniusConfiguration {
  id: string;
  organizationId: string;
  token: string;
  isEnabled: boolean;
  isOrganizationCustom: boolean;
}

export interface OrganizationConfiguration {
  autentiConfiguration: AutentiConfiguration;
  signiusConfiguration: SigniusConfiguration;
  enabledSigningProviders: Array<SigningProvider>;
  demo: boolean;
  expirationDate: null | string;
  id: string;
  organizationId: string;
}

const getOrganization = (id: string): Action => ({
  endpoint: `/organization/${id}`,
  method: 'GET',
  credentials: 'include',
});

export const OrganizationDetails: FC = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { query, payload } = useQuery(getOrganization(id));

  const { query: reload, payload: data } = useSuspenseQuery<OrganizationConfiguration>({
    endpoint: `/organization_configuration/${id}`,
    method: 'GET',
    credentials: 'include',
  });
  useEffect(() => {
    query();
    reload();
  }, [query, id]);

  return payload ? (
    <div>
      <Box pb={4}>
        <Typography variant="h4">Organization {payload?.name}</Typography>
      </Box>
      <Box display='flex'>
        <Invitation endpoint={`/organization/${id}/user/invite`} />
      </Box>
      <Box m={2} alignSelf="stretch" style={{ maxHeight: 'auto', minWidth: '700px' }} flex={3}>
        <TableContainer
          component={({ children }) => (
            <Paper style={{ height: '100%' }}>
              <Box p={4}>{children}</Box>
            </Paper>
          )}
        >
          <BillingsComponent organizationId={id}></BillingsComponent>
        </TableContainer>
      </Box>
      <Box m={2} alignSelf="stretch" style={{ maxHeight: '600px', minWidth: '700px' }} flex={3}>
        <TableContainer
          component={({ children }) => (
            <Paper style={{ height: '100%' }}>
              <Box p={4}>{children}</Box>
            </Paper>
          )}
        >
          <TokenComponent organizationId={id}></TokenComponent>
        </TableContainer>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box m={2} alignSelf="stretch" style={{ maxHeight: '600px', minWidth: '200px' }} flex={1}>
          <Paper style={{ height: '100%' }}>
            <Box p={4}>
              <DemoParameters organizationConfig={data} reloadConfig={reload} />
            </Box>
          </Paper>
        </Box>
        <Box m={2} alignSelf="stretch" style={{ maxHeight: '600px', minWidth: '700px' }} flex={2}>
          <TableContainer
            component={({ children }) => (
              <Paper style={{ height: '100%' }}>
                <Box p={4}>{children}</Box>
              </Paper>
            )}
          >
            <DocumentStyleList organizationId={id} />
          </TableContainer>
        </Box>
        <Box m={2} alignSelf="stretch" style={{ maxHeight: '600px', minWidth: '700px' }} flex={2}>
          <TableContainer
            component={({ children }) => (
              <Paper style={{ height: '100%' }}>
                <Box p={4}>{children}</Box>
              </Paper>
            )}
          >
            <DocumentStyleFontList organizationId={id} />
          </TableContainer>
        </Box>
      </Box>
    </div>
  ) : null;
};

export default OrganizationDetails;
