// @ts-ignore
import Users from '@ckeditor/ckeditor5-collaboration-core/src/users';
// @ts-ignore
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// @ts-ignore
import DocumentEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import { uniq } from 'lodash';

import { getDocumentContentWithAttributes } from '.';
import { defaultLanguage } from 'config/language';
import { DocumentStyle } from 'models/DocumentStyle';
import { DocumentStyleFont } from 'models/DocumentStyleFont';
import { DocumentSuggestion } from 'models/DocumentSuggestion';
import { BaseDocument } from 'models/DocumentTemplate';
import { getPluginsConfig, getPluginsForExport } from 'shared/components/DocumentEditor/ckeditorConfig';
import { documentSuggestionToEditorSuggestion } from 'shared/components/DocumentEditor/utils/suggestions';

class FakeUserPlugin extends Plugin {
  static get requires() {
    return [Users];
  }

  init() {
    const { editor }: any = this;

    const suggestions = editor.config.get('suggestions');

    const usersPlugin = editor.plugins.get('Users');
    uniq(suggestions.map((e: DocumentSuggestion) => e.authorId)).forEach((id) => {
      usersPlugin.addUser({
        id,
        name: id,
      });
    });

    usersPlugin.addUser({
      id: 'fake-id',
      name: 'fake-name',
    });
    usersPlugin.defineMe('fake-id');
  }
}

export const getDocumentContentForExport = async (
  document: BaseDocument,
  style: DocumentStyle,
  customFonts: DocumentStyleFont[],
  useAttributeValue?: boolean
) => {
  const t = await DocumentEditor.create(document.content, {
    licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
    // plugins: [FakeUserPlugin, ...getPlugins({ commentsEnabled: false, style })],
    plugins: [FakeUserPlugin, ...getPluginsForExport({ commentsEnabled: false, style })],
    ...getPluginsConfig(style, customFonts),
    suggestions: document?.suggestions ? document?.suggestions.map((d) => documentSuggestionToEditorSuggestion(d)) : [],
    language: defaultLanguage,
  });

  try {
    const currentEditorContent = t.getData();
    // const plugin = t.plugins.get('TrackChangesData');
    // const result = await plugin.getDataWithDiscardedSuggestions({ useAttributeValue: true });

    return useAttributeValue
      ? getDocumentContentWithAttributes({
          ...document,
          content: currentEditorContent,
        })
      : currentEditorContent;
  } catch (e) {
    return '';
  } finally {
    t.destroy();
  }
};
