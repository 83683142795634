import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Grid, MenuItem, TextField, Typography } from '@material-ui/core';

import { defaultLanguage, supportedLanguages } from 'config/language';

export interface MemberSetttingsModel {
  autentiAccessToken: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  companyName: string;
  locale: string;
}

interface MemberSettingsFormProps {
  data: MemberSetttingsModel;
  onSubmit: (data: MemberSetttingsModel) => void;
  shouldShowAutentiToken: boolean,
}

export const MemberSettingsForm: FC<MemberSettingsFormProps> = ({ data, onSubmit, shouldShowAutentiToken }) => {
  const {
    handleSubmit,
    register,
    errors: formErrors,
    formState,
    reset,
    control,
  } = useForm<MemberSetttingsModel>({
    criteriaMode: 'all',
    defaultValues: { ...data, locale: data.locale ? data.locale : defaultLanguage },
  });
  const { t } = useTranslation();

  const fields: { field: keyof MemberSetttingsModel; label: string; required: boolean }[] = [
    {
      field: 'firstName',
      label: t('member_settings_form.first_name', 'First Name'),
      required: true,
    },
    {
      field: 'lastName',
      label: t('member_settings_form.last_name', 'Last Name'),
      required: true,
    },
    {
      field: 'companyName',
      label: t('member_settings_form.company_name', 'Company Name'),
      required: false,
    },
    {
      field: 'jobTitle',
      label: t('member_settings_form.job_title', 'Job Title'),
      required: false,
    },
    {
      field: 'autentiAccessToken',
      label: t('member_settings_form.autenti_access_token', 'Autenti Access Token'),
      required: false,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        {fields.map(({ field, label, required }) => (
          <Grid item key={field}>
            {field === 'autentiAccessToken' && shouldShowAutentiToken && (
              <Typography component="p">
                {t(
                  'member_settings_form.autenti_info',
                  'Fill this field only if you have Autenti account and token. Otherwise - leave this field empty; you can fill this details later'
                )}
              </Typography>
            )}
            {((field === 'autentiAccessToken' && shouldShowAutentiToken) || field !== 'autentiAccessToken') && <TextField
              variant="outlined"
              label={label}
              name={field}
              inputRef={register({ required })}
              helperText={formErrors[field] ? formErrors[field]?.message : null}
              error={Boolean(formErrors[field])}
              fullWidth
            />}
          </Grid>
        ))}
        <Grid item>
          <Controller
            control={control}
            name="locale"
            rules={{
              required: t('common.validation_error.required') as string,
            }}
            defaultValue={data.locale ?? defaultLanguage}
            as={
              <TextField
                select
                variant="outlined"
                label={t('member_settings_form.language', 'Language')}
                // inputRef={register({
                //   required: t('common.validation_error.required') as string,
                // })}
                InputLabelProps={{ shrink: true }}
              >
                {supportedLanguages.map((l) => (
                  <MenuItem key={l} value={l}>
                    {l.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            {t('member_settings_form.save', 'Save')}
          </Button>
          {formState.isDirty ? (
            <Button variant="outlined" onClick={() => reset()}>
              {t('member_settings_form.discard_changes', 'Discard changes')}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
};
