import React, { FC } from 'react';

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { DeleteAction } from '../DeleteButton/DeleteAction';
import { DocumentStyleListItem } from 'models/DocumentStyle';

interface DocumentStyleTableProps {
  data: DocumentStyleListItem[];
  reload: () => void;
  onEdit: (id: string) => void;
}
export const DocumentStyleTable: FC<DocumentStyleTableProps> = ({ data, reload, onEdit }) => {
  const body = data.map((row) => (
    <TableRow key={row.id}>
      <TableCell component="td" scope="row">
        {row.id}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.name}
      </TableCell>
      <TableCell>
        <IconButton onClick={() => onEdit(row.id)}>
          <Edit />
        </IconButton>
        <DeleteAction title="Are you sure?" onComplete={reload} endpoint={`/styles/${row.id}`} />
      </TableCell>
    </TableRow>
  ));
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th">Id</TableCell>
          <TableCell component="th">Name</TableCell>
          <TableCell component="th">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{body}</TableBody>
    </Table>
  );
};
