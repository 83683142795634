import React, { FC } from 'react';
import { Action } from 'react-fetching-library';
import { useRouteMatch } from 'react-router';

import FormWrapper from './components/FormWrapper';
import { DocumentStyleFontCreateFormData } from 'models/DocumentStyleFont';
import { DocumentStyleFontCreateForm } from 'shared/components/DocumentStyleFont/DocumentStyleFontCreateForm';

export const OrganizationStyleFontCreate: FC = () => {
  const {
    params: { id: organizationId },
  } = useRouteMatch<{ id: string }>();

  const createStylesActionCreator = (fontForm: DocumentStyleFontCreateFormData): Action => {
    const formData = new FormData();
    const { files, ...form } = fontForm;
    formData.append('font', new Blob([JSON.stringify({ ...form, organizationId })], { type: 'application/json' }));

    for (let file of files) {
      formData.append('files', file);
    }

    return {
      endpoint: '/font',
      method: 'POST',
      credentials: 'include',
      body: formData,
    };
  };
  return (
    <FormWrapper
      title="Create new font"
      data={{
        fontFamily: '',
        style: '',
        weight: '',
      }}
      actionCreator={createStylesActionCreator}
      organizationId={organizationId}
      Form={DocumentStyleFontCreateForm}
    />
  );
};

export default OrganizationStyleFontCreate;
