import { AttributeValueStrategy } from '../model';
import { booleanStrategy } from './boolean';
import { defaulStrategy } from './default';
import { AttributeType } from 'models/Enums';

const strategies: Partial<Record<AttributeType, AttributeValueStrategy>> & {
  default: AttributeValueStrategy;
} = {
  boolean: booleanStrategy,
  default: defaulStrategy,
};

export const getStrategy = (type: keyof typeof strategies): AttributeValueStrategy =>
  strategies[type] || strategies.default;
