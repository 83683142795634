import { useAuth } from 'auth';
import React, { FC } from 'react';
import { Redirect, RedirectProps, Route, RouteProps, useLocation } from 'react-router';

const ProtectedRoute: FC<RouteProps & { isMatch: boolean; redirectTo?: RedirectProps['to'] }> = ({
  isMatch,
  redirectTo,
  ...props
}) => {
  if (isMatch) {
    return <Route {...props} />;
  }
  const { children, component, ...rest } = props;
  return <Route {...rest}>{redirectTo ? <Redirect to={redirectTo} /> : null}</Route>;
};

export const PublicRoute: FC<RouteProps & { restricted?: boolean }> = ({ restricted, ...props }) => {
  const { isLoggedIn } = useAuth();
  const isMatch = !(restricted && isLoggedIn);

  const { state: { redirectUrl = '/' } = {} } = useLocation<{ redirectUrl: string }>();

  return <ProtectedRoute {...props} isMatch={isMatch} redirectTo={redirectUrl} />;
};

export const PrivateRoute: FC<RouteProps & { userType?: 'admin' | 'user' }> = ({ userType, ...props }) => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();
  const isMatch = isLoggedIn && (!userType || (userType === 'admin') === Boolean(user?.isAdmin));

  return (
    <ProtectedRoute
      {...props}
      isMatch={isMatch}
      redirectTo={
        isLoggedIn
          ? '/'
          : {
              pathname: '/login',
              state: {
                redirectUrl: location.pathname,
              },
            }
      }
    />
  );
};
