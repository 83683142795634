import { find } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef } from 'react';

import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';

interface AttributeQuery extends Pick<DocumentAttribute, 'name' | 'category'> {}

export const useWatchAttribute = (
  attributes: DocumentAttribute[],
  query: AttributeQuery,
  callback: (q: DocumentAttribute) => any
) => {
  const ref = useRef<DocumentAttribute | undefined>();

  const attr = useMemo(() => {
    const found = find<DocumentAttribute>(attributes, query);
    return isEqual(found, ref.current) ? ref.current : found;
  }, [attributes, query]);

  useEffect(() => {
    ref.current = attr;
  }, [attr]);

  const run = useRef(false);

  useEffect(() => {
    if (run.current && attr?.value !== undefined) {
      callback(attr);
    }
    run.current = true;
  }, [attr, callback]);
};
