import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Action, useClient, useMutation } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { CKEditorComment, CKEditorCommentThread } from './model';
import { DocumentComment, DocumentCommentThread } from 'models/documentation/Documentation';

export const COMMENTS_CHANNEL = 'default-channel';
export const useCommentsBaseUrl = () => {
  const {
    params: { organizationId, membershipId, documentationId, documentId },
  } = useRouteMatch<{ documentationId: string; documentId: string; organizationId: string; membershipId: string }>();

  return `/documentation/${organizationId}/${membershipId}/${documentationId}/document/${documentId}`;
};

interface CommentAdapter {
  addComment: (data: CKEditorComment) => Promise<{ createdAt: number }>;
  updateComment: (data: CKEditorComment) => Promise<void>;
  removeComment: (data: CKEditorComment) => Promise<void>;
  getCommentThread: (data: CKEditorCommentThread) => Promise<CKEditorCommentThread>;
  removeCommentThread: (data: CKEditorCommentThread) => Promise<void>;
}

const useCommentThreadsAction = () => {
  const baseUrl = useCommentsBaseUrl();

  const commentThreadsAction = useMemo(
    (): Action => ({
      method: 'GET',
      endpoint: `${baseUrl}/thread`,
      credentials: 'include',
    }),
    [baseUrl]
  );

  return commentThreadsAction;
};

const useCommentThreadsQuery = () => {
  const client = useClient();
  const action = useCommentThreadsAction();
  const res = useCallback(
    () =>
      client.query(action).then((e) => {
        return e.payload || [];
      }),
    [client, action]
  );

  return res;
};

export const useCommentsAdapter = (reloadDocument?: () => void) => {
  const adapter = useRef<CommentAdapter>({} as CommentAdapter);

  adapter.current.addComment = useAddComment(reloadDocument);
  adapter.current.getCommentThread = useGetCommentThread();
  adapter.current.removeComment = useRemoveComment();
  adapter.current.updateComment = useUpdateComment();
  adapter.current.removeCommentThread = useRemoveCommentThread(reloadDocument);

  return { adapter: adapter.current };
};

const useGetCommentThread = () => {
  const query = useCommentThreadsQuery();
  const callback = useCallback(
    (data: CKEditorCommentThread): Promise<CKEditorCommentThread> => {
      return query().then((commentThreads: any[]) => {
        const thread = commentThreads.find((t) => t.id === data.threadId);

        const comments = thread?.comments.map(
          (c: DocumentComment): CKEditorComment => ({
            channelId: COMMENTS_CHANNEL,
            commentId: c.id,
            content: c.content,
            threadId: data.threadId,
            authorId: c.authorId,
            createdAt: c.createdAt,
          })
        );

        return Promise.resolve({
          threadId: data.threadId,
          comments: comments || [],
          isFromAdapter: true,
        });
      });
    },
    [query]
  );

  return callback;
};
const useUpdateComment = () => {
  const baseUrl = useCommentsBaseUrl();
  const updateCommentActionCreator = useCallback(
    ({ threadId, comment }: { threadId: string; comment: DocumentComment }): Action => {
      return {
        method: 'PUT',
        endpoint: `${baseUrl}/thread/${threadId}/comment/${comment.id}`,
        credentials: 'include',
        body: comment,
      };
    },
    [baseUrl]
  );

  const { mutate: updateComment } = useMutation<void>(updateCommentActionCreator);

  const callback = useCallback(
    (data: CKEditorComment): Promise<void> => {
      return updateComment({ threadId: data.threadId, comment: mapCKEditorComment(data) }).then();
    },
    [updateComment]
  );

  return callback;
};

const useRemoveComment = () => {
  const baseUrl = useCommentsBaseUrl();
  const deleteCommentActionCreator = useCallback(
    ({ commentId, threadId }): Action => ({
      method: 'DELETE',
      endpoint: `${baseUrl}/thread/${threadId}/comment/${commentId}`,
      credentials: 'include',
    }),
    [baseUrl]
  );

  const { mutate: deleteComment } = useMutation<void>(deleteCommentActionCreator);

  const callback = useCallback(
    ({ threadId, commentId }: CKEditorComment): Promise<void> =>
      deleteComment({
        threadId,
        commentId,
      }).then(),
    [deleteComment]
  );

  return callback;
};
const useRemoveCommentThread = (reloadDocument?: () => void) => {
  const baseUrl = useCommentsBaseUrl();
  const deleteThreadActionCreator = useCallback(
    ({ threadId }): Action => ({
      method: 'DELETE',
      endpoint: `${baseUrl}/thread/${threadId}`,
      credentials: 'include',
      skipErrorNotification: true,
    }),
    [baseUrl]
  );

  const { mutate: deleteThread } = useMutation<void>(deleteThreadActionCreator);

  const callback = useCallback(
    (data: CKEditorCommentThread): Promise<void> => {
      return deleteThread({
        threadId: data.threadId,
      }).then(() => {
        reloadDocument && reloadDocument();
      });
    },
    [deleteThread, reloadDocument]
  );

  return callback;
};

const useAddComment = (reloadDocument?: () => void) => {
  const baseUrl = useCommentsBaseUrl();
  const { t } = useTranslation();
  const commentThreadsQuery = useCommentThreadsQuery();
  const addCommentThreadOrCommentActionCreator = useCallback(
    ({
      isCommentThread,
      comment,
      threadId,
    }: {
      isCommentThread: boolean;
      threadId: string;
      comment: Pick<DocumentComment, 'content' | 'id'>;
    }): Action => ({
      method: isCommentThread ? 'PUT' : 'POST',
      endpoint: `${baseUrl}/thread/${threadId}`,
      credentials: 'include',
      body: comment,
    }),
    [baseUrl]
  );

  const { mutate: addCommentThread } = useMutation<DocumentCommentThread | DocumentComment>(
    addCommentThreadOrCommentActionCreator
  );

  const callback = useCallback(
    (data: CKEditorComment) => {
      return commentThreadsQuery().then((commentThreads: DocumentCommentThread[]) => {
        const hasCommentThread = Boolean(commentThreads?.find((t) => t.id === data.threadId));

        return addCommentThread({
          threadId: data.threadId,
          isCommentThread: !hasCommentThread,
          comment: {
            content: data.content,
            id: data.commentId,
          },
        }).then((response) => {
          if (response.error) {
            throw new Error(t('common.error.unknown') as string);
          }
          const comment = hasCommentThread
            ? (response.payload as DocumentComment)
            : (response.payload as DocumentCommentThread)?.comments?.[0];
          if (!hasCommentThread) {
            reloadDocument && reloadDocument();
          }
          return {
            createdAt: comment.createdAt,
          };
        });
      });
    },
    [addCommentThread, commentThreadsQuery, reloadDocument, t]
  );

  return callback;
};

export const mapCKEditorComment = (c: CKEditorComment): DocumentComment => ({
  authorId: c.authorId,
  content: c.content,
  createdAt: c.createdAt || 0,
  id: c.commentId,
});

const useScript = (src: string) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;

    script.addEventListener('load', () => {
      setLoaded(true);
    });
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);

  return loaded;
};

export const useCKEditorTranslationScript = (locale: string) => {
  useScript(`/ckeditor-translations/${locale}.js`);
};
