import React, { ErrorInfo } from 'react';
import { Translation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Link, Typography } from '@material-ui/core';

export class ErrorBoundary extends React.Component<{}, { error: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      error: false,
    };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.debug('error boundary', error, info);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return error ? (
      <Translation>
        {(t) => (
          <>
            <Typography color="error" variant="h2">
              {t('common.error.unknown')}
            </Typography>

            <Link
              underline={'none'}
              component={RouterLink}
              to="/"
              onClick={() => {
                this.setState({ error: false });
              }}
            >
              <Button color="primary" variant="contained">
                {t('common.error_page_link', 'Back to dashboard')}
              </Button>
            </Link>
          </>
        )}
      </Translation>
    ) : (
      children
    );
  }
}
