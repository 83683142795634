import { DocumentSuggestion } from 'models/DocumentSuggestion';

export interface EditorSuggestion extends Omit<DocumentSuggestion, 'attributes' | 'data' | 'createdAt'> {
  attributes: {};
  data: {};
  createdAt: Date;
}
export const documentSuggestionToEditorSuggestion = ({
  attributes,
  data,
  createdAt,
  ...suggestion
}: DocumentSuggestion): EditorSuggestion => ({
  ...suggestion,
  createdAt: new Date(createdAt),
  attributes: JSON.parse(attributes),
  data: JSON.parse(data),
});
export const editorSuggestionToDocumentSuggestion = ({
  attributes,
  data,
  authorId,
  hasComments,
  id,
  type,
  createdAt,
}: EditorSuggestion): DocumentSuggestion => ({
  id,
  attributes: JSON.stringify(attributes),
  data: JSON.stringify(data),
  authorId,
  hasComments,
  createdAt: createdAt.toISOString(),
  type,
});
