import React, { FC, useState } from 'react';

import {
  Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography
} from '@material-ui/core';

import * as yup from 'yup';
import { API_BASE_URL } from 'api/config';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from 'components/LoadingButton';
import { format } from 'date-fns';

interface BillingsCsvExportProps {
  organizationId: string;
}
const validationSchema = yup.object().shape({
  createdAtAfter: yup.date().nullable().required(),
  createdAtBefore: yup.date().nullable()
});

interface FormValues {
  createdAtAfter: string;
  createdAtBefore: string;
}

export const BillingsCsvExport: FC<BillingsCsvExportProps> = ({
  organizationId
}) => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const translateIfExists = (value?: string) => value && t(value);
  const snackbar = useSnackbar();

  const defaultAfter = new Date();
  const defaultBefore = new Date();
  defaultAfter.setMonth(defaultAfter.getMonth() - 1);

  const form = useForm<FormValues>({
    reValidateMode: 'onChange',
    defaultValues: {
      createdAtAfter: defaultAfter.toLocaleDateString('en-CA'),
      createdAtBefore: defaultBefore.toLocaleDateString('en-CA'),
    },
    resolver: yupResolver(validationSchema),
  });

  const doSubmit = () => {
    form.handleSubmit((formData: FormValues) => {
      return new Promise(() => {
        fetch(API_BASE_URL +
          `/admin/signing/configuration/${organizationId}/csv/export/state`, {
          credentials: 'include'
        })
          .then(response => response.json())
          .then(data => {
            if (data.exportState != 'RUNNING') {
              const baseExportUrl =
                API_BASE_URL + `/admin/signing/configuration/${organizationId}/csv/export`;
              const form = document.createElement('form');
              form.setAttribute('target', '_blank');
              form.method = 'POST';
              form.action = baseExportUrl;
              const formDataInput = document.createElement('input');
              formDataInput.type = 'hidden';
              formDataInput.name = 'formData';
              formDataInput.value = JSON.stringify({
                createdAtAfter: format(new Date(formData.createdAtAfter), "yyyy-MM-dd"),
                createdAtBefore: format(new Date(formData.createdAtBefore), "yyyy-MM-dd"),
              });
              form.appendChild(formDataInput);
              document.body.appendChild(form);
              form.submit();
            } else {
              throw new Error(data.message ?? "Export error");
            }
          })
          .catch(error => {
            snackbar.enqueueSnackbar(error.message, { variant: 'error' });
          });
      });
    })();
  };

  return (
    <>
      <Button color="secondary" onClick={() => setOpened(true)}>
        Export csv
      </Button>
      <Dialog
        open={opened}
        fullWidth={true}
        maxWidth="xs"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={() => setOpened(false)}
      >
        <DialogTitle>
          <span>{t('export.agreement.csv.title')}</span>
        </DialogTitle>

        <Typography component="p" color="secondary" style={{ marginLeft: '20px', marginBottom: '10px' }}>
          {t("export.agreement.csv.description")}
        </Typography>

        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Card>
              <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Controller
                  name="createdAtAfter"
                  control={form.control}
                  render={({ onChange, onBlur, value }) => (
                    <TextField
                      label={t("export.agreement.csv.form.date.from.label")}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(form.errors?.createdAtAfter)}
                      helperText={translateIfExists(form.errors?.createdAtAfter?.message)}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onBlur={onBlur}
                      value={value}
                      variant="outlined"
                      fullWidth={true}
                    />
                  )}
                />
                <Controller
                  name="createdAtBefore"
                  control={form.control}
                  render={({ onChange, onBlur, value }) => (
                    <TextField
                      label={t("export.agreement.csv.form.date.to.label")}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(form.errors?.createdAtBefore)}
                      helperText={translateIfExists(form.errors?.createdAtBefore?.message)}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      onBlur={onBlur}
                      value={value}
                      variant="outlined"
                      fullWidth={true}
                    />
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" color="primary" onClick={() => doSubmit()}>
            {t('export.agreement.csv.action')}
          </LoadingButton>
          <Button variant="outlined" onClick={() => setOpened(false)}>
            {t('export.agreement.csv.exit')}
          </Button>
        </DialogActions>

      </Dialog>
    </>
  );
};
