import React, { FC, useMemo } from 'react';
import { Action, useQuery } from 'react-fetching-library';
import { useRouteMatch } from 'react-router';

import FormWrapper from './components/FormWrapper';
import { DocumentStyleFont, DocumentStyleFontBase } from 'models/DocumentStyleFont';
import { DocumentStyleFontUpdateForm } from 'shared/components/DocumentStyleFont/DocumentStyleFontUpdateForm';

const useGetStyleFontQuery = (id: string) => {
  const action: Action = useMemo(
    (): Action => ({
      endpoint: `/font/${id}`,
      method: 'GET',
      credentials: 'include',
    }),
    [id]
  );

  return useQuery(action);
};
export const OrganizationStyleFontDetails: FC = () => {
  const {
    params: { id: organizationId, fontId },
  } = useRouteMatch<{ id: string; fontId: string }>();
  const updateFontStyleActionCreator = (body: DocumentStyleFontBase): Action => ({
    endpoint: `/font/${fontId}`,
    method: 'PUT',
    credentials: 'include',
    body: {
      ...body,
      organizationId,
    },
  });
  const { payload } = useGetStyleFontQuery(fontId);

  return payload ? (
    <FormWrapper
      title={`Update font: ${payload.fontFamily}`}
      data={payload as DocumentStyleFont}
      actionCreator={updateFontStyleActionCreator}
      organizationId={organizationId}
      Form={DocumentStyleFontUpdateForm}
    />
  ) : null;
};

export default OrganizationStyleFontDetails;
