import { useEffect, useMemo, useState } from 'react';
import { Action, useParameterizedQuery, useSuspenseQuery } from 'react-fetching-library';

import { DocumentStyle, DocumentStyleListItem } from 'models/DocumentStyle';
import { DocumentStyleFont } from 'models/DocumentStyleFont';
import { customFontsActionCreator, documentStylesActionCreator } from 'shared/api/actions';
import { defaultStyle, prepareCustomFonts } from 'shared/helpers/documentStyle';

export const useDocumentStyles = (organizationId: string, membershipId: string, ids?: string[]) => {
  const [styles, setStyles] = useState<DocumentStyle[] | null>(null);

  const { query } = useParameterizedQuery(documentStylesActionCreator);

  useEffect(() => {
    if (!ids) {
      return;
    }

    if (ids.length === 0) {
      setStyles([]);
      return;
    }

    query({
      organizationId,
      membershipId,
      ids,
    }).then(({ status, payload }) => {
      if (status === 200) {
        setStyles(payload);
      }
    });
  }, [ids, membershipId, organizationId, query]);

  return styles;
};

export const useDocumentStyle = (organizationId: string, membershipId: string, id?: string) => {
  const ids = useMemo(() => (id ? [id] : undefined), [id]);
  const styles = useDocumentStyles(organizationId, membershipId, ids);

  if (!id) {
    return defaultStyle;
  }
  return styles === null ? null : styles?.[0] || defaultStyle;
};

export const useCustomFonts = (organizationId: string, membershipId: string, ids?: string[]) => {
  const [fonts, setFonts] = useState<DocumentStyleFont[] | null>(null);

  const { query } = useParameterizedQuery<DocumentStyleFont[]>(customFontsActionCreator);

  useEffect(() => {
    if (!ids) {
      return;
    }
    if (ids.length === 0) {
      setFonts([]);
      return;
    }
    query({
      organizationId,
      membershipId,
      ids,
    }).then(({ status, payload }) => {
      if (status === 200) {
        setFonts(prepareCustomFonts(payload));
      }
    });
  }, [ids, membershipId, organizationId, query]);

  return fonts;
};

export const useDocumentStyleList = (organizationId: string, membershipId: string) => {
  const action = useMemo((): Action => {
    return {
      method: 'GET',
      endpoint: `/organization/${organizationId}/organizationMembership/${membershipId}/styles/options`,
      credentials: 'include',
    };
  }, [membershipId, organizationId]);

  const { payload, error } = useSuspenseQuery<DocumentStyleListItem[]>(action);

  return (error || !payload ? [] : payload) as DocumentStyleListItem[];
};
