import { useAuth } from 'auth';
import { isArray } from 'lodash';
import React, { FC, createContext, useContext } from 'react';
import { Action, useSuspenseQuery } from 'react-fetching-library';

import {
  AppPermissions,
  DocumentationFlowRole,
  DocumentationPermission,
  DocumentationRole,
  OrganizationPermission,
} from './model';
import { UserType } from 'models/Enums';

interface PermissionContextValue {
  hasDocumentationPermission: (
    role: DocumentationRole | DocumentationFlowRole | undefined,
    perm: DocumentationPermission
  ) => boolean;
  hasOrganizationPermission: (userType: UserType, perm: OrganizationPermission) => boolean;
}
const PermissionContext = createContext<PermissionContextValue>({
  hasDocumentationPermission() {
    throw new Error('Missing permissions provider');
  },
  hasOrganizationPermission() {
    throw new Error('Missing permissions provider');
  },
});

const permissionsAction: Action = {
  endpoint: '/user/permissions',
  method: 'GET',
  credentials: 'include',
};

const ActualProvider: FC = ({ children }) => {
  const { payload: permissions } = useSuspenseQuery<AppPermissions>(permissionsAction);
  const hasOrganizationPermission = (
    userType: UserType,
    perm: OrganizationPermission | OrganizationPermission[]
  ): boolean => {
    const perms = isArray(perm) ? perm : [perm];
    return perms.some((p) => permissions!.organizationPermissions?.[userType]?.includes(p) || false);
  };

  const hasDocumentationPermission = (
    role: DocumentationRole | DocumentationFlowRole | undefined,
    perm: DocumentationPermission
  ): boolean => {
    if (!role) {
      return false;
    }
    return permissions!.documentationPermissions?.[role]?.includes(perm) || false;
  };

  return (
    <PermissionContext.Provider
      value={{
        hasDocumentationPermission,
        hasOrganizationPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const PermissionsProvider: FC = ({ children }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <ActualProvider>{children}</ActualProvider>;
  } else {
    return (
      <PermissionContext.Provider
        value={{
          hasDocumentationPermission: (
            _role: DocumentationRole | DocumentationFlowRole | undefined,
            _perm: DocumentationPermission
          ) => false,
          hasOrganizationPermission: (_role: UserType, _perm: OrganizationPermission) => false,
        }}
      >
        {children}
      </PermissionContext.Provider>
    );
  }
};
export const usePermissions = () => useContext(PermissionContext);
