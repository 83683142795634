import React, { FC, createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Breadcrumbs, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { ReactComponent as ActiveFlow } from 'images/active_flow.svg';
import { ReactComponent as GoBack } from 'images/arrowleft.svg';
import { generateDisplayId } from 'shared/helpers/attributes';

interface AdditionalNavbarContextValue {
  generateNavbarChildren: (children: React.ReactNode) => void;
  breadcrumbs: React.ReactElement;
  setCurrentDocumentName: (newName: string) => void;
  setActiveFlowId: (id: string | null | undefined) => void;
}

const AdditionalNavbarContext = createContext<AdditionalNavbarContextValue>({
  generateNavbarChildren: () => () => <></>,
  breadcrumbs: <></>,
  setCurrentDocumentName: () => () => null,
  setActiveFlowId: () => () => null,
});

export const AdditionalNavbarProvider: FC = ({ children }) => {
  const [additionalNavbarChildren, setAdditionalNavbarChildren] = useState<React.ReactNode>(<></>);
  const [activeFlowId, setActiveFlowId] = useState<string | null | undefined>(null);
  const { t } = useTranslation();

  const history = useHistory();

  const generateNavbarChildren = useCallback((children: React.ReactNode) => {
    setAdditionalNavbarChildren(children);
  }, []);
  const [currentDocumentName, setCurrentDocumentName] = React.useState<string | null>('');

  const nameConverted = React.useMemo(() => {
    if (!currentDocumentName) {
      return 'Temporary name';
    }
    if (currentDocumentName.length > 20) {
      return currentDocumentName.slice(0, 15) + '(...)';
    } else {
      return currentDocumentName;
    }
  }, [currentDocumentName]);

  const generateFlowIcon = React.useCallback(
    (name?) => {
      if (name) {
        return activeFlowId ? (
          <Box display="flex" alignItems="center">
            {generateDisplayId(name)}
            <ActiveFlow />
          </Box>
        ) : (
          generateDisplayId(name)
        );
      }
      return (
        <Box display="flex" alignItems="center">
          {'Untitled'}
        </Box>
      );
    },
    [activeFlowId]
  );

  const generateBreadcrumbs = React.useCallback(
    (location) => {
      const url = location.pathname.split('/').filter((path: string) => path !== '');

      const mainUrl = {
        to: '/' + url.slice(0, 3).join('/') + '/documentation',
        name: t('navigation_bar.documentations', 'Documentation'),
      };
      const breadcrumbs: { to: string; name: string | React.ReactElement }[] = [mainUrl];
      const mainDocUrl = { to: mainUrl.to + '/details/' + url[5], name: generateFlowIcon(url[5]) };
      const docUrl = { to: mainDocUrl.to + '/document' + url[7], name: nameConverted };
      const amendmentUrl = {
        to: mainDocUrl.to + '/amendment' + url[7],
        name: t('document_category.Amendment', 'Amendment'),
      };

      if (url.length > 5) {
        breadcrumbs.push(mainDocUrl);
      }

      if (url.length > 7) {
        if (url[6] !== 'amendment') {
          breadcrumbs.push(docUrl);
        } else {
          breadcrumbs.push(amendmentUrl);
        }
      }

      return (
        <Box display="flex" alignItems="center">
          <Tooltip title="Go back">
            <IconButton onClick={() => history.goBack()}>
              <GoBack />
            </IconButton>
          </Tooltip>
          <div style={{ width: '1px', height: '60px', margin: '0 16px 0 0', backgroundColor: '#D2D0CC' }} />
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} style={{ fontWeight: 'bold' }}>
            {breadcrumbs.map((breadcrumb, index, array) => {
              if (index === array.length - 1) {
                return (
                  <Typography key={breadcrumb.to} color="primary" component="strong">
                    {breadcrumb.name}
                  </Typography>
                );
              }
              return (
                <Link
                  style={{ cursor: 'pointer' }}
                  key={breadcrumb.to}
                  color="primary"
                  onClick={() => history.push(breadcrumb.to)}
                >
                  {breadcrumb.name}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
      );
      // console.log(url);
    },
    [generateFlowIcon, history, nameConverted]
  );

  const [usedBreadcrumbs, setUsedBreadcrumbs] = React.useState<React.ReactElement>(
    generateBreadcrumbs(history.location)
  );

  history.listen((location) => setUsedBreadcrumbs(generateBreadcrumbs(location)));

  React.useEffect(
    () => setUsedBreadcrumbs(generateBreadcrumbs(history.location)),
    [currentDocumentName, generateBreadcrumbs, history.location, activeFlowId]
  );

  React.useEffect(() => {
    setUsedBreadcrumbs(generateBreadcrumbs(history.location));
  }, [activeFlowId]);

  return (
    <AdditionalNavbarContext.Provider
      value={{
        generateNavbarChildren,
        breadcrumbs: usedBreadcrumbs,
        setCurrentDocumentName,
        setActiveFlowId,
      }}
    >
      <Box style={{ position: 'fixed', width: '100%', zIndex: 2 }}>{additionalNavbarChildren}</Box>
      {children}
    </AdditionalNavbarContext.Provider>
  );
};

export const useAdditionalNavbar = () => useContext(AdditionalNavbarContext);
