import { useCallback, useEffect, useState } from 'react';
import { Action, useParameterizedQuery } from 'react-fetching-library';

const birQueryActionCreator = (data: BIRQuery): Action => ({
  method: 'GET',
  endpoint: `/search/company?${data.key.toLocaleLowerCase()}=${data.value}`,
  credentials: 'include',
});

enum CompanyType {
  F = 'F',
  P = 'P',
  LP = 'LP',
}
interface BIRCompany {
  Gmina: string;
  KodPocztowy: string;
  Miejscowosc: string;
  Nazwa: string;
  Nip: string;
  NrLokalu: string;
  NrNieruchomosci: string;
  Regon: string;
  Typ: CompanyType;
  Ulica: string;
}

interface BIREntity {
  nazwa: string;
  adSiedzMiejscowosc_Nazwa: string;
  adSiedzUlica_Nazwa: string;
  adSiedzNumerLokalu: string;
  adSiedzNumerNieruchomosci: string;
  organRejestrowy_Nazwa: string;
  adSiedzKodPocztowy: string;
  regon9: string;
  nip: string;
  numerWRejestrzeEwidencji: string;
}

export interface Company {
  name: string;
  seatAddress: string;
  postAddress: string;
  regon: string;
  nip: string;
  krs: string;
  registryCourt: string;
}

export interface BIRQuery {
  key: 'NIP' | 'KRS' | 'REGON';
  value: string;
}

const mapCompany = (c: BIRCompany): Company => {
  const address: string = `${c.Ulica}, ${c.NrNieruchomosci} ${c.NrLokalu}, ${c.KodPocztowy} ${c.Miejscowosc}`;
  const companyData: Company = {
    name: c.Nazwa,
    seatAddress: address,
    postAddress: address,
    regon: c.Regon,
    nip: c.Nip,
    krs: '',
    registryCourt: '',
  };
  return companyData;
};

const mapEntity = (data: BIREntity): Company => {
  const address =
    `${data.adSiedzUlica_Nazwa}, ${data.adSiedzNumerNieruchomosci}, ` +
    `${data.adSiedzKodPocztowy} ${data.adSiedzMiejscowosc_Nazwa}`;
  const companyData: Company = {
    name: data.nazwa,
    seatAddress: address,
    postAddress: address,
    regon: data.regon9,
    nip: data.nip,
    krs: data.numerWRejestrzeEwidencji,
    registryCourt: data.organRejestrowy_Nazwa,
  };
  return companyData;
};

//@TODO: extend validation with the control number;
const validators: Record<BIRQuery['key'], (v: string) => boolean> = {
  KRS: (v) => v.length === 10,
  REGON: (v: string) => v.length === 9 || v.length === 14,
  NIP: (v: string) => v.length === 10,
};

export const useBIRApi = (data: BIRQuery | null, timeout = 3000): [Company | null, () => void] => {
  const {
    query,
    payload,
    status,
    reset: queryReset,
  } = useParameterizedQuery<BIRCompany | BIREntity>(birQueryActionCreator);

  const [result, setResult] = useState<Company | null>(null);

  const reset = useCallback(() => {
    queryReset();
    setResult(null);
  }, [queryReset]);
  useEffect(() => {
    if (!data || !validators[data.key](data.value)) {
      return;
    }
    const timeoutId = setTimeout(() => {
      query(data);
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data, query, timeout]);

  useEffect(() => {
    if (!payload || status !== 200) {
      return;
    }

    const type = (payload as BIRCompany).Typ;
    const isCompany = [CompanyType.LP, CompanyType.P, CompanyType.F].includes(type);
    if (type && !isCompany) {
      // eslint-disable-next-line no-console
      console.error(`Unrecognized company type: "${type}"`);
      return;
    }

    const mapped = isCompany ? mapCompany(payload as BIRCompany) : mapEntity(payload as BIREntity);
    setResult(mapped);
  }, [payload, status]);

  return [result, reset];
};
