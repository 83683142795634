import { useAuth } from 'auth';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { OrganizationSelector } from '../../../containers/OrganizationSelector';
import { NotificationsMenu } from '../../../pages/Notifications/NotificationsMenu';
import { NavbarDropdownMenu } from './NavbarDropdownMenu';
import TutorialModal from './components/ModalHelp/TutorialModal';
import SidePanel from './components/SidePanel/SidePanel';
import { useOrganization } from 'contexts/organization';
import { ReactComponent as Logo } from 'images/logo.svg';

interface NavigationBarProps {
  isCreateLeasesActive?: boolean;
  style?: any;
}

const NavigationBar = (props: NavigationBarProps): JSX.Element | null => {
  const { user } = useAuth();
  const organization = useOrganization();

  if (!user) {
    return null;
  }

  return (
    <>
      <nav className="navigation" style={props.style}>
        <div className="navigation__left">
          <NavLink to="/">
            <div className="navigation__logo">
              <Logo />
            </div>
          </NavLink>
          <OrganizationSelector />
        </div>

        <div className="navigation__right">
          <NotificationsMenu />
          <NavbarDropdownMenu />
        </div>
        {organization && organization?.showModalTutorial && (
          <TutorialModal setModalTutorialVisible={organization.setModalTutorialVisible} />
        )}
      </nav>
      <SidePanel organization={organization} />
    </>
  );
};

export default NavigationBar;
