import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IconButton, Tooltip } from '@material-ui/core';

interface SidePanelIconProps {
  to: string;
  tooltip: string;
  icon: React.ReactNode;
  exact?: boolean;
}

export const SidePanelIcon: FC<SidePanelIconProps> = (props) => {
  return (
    <NavLink
      className="navigation__to-section-link"
      activeClassName="navigation__to-section-link--active"
      to={props.to}
    >
      <Tooltip title={<p style={{ margin: 'none', fontSize: '12px' }}>{props.tooltip}</p>} placement="top">
        <IconButton style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit">
          {props.icon}
        </IconButton>
      </Tooltip>
    </NavLink>
  );
};
