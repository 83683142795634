// @ts-ignore
import DocumentEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useAuth } from 'auth';
import { difference } from 'lodash';
import keyBy from 'lodash/keyBy';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { CircularProgress, makeStyles } from '@material-ui/core';

import { getPlugins, getPluginsConfig, getToolbar } from '../ckeditorConfig';
import { useCKEditorTranslationScript, useCommentsAdapter } from '../hooks';
import { CKEditorUser, EditorModel, EditorProps } from '../model';
import { ATTRIBUTE_EVENT_FEED_UPDATED } from '../plugins/attribute/constants';
import { documentSuggestionToEditorSuggestion, editorSuggestionToDocumentSuggestion } from '../utils/suggestions';
import styles from './editor.module.sass';
import { defaultLanguage } from 'config/language';
import { useOrganization } from 'contexts/organization';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { PartialUserInfo } from 'pages/Documentation/model';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { getAttributeDisplayValue } from 'shared/helpers/document/attributeValue/getAttributeDisplayValue';

let globalAttributeResetFlag = false;

const attachEditorDebbuger = (editor: EditorModel) => {
  if (process.env.REACT_APP_ATTACH_CKE_INSPECTOR === 'true') {
    // @ts-ignore
    import('@ckeditor/ckeditor5-inspector').then((inspector) => {
      inspector.default.attach('editor', editor);
    });
  }
};
function editorWalk(modelElement: any, callback: (arg: any) => void) {
  callback(modelElement);
  if (modelElement.getChildren) {
    for (let child of modelElement.getChildren()) {
      editorWalk(child, callback);
    }
  }
}

const useStyles = makeStyles(() => ({
  editor: {
    maxHeight: (hasTabs) => `calc(100vh - ${155 + (hasTabs ? 80 : 0)}px)`,
  },
}));

export const Editor: FC<EditorProps> = ({
  editorRef,
  documentStyle,
  attributes,
  initialData = '',
  readOnly,
  onChange,
  reloadDocument,
  customFonts,
  commentsEnabled = false,
  hasTabs = false,
  displayAttributeValues,
  initialSuggestions,
  enableTrackChangesInToolbar,
}) => {
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const ckEditorRef = useRef<any>();

  const { t } = useTranslation();
  const classNames = useStyles(hasTabs);

  const { user } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const indexedAttributes = useMemo(() => keyBy<DocumentAttribute>(attributes, getDocumentAttributeId), [attributes]);

  const prepareAttributeValue = useCallback(
    (id: string, attribute: DocumentAttribute, meta: string) => {
      if (!attribute) {
        return t('editor.missing_attribute', { attribute: id });
      }
      return getAttributeDisplayValue(attribute, { displayValue: Boolean(displayAttributeValues), metaData: meta });
    },
    [displayAttributeValues, t]
  );

  const updateAttributesInEditor = useCallback(
    (editor) => {
      editor.model.change((writer: any) => {
        editorWalk(editor.model.document.getRoot(), (el: any) => {
          if (el.hasAttribute('id')) {
            const id = el.getAttribute('id');
            const meta = el.getAttribute('meta');
            const value = prepareAttributeValue(id, indexedAttributes[id], meta);
            writer.setAttribute('value', value, el);
          }
        });
      });
    },
    [prepareAttributeValue, indexedAttributes]
  );
  useEffect(() => {
    if (!ckEditorRef?.current) {
      return;
    }

    updateAttributesInEditor(ckEditorRef.current);
  }, [updateAttributesInEditor, initialData]);

  const updateAttributeDropdownInEditor = useCallback(() => {
    const editor = ckEditorRef.current;
    if (!editor) {
      return;
    }

    editor.fire(ATTRIBUTE_EVENT_FEED_UPDATED, indexedAttributes);
  }, [indexedAttributes]);

  const prevAttributes = useRef(indexedAttributes);
  useEffect(() => {
    const a = Object.keys(prevAttributes.current);
    const b = Object.keys(indexedAttributes);

    const changed = a.length !== b.length || difference(a, b).length > 0;

    prevAttributes.current = indexedAttributes;
    if (changed) {
      updateAttributeDropdownInEditor();
    }
  }, [indexedAttributes, updateAttributeDropdownInEditor]);

  const { membershipId, organizationId } = useOrganization();

  const { adapter } = useCommentsAdapter(reloadDocument);

  const { payload: memberships } = useSuspenseQuery<PartialUserInfo[]>({
    endpoint: `/orgmembership/organization/${organizationId}/user-info`,
    method: 'GET',
    credentials: 'include',
  });

  const language = user?.locale || defaultLanguage;
  const users: CKEditorUser[] = useMemo(
    () =>
      memberships?.map((u) => ({
        id: u.membershipId,
        name: `${u.firstName} ${u.lastName} (${u.userType})`,
      })) || [],
    [memberships]
  );

  const config = useMemo(() => {
    if (!documentStyle || !customFonts) {
      return null;
    }

    const pluginsConfig: Record<string, any> = getPluginsConfig(documentStyle, customFonts);
    return {
      initialData,
      language,
      commentsOnly: readOnly && commentsEnabled,
      licenseKey: process.env.REACT_APP_CKEDITOR_LICENSE_KEY,
      plugins: getPlugins({ commentsEnabled, style: documentStyle }),
      toolbar: getToolbar({ comments: commentsEnabled, trackChanges: enableTrackChangesInToolbar }),
      ...(commentsEnabled
        ? {
          commentsAdapter: {
            adapter,
            users,
            currentUserId: membershipId,
          },
        }
        : {}),
      ...pluginsConfig,
      suggestions: (initialSuggestions || []).map((suggestion) => documentSuggestionToEditorSuggestion(suggestion)),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentStyle,
    customFonts,
    initialData,
    language,
    readOnly,
    commentsEnabled,
    enableTrackChangesInToolbar,
    adapter,
    users,
    membershipId,
    initialSuggestions,
  ]);

  const editorOnInit = useCallback(
    (editor: EditorModel) => {
      attachEditorDebbuger(editor);
      toolbarRef.current!.appendChild(editor.ui.view.toolbar.element);

      if (editorRef) {
        editorRef.current = {
          execute: (...args: [string, any]) => editor.execute(...args),
          getData: (...args) => editor.getData(...args),
          setData: (data) => {
            editor.setData(data);
            updateAttributesInEditor(editor);
          },
          getSuggestions: () => {
            if (!enableTrackChangesInToolbar) {
              return;
            }

            return editor.plugins
              .get('TrackChanges')
              .getSuggestions({ skipNotAttached: true, toJSON: true, skipEmpty: true })
              .map(editorSuggestionToDocumentSuggestion);
          },
        };
      }

      ckEditorRef.current = editor;

      ckEditorRef.current.isReadOnly = readOnly && !commentsEnabled;

      enableTrackChangesInToolbar && editor.commands.get('trackChanges').execute(true);

      editor.fire(ATTRIBUTE_EVENT_FEED_UPDATED, indexedAttributes);

      updateAttributesInEditor(editor);
      editor.model.on('applyOperation', (event: any, operation: any) => {
        onChange();
      });
    },
    [editorRef, readOnly, commentsEnabled, indexedAttributes, updateAttributesInEditor, onChange]
  );

  useEffect(() => {
    if (!loaded && config) {
      setLoaded(true);
    }
  }, [loaded, config]);

  useCKEditorTranslationScript(language);
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.editor} ${classNames.editor}`}>
        <div ref={toolbarRef} className={styles.toolbar}></div>
        <div className={styles.container}>
          <div
            className={styles.editable}
            style={
              documentStyle?.margin
                ? ({
                  '--pactt-document-margin-top': documentStyle.margin.top,
                  '--pactt-document-margin-bottom': documentStyle.margin.bottom,
                  '--pactt-document-margin-left': documentStyle.margin.left,
                  '--pactt-document-margin-right': documentStyle.margin.right,
                } as React.CSSProperties)
                : undefined
            }
          >
            {loaded ? (
              <CKEditor
                config={config}
                editor={DocumentEditor}
                onReady={editorOnInit}
                onChange={(event: any, editor: any) => {
                  updateAttributesInEditor(editor);
                }}
              ></CKEditor>
            ) : (
              <CircularProgress size={48} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
