import React, { FC, useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { Edit } from '@material-ui/icons';

import { AttributeDialog } from '../../../shared/components/CreateAttribute/AttributeDialog';
import { useOrganization } from 'contexts/organization';
import { Attribute } from 'models/documentation/sub-models/attribute/Attribute';
import { useOrganizationUrl } from 'pages/TemplateManagement/hooks/api-helper';
import { defaultAttributes } from 'shared/attributes/defaultAttributes';
import { DeleteAction } from 'shared/components/DeleteButton/DeleteAction';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { useAttributeTranslation } from 'shared/hooks/useAttributeTranslation';
import { useDefaultAttributes } from 'shared/hooks/useDefaultAttributes';

interface AttributesListProps {
  canDelete: boolean;
  canEdit: boolean;
  canCreate: boolean;
}
export const AttributesList: FC<AttributesListProps> = ({ canDelete, canEdit, canCreate }) => {
  const baseUrl = useOrganizationUrl();

  const { t } = useTranslation();
  const { translateAttributeCategory, translateAttributeName } = useAttributeTranslation();

  const { organizationId, membershipId } = useOrganization();
  const attributeUrl = `${baseUrl}/organizationMembership/${membershipId}/document-attribute`;
  const [attributes, reload] = useDefaultAttributes(organizationId, membershipId);

  const { mutate: upsertAttribute } = useMutation(
    ({ body, id }: { body: Omit<Attribute, 'id'>; id: string | null }) => {
      let endpoint = attributeUrl;
      if (id) {
        endpoint += `/${id}`;
      }

      return {
        endpoint,
        method: id ? 'PUT' : 'POST',
        credentials: 'include',
        body,
      };
    }
  );

  const [dialog, setDialog] = useState<{ open: boolean; data: Attribute | null }>({
    open: false,

    data: null,
  });

  const closeDialog = () => {
    setDialog({ open: false, data: null });
  };
  const openCreateDialog = () => {
    setDialog({ open: true, data: null });
  };
  const openUpdateDialog = (data: Attribute) => {
    setDialog({ open: true, data });
  };

  const renderRow = (row: Attribute, isDefault = false) => (
    <TableRow key={row.id}>
      <TableCell component="td" scope="row">
        {translateAttributeCategory(row.category)}
      </TableCell>
      <TableCell component="td" scope="row">
        {translateAttributeName(row)}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.type}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.description}
      </TableCell>
      <TableCell component="td" scope="row">
        {row.value}
      </TableCell>
      <TableCell component="td" scope="row">
        <Tooltip title={t<string>('attributes_section.edit_attribute.tooltip', 'Edit')}>
          <IconButton disabled={isDefault || !canEdit} onClick={() => openUpdateDialog(row)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={t<string>('attributes_section.edit_attribute.tooltip', 'Edit')}>
        <DeleteAction
          disabled={isDefault || !canDelete}
          title={t('organization_attributes.confirm_delete', 'Are you sure to delete {{attribute}} attribute?', {
            attribute: getDocumentAttributeId(row),
          })}
          onComplete={reload}
          endpoint={`${attributeUrl}/${row.id}`}
        />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
  const body = attributes!.map((v) => renderRow(v)).concat(defaultAttributes.map((v) => renderRow(v, true)));

  const onSubmit = async (body: Omit<Attribute, 'id'>) => {
    const values = { body, id: dialog.data?.id || null };
    await upsertAttribute(values);
    closeDialog();
    reload();
  };
  const title = dialog.data
    ? t('organization_attributes.update_attribute_dialog_title', 'Update attribute {{attribute}}', {
        attribute: getDocumentAttributeId(dialog.data),
      })
    : t('organization_attributes.create_attribute_dialog_title', 'Create attribute');
  return (
    <>
      {dialog.open ? (
        <AttributeDialog
          title={title}
          initialData={dialog.data || undefined}
          onSubmit={onSubmit}
          onCancel={closeDialog}
          confirmText={t('organization_attributes.create_attribute_dialog.confirm', 'Save')}
          cancelText={t('organization_attributes.create_attribute_dialog.cancel', 'Cancel')}
        />
      ) : null}

      <TableContainer>
        <Button variant="contained" color="primary" onClick={openCreateDialog} disabled={!canCreate}>
          + {t('organization_attributes.create_attribute_button', 'Create new attribute')}
        </Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('attribute.category', 'Category')}</TableCell>
              <TableCell>{t('attribute.name', 'Name')}</TableCell>
              <TableCell>{t('attribute.type', 'Type')}</TableCell>
              <TableCell>{t('attribute.description', 'Description')}</TableCell>
              <TableCell>{t('attribute.default_value', 'Default Value')}</TableCell>
              <TableCell>{t('organization_attributes.column_actions', 'Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{body}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
