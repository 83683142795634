import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { FC, useRef } from 'react';
import { Action, Client, ClientContextProvider, QueryResponse, createClient } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { API_BASE_URL } from './config';
import { ErrorResponse } from './models';

const resolveEndpoint = (host: string, endpoint: string) => `${host}${endpoint}`;
const requestHostInterceptor = (host: string) => () => async (action: Action) => {
  return {
    ...action,
    endpoint: resolveEndpoint(host, action.endpoint),
  };
};

export const resolveErrorMessage = (t: TFunction, payload: ErrorResponse): string => {
  if (payload.translationKey && payload.translationKey !== '') {
    return t(payload.translationKey, { ...payload.translationParameters, ns: 'backend' });
  }

  if (payload.message) {
    return payload.message;
  }

  return t('common.error.unknown');
};

export const ClientProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const history = useHistory();

  // TODO: Below are the error codes which handling is moved somewhere else and if its not ignored,
  // there will be double snackbar message. This should be eventually fixed by removing this custom lib with general
  // error handling
  const ignoredErrorCodes = ['E_API_0001'];
  const errorResponseInterceptor = (_client: Client) => async (action: Action, response: QueryResponse) => {
    const { error, payload, errorObject, status } = response;

    if (error && !errorObject && !action.skipErrorNotification && ignoredErrorCodes.indexOf(payload.code) === -1) {
      const message = resolveErrorMessage(t, payload);
      snackbar.enqueueSnackbar(message, { variant: 'error' });
      response.errorObject = new Error(message);
    }

    if (status === 401 && !['/', '/login', '/logout'].includes(window.location.pathname)) {
      history.push('/logout');
    }

    return response;
  };

  const client = useRef<Client>(
    createClient({
      requestInterceptors: [requestHostInterceptor(API_BASE_URL)],
      responseInterceptors: [errorResponseInterceptor],
    })
  );

  return <ClientContextProvider client={client.current}>{children}</ClientContextProvider>;
};
