export enum ActionEnum {
  /* USER ACTIONS */
  ADD_USER_DATA_AFTER_LOGIN = 'ADD_USER_DATA_AFTER_LOGIN',
  CLEAR_USER_DATA_AFTER_LOGOUT = 'CLEAR_USER_DATA_AFTER_LOGOUT',
  SYNC_USER_DATA = 'SYNC_USER_DATA',

  /* USER PROFILE ACTIONS */
  SET_ALL_COLUMNS = 'SET_ALL_COLUMNS',
  SET_COLUMNS_TO_DISPLAY = 'SET_COLUMNS_TO_DISPLAY',
  CHANGE_COLUMNS_TO_DISPLAY = 'CHANGE_COLUMNS_TO_DISPLAY',

  /* AGREEMENTS ACTIONS */
  SET_ALL_AGREEMENTS = 'SET_ALL_AGREEMENTS',
  SET_ALL_AGREEMENT_TEMPLATES = 'SET_ALL_AGREEMENT_TEMPLATES',
  UPDATE_ONE_AGREEMENT = 'UPDATE_ONE_AGREEMENT',
  UPDATE_ONE_AGREEMENT_TEMPLATE = 'UPDATE_ONE_AGREEMENT_TEMPLATE',
}
