import { UserType } from 'models/Enums';

export interface InvitationModel {
  id: string;
  tokenId: string;
  email: string;
  userType: UserType;
  organizationId: string;
  organizationName: string;
  createdAt: number;
}

export enum NotificationStatus {
  READ = 'READ',
  UN_READ = 'UN_READ',
}

export enum NotificationKind {
  CREATE_DOCUMENTATION = 'CREATE_DOCUMENTATION',
  REMOVE_DOCUMENTATION = 'REMOVE_DOCUMENTATION',
  EDIT_DOCUMENTATION = 'EDIT_DOCUMENTATION',
  SHARE_DOCUMENTATION = 'SHARE_DOCUMENTATION',
  VIEW_DOCUMENTATION = 'VIEW_DOCUMENTATION',
  SIGN_DOCUMENTATION = 'SIGN_DOCUMENTATION',
  ADD_DOCUMENT_TO_DOCUMENTATION = 'ADD_DOCUMENT_TO_DOCUMENTATION',
  INVITE_TO_ORGANIZATION = 'INVITE_TO_ORGANIZATION',
  CONFIRM_INVITE_TO_ORGANIZATION = 'CONFIRM_INVITE_TO_ORGANIZATION',
  REJECT_INVITE_TO_ORGANIZATION = 'REJECT_INVITE_TO_ORGANIZATION',
  OFFLINE_JOB = 'OFFLINE_JOB',
  CREATE_AMENDMENT = 'CREATE_AMENDMENT',
  BEGIN_SIGNING_PROCESS = 'BEGIN_SIGNING_PROCESS',
  START_FLOW = 'START_FLOW',

  EXPIRING_ORGANIZATION = 'EXPIRING_ORGANIZATION',
  DEMO_ORGANIZATION_CREATED = 'DEMO_ORGANIZATION_CREATED',
  COPY_TEMPLATES_PROCESS_FINISHED = 'COPY_TEMPLATES_PROCESS_FINISHED',
}

export interface Notification {
  additionalParameters: Record<string, string>;
  createdAt: string;
  expiredAt: string | null;
  id: string;
  membershipId: string;
  message: string;
  notificationType: NotificationKind;
  status: NotificationStatus;
  userId: string;
  organizationId: string;
}

export interface InvitationNotification extends Notification {
  additionalParameters: {
    EMAIL: string;
    ORGANIZATION_NAME: string;
    USER_TYPE: string;
    TOKEN_ID: string;
  };
}
