import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { TransformedData } from './graphPayloadTransformator';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          const dataset = context.dataset;
          return [
            "Type: " + dataset.label,
            "Count:\t" + context.formattedValue,
            "Price: \t" + dataset.priceSum[context.dataIndex] + 'zł'];
        }
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

interface BillingsGraphsComponentProps {
  graphsData?: TransformedData
}

export const BillingsGraphsComponent: FC<BillingsGraphsComponentProps> = ({
  graphsData
}) => {

  return (
    <>
      <Box style={{ margin: 'auto', height: "400px", width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {graphsData ? <Bar options={options} data={graphsData} /> : <>Brak danych</>}
      </Box>
    </>
  );
};
