import { i18n as i18nlib } from 'i18next';
import { useTranslation } from 'react-i18next';

import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { getAttributeCategoryTK, getAttributeNameTK } from 'shared/helpers/translationKeys';

const optionalTranslate = (i18n: i18nlib, key: string, defaultValue: string): string =>
  i18n.exists(key) ? i18n.t(key) : defaultValue;

export const translateAttributeId = (i18n: i18nlib, id: string) => {
  try {
    const [category, name] = id.split(':');

    return getDocumentAttributeId({
      category: translateAttributeCategory(i18n, category),
      name: translateAttributeName(i18n, { category, name }),
    });
  } catch {
    return id;
  }
};

const translateAttributeCategory = (i18n: i18nlib, category: string) =>
  optionalTranslate(i18n, getAttributeCategoryTK(category), category);
const translateAttributeName = (i18n: i18nlib, attr: Pick<DocumentAttribute, 'name' | 'category'>) =>
  optionalTranslate(i18n, getAttributeNameTK(attr), attr.name);

export const useAttributeTranslation = () => {
  const { i18n } = useTranslation();

  return {
    translateAttributeName: (attr: Pick<DocumentAttribute, 'name' | 'category'>) => translateAttributeName(i18n, attr),
    translateAttributeCategory: (category: string) => translateAttributeCategory(i18n, category),
    translateAttributeId: (id: string) => translateAttributeId(i18n, id),
  };
};
