import { Dictionary } from 'lodash';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { DocCategory } from 'models/DocCategory';

export const groupDocumentTemplates = <T extends { category: string }>(templates?: T[]): Dictionary<T[]> => {
  if (!templates) {
    return {};
  }
  const order = Object.values(DocCategory) as string[];
  const indexedTemplates = groupBy(templates, 'category');
  const keys = Object.keys(indexedTemplates);

  const orderedKeys = sortBy(keys, (k) => {
    const index = order.indexOf(k);
    return index === -1 ? Infinity : index;
  });

  return fromPairs(map(orderedKeys.map((key) => [key, indexedTemplates[key]])));
};
