import React from 'react';

interface StyledButtonProps {
  onClick?: any;
  type: string;
  className?: string;
  marginLeft?: boolean;
  marginRight?: boolean;
  icon?: JSX.Element;
  text?: string;
  style?: any;
}

const StyledButton = (props: StyledButtonProps): JSX.Element => {
  return (
    <div
      onClick={props.onClick}
      className={`button--${props.type} ${props.className ? props.className : ''} 
      ${props.marginLeft ? 'marginLeft' : ''} 
      ${props.marginRight ? 'marginRight' : ''}`}
    >
      {props.icon}
      <span className={`button--${props.type}__text`}>{props.text}</span>
    </div>
  );
};

export default StyledButton;
