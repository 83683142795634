import React from 'react';

interface Props {
  className?: string;
}

const Loader = (props: Props): JSX.Element => {
  return (
    <div className="loader__wrapper">
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
