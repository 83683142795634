import { Action } from 'react-fetching-library';

interface ActionCreatorParams {
  organizationId: string;
  membershipId: string;
  ids: string[];
}
const createActionCreator =
  (path: string, paramName: string) =>
  ({ ids, membershipId, organizationId }: ActionCreatorParams): Action => {
    const search = new URLSearchParams(ids.map((id) => [paramName, id])).toString();

    return {
      endpoint: `/organization/${organizationId}/organizationMembership/${membershipId}/${path}?${search}`,
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    };
  };

export const documentStylesActionCreator = createActionCreator('styles', 'styleIds');
export const customFontsActionCreator = createActionCreator('fonts', 'ids');
