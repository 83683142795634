import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import { Client } from 'react-fetching-library';

import { getDocumentContentForExport } from './getDocumentContentForExport';
import { convertBlobToBase64 } from 'helpers/base64';
import { DocumentStyle } from 'models/DocumentStyle';
import { DocumentStyleFont } from 'models/DocumentStyleFont';
import { BaseDocument } from 'models/DocumentTemplate';
import { DocumentationDocument } from 'models/documentation/sub-models/document/DocumentationDocument';
import { customFontsActionCreator, documentStylesActionCreator } from 'shared/api/actions';
import { pageFormat } from 'shared/components/DocumentEditor/config';
import { fontsToFontFaceCss } from 'shared/components/DocumentEditor/utils/fontsToFontFaceCss';
import { defaultStyle, getDocumentStyleCss, prepareCustomFonts } from 'shared/helpers/documentStyle';

const exporterDefaultCSS = `
body {
  margin: 0;
  padding: 0;
  font-family: Mukta
}

.ck-content .table table,
.ck-content .table table td,
.ck-content .table table th {
  border-color: transparent;
} 
`;

const exporterDefaultFonts =
  "@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;700&display=swap');";

const getCustomFontsWithDataUrls = async (fonts: DocumentStyleFont[]) => {
  const allFiles = uniq(fonts.flatMap((f) => f.fileLinks));

  const result = await Promise.allSettled(
    allFiles.map((url) =>
      fetch(url)
        .then((f) => f.blob())
        .then(convertBlobToBase64)
        .then((dataUrl) => ({
          dataUrl,
          url,
        }))
    )
  );

  const indexedDataUrls = keyBy(
    result.flatMap((r) => (r.status === 'fulfilled' ? [r.value] : [])),
    'url'
  );

  return fonts.map((f) => ({
    ...f,
    fileLinks: f.fileLinks.map((l) => indexedDataUrls[l]?.dataUrl).filter(Boolean),
  }));
};
export const fetchDocumentPdf = async (
  document: BaseDocument,
  documentStyle: DocumentStyle,
  fonts: DocumentStyleFont[],
  useValues: boolean
) => {
  const contentStyles = await fetch('/css/content-styles.css').then((r) => r.text());

  const fontsForConvert = process.env.NODE_ENV === 'development' ? await getCustomFontsWithDataUrls(fonts) : fonts;
  const fontsCss = fontsToFontFaceCss(fontsForConvert);
  const { margin } = documentStyle;

  const customCss = getDocumentStyleCss(documentStyle);
  const content = await getDocumentContentForExport(document, documentStyle, fonts, useValues);

  const contentWithAttributes = window.document.createElement('div');
  contentWithAttributes.innerHTML = '<div class="ck-content" dir="ltr">' + content + '</div>';

  const documentContentAsHtml = window.document.createElement('template');
  documentContentAsHtml.insertAdjacentElement('afterbegin', contentWithAttributes);
  documentContentAsHtml.querySelectorAll('[data-pactt-attribute-id]').forEach((selectedElement) => {
    if (!selectedElement.innerHTML || selectedElement.innerHTML === '') {
      selectedElement.innerHTML = "..............";
    }
  });

  const htmlWithPopulatedPlaceholders = '<html><head><meta charset="utf-8"></head><body>'
      + contentWithAttributes.innerHTML + '</body></html>';
  return await fetch(process.env.REACT_APP_CKEDITOR_CONVERTER_URL + '/v1/convert/', {
    body: JSON.stringify({
      html: htmlWithPopulatedPlaceholders,
      css: [exporterDefaultFonts + fontsCss, contentStyles, exporterDefaultCSS, customCss].filter(Boolean).join(' '),
      options: {
        format: pageFormat.name,
        margin_top: margin.top,
        margin_bottom: margin.bottom,
        margin_right: margin.right,
        margin_left: margin.left,
        page_orientation: 'portrait',
      },
    }),
    method: 'POST',
    mode: 'cors',
    credentials: 'omit',
  }).then((r) => r.arrayBuffer());
};

export const fetchDocumentPdfWithStyles = async (
  documents: DocumentationDocument[],
  client: Client,
  organizationId: string,
  membershipId: string
) => {
  const { payload: documentStyles } = await client.query<DocumentStyle[]>(
    documentStylesActionCreator({
      organizationId,
      membershipId,
      ids: documents.map((d) => d.styleId).filter(Boolean) as string[],
    })
  );

  const indexedStyles = keyBy(documentStyles, 'id');
  const fontIds = uniq(documentStyles?.flatMap((d) => d.customFonts));

  const customFonts: DocumentStyleFont[] =
    fontIds.length > 0
      ? await client
          .query<DocumentStyleFont[]>(customFontsActionCreator({ organizationId, membershipId, ids: fontIds }))
          .then((r) => prepareCustomFonts(r.payload))
      : [];

  return Promise.all(
    documents.map((d) => {
      const style = (d.styleId && indexedStyles[d.styleId]) || defaultStyle;
      return fetchDocumentPdf(
        d,
        style,
        customFonts.filter((f) => style.customFonts?.includes(f.id)),
        true
      ).then((b) => ({
        blob: b,
        name: d.id,
      }));
    })
  );
};
