import { Attachment } from 'models/Attachment';
import { DocumentationDocument } from 'models/documentation/sub-models/document/DocumentationDocument';

export enum UserType {
  Owner = 'owner',
  Member = 'member',
  Creator = 'creator',
}

export type LeaseRowData = {
  fullId: string;
  leaseID: string;
  leaseStart: string;
  leaseEnd: string;
  paymentSchedule: string;
  rentCharge: string;
  landlord: string;
  tenant: string;
  area: string;
  propertyAddress: string;
  status: string;
  documents: DocumentationDocument[];
  attachments: Attachment[];
};

export type LeaseRowCell = {
  fullId: string;
  leaseID: string;
  leaseStart: string;
  leaseEnd: string;
  paymentSchedule: string;
  rentCharge: string;
  landlord: string;
  tenant: string;
  area: string;
  propertyAddress: string;
  status: string;
  documents: DocumentationDocument[];
  attachments: Attachment[];
};

export enum LeaseRowName {
  FullId = 'fullId',
  LeaseID = 'leaseID',
  Tenant = 'tenant',
  Landlord = 'landlord',
  Area = 'area',
  LeaseStart = 'leaseStart',
  LeaseEnd = 'leaseEnd',
  RentCharge = 'rentCharge',
  PaymentSchedule = 'paymentSchedule',
  PropertyAddress = 'propertyAddress',
  Status = 'status',
}

export type FilterValues = {
  column: string;
  value: string;
};

export type ColumnToDisplay = {
  Header: string;
  accessor?: string | null | undefined;
  width?: number;
};

export enum AttributeType {
  Date = 'date',
  Text = 'text',
  Number = 'number',
  Boolean = 'boolean',
}

export enum AttachmentType {
  Reference = 'Reference Document',
  Document = 'Document',
}
