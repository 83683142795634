import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar, Chip, Divider } from '@material-ui/core';
import { LowPriority } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CheckIcon from '@material-ui/icons/Check';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import DoneIcon from '@material-ui/icons/Done';
import NoteAddRoundedIcon from '@material-ui/icons/NoteAddRounded';
import ShareIcon from '@material-ui/icons/Share';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import { VerySimpleTextHighlighter } from '../../components/VerySimpleTextHighlighter';
import { ReactComponent as View } from '../../images/view.svg';
import { fontSizeNormal, fontSizeSmall, grey } from '../../styles';
import { NotificationDate } from './NotificationDate';
import { Notification, NotificationKind, NotificationStatus } from './model';

interface Props {
  notification: Notification;
  organizationName: string;
  onRead(): void;
  closeMenu(): void;
}

export const GeneralNotificationItem: FC<Props> = (props) => {
  const { t } = useTranslation();

  const notificationWasRead = props.notification.status === NotificationStatus.READ;

  return (
    <div
      style={{
        display: 'flex',
        padding: '10px 20px',
        gap: '10px',
        backgroundColor: notificationWasRead ? 'inherit' : '#fef6f0',
      }}
    >
      <Avatar variant="rounded" style={{ backgroundColor: '#bfaacc' }}>
        {getNotificationIcon(props.notification.notificationType)}
      </Avatar>
      <div style={{ fontSize: fontSizeNormal }}>
        <FormattedMessage notification={props.notification} onLinkClick={props.closeMenu} />
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', fontSize: fontSizeSmall, color: grey }}
        >
          <NotificationDate date={props.notification.createdAt} />
          <Divider style={{ margin: '5px' }} orientation={'vertical'} flexItem={true} variant={'middle'} />
          <div>{props.organizationName}</div>
        </div>
        {!notificationWasRead && (
          <Chip
            variant="outlined"
            size="small"
            component="div"
            icon={<DoneIcon />}
            label={t('notifications.markAsRead', 'Mark as read')}
            onClick={props.onRead}
          />
        )}
      </div>
    </div>
  );
};

// todo this shoulud be removed and we should dynamically build notifications using additionalParameters
const FormattedMessage = ({ notification, onLinkClick }: { notification: Notification; onLinkClick: () => void }) => {
  if (!notification.message.includes(notification.additionalParameters.SHORT_DOCUMENTATION_ID)) {
    return <div>{notification.message}</div>;
  }

  const [message1, message2] = notification.message.split(notification.additionalParameters.SHORT_DOCUMENTATION_ID);

  return (
    <div>
      <VerySimpleTextHighlighter text={message1} textToHighlight={notification.additionalParameters.EMAIL} />
      <Link
        // cra doesn't want to pick up custom eslint config. Not worth the effort, as we're gonna migrate to v5 anyway
        /* eslint-disable-next-line max-len */
        to={`/organization/${notification.organizationId}/${notification.membershipId}/documentation/details/${notification.additionalParameters.DOCUMENTATION_ID}`}
        onClick={onLinkClick}
      >
        {notification.additionalParameters.SHORT_DOCUMENTATION_ID}
      </Link>
      <VerySimpleTextHighlighter text={message2} textToHighlight={notification.additionalParameters.EMAIL} />
    </div>
  );
};

const getNotificationIcon = (notificationKind: string) => {
  switch (notificationKind) {
    case NotificationKind.SIGN_DOCUMENTATION:
      return <CheckIcon style={{ fill: '#6BCBB4' }} />;
    case NotificationKind.BEGIN_SIGNING_PROCESS:
      return <AlarmOnIcon style={{ fill: '#6BCBB4' }} />;
    case NotificationKind.ADD_DOCUMENT_TO_DOCUMENTATION:
      return <AddIcon style={{ fill: '#4F93E2' }} />;
    case NotificationKind.CREATE_AMENDMENT:
      return <AddToPhotosIcon style={{ fill: '#4F93E2' }} />;
    case NotificationKind.CREATE_DOCUMENTATION:
      return <NoteAddRoundedIcon style={{ fill: '#392632' }} />;
    case NotificationKind.REMOVE_DOCUMENTATION:
      return <DeleteOutlinedIcon style={{ fill: '#D1104A' }} />;
    case NotificationKind.EDIT_DOCUMENTATION:
      return <CreateOutlinedIcon style={{ fill: '#636363' }} />;
    case NotificationKind.SHARE_DOCUMENTATION:
      return <ShareIcon style={{ fill: '#4F93E2' }} />;
    case NotificationKind.VIEW_DOCUMENTATION:
      return <View style={{ fill: '#392632' }} />;
    case NotificationKind.REJECT_INVITE_TO_ORGANIZATION:
      return <ThumbDownIcon style={{ fill: '#D1104A' }} />;
    case NotificationKind.CONFIRM_INVITE_TO_ORGANIZATION:
      return <ThumbUpIcon style={{ fill: '#6BCBB4' }} />;
    case NotificationKind.OFFLINE_JOB:
      return <DnsRoundedIcon style={{ fill: '#8161AA' }} />;
    case NotificationKind.START_FLOW:
      return <LowPriority style={{ fill: '#FFA75E' }} />;
    default:
      return <LowPriority style={{ fill: '#FFA75E' }} />;
  }
};
