import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import i18n from 'i18next';

import { customComponentClassRegexp } from '../config';
import { AttributeCommand } from './AttributeCommand';
import {
  ATTRIBUTE_COMMAND_NAME,
  ATTRIBUTE_MODEL_NAME,
  ATTRIBUTE_VIEW_ATTRIBUTE_META_NAME,
  ATTRIBUTE_VIEW_ATTRIBUTE_NAME,
  ATTRIBUTE_VIEW_TOOLTIP_TEXT,
} from './constants';
import { translateAttributeId } from 'shared/hooks/useAttributeTranslation';

export class AttributeEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add(ATTRIBUTE_COMMAND_NAME, new AttributeCommand(this.editor));

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) =>
        viewElement.hasAttribute(ATTRIBUTE_VIEW_ATTRIBUTE_NAME)
      )
    );
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    schema.register(ATTRIBUTE_MODEL_NAME, {
      allowWhere: '$text',
      isInline: true,
      isObject: true,
      allowAttributes: ['id', 'value', 'meta', 'width', 'customComponent'],
      allowAttributesOf: '$text',
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        attributes: {
          [ATTRIBUTE_VIEW_ATTRIBUTE_NAME]: /[\w-]+/,
        },
      },
      model: (viewElement, { writer: modelWriter }) => {
        const id = viewElement.getAttribute(ATTRIBUTE_VIEW_ATTRIBUTE_NAME);
        const meta = viewElement.getAttribute(ATTRIBUTE_VIEW_ATTRIBUTE_META_NAME);

        const attrs = {
          id,
        };
        if (meta) {
          attrs.meta = meta;
        }

        if (viewElement.getStyle('width')) {
          attrs.width = viewElement.getStyle('width');
        }

        if (viewElement.getStyle('font-size')) {
          attrs.fontSize = viewElement.getStyle('font-size');
        }
        if (viewElement.getStyle('font-weight')) {
          attrs.bold = Number(viewElement.getStyle('font-size')) > 500;
        }
        if (viewElement.getStyle('color')) {
          attrs.fontColor = viewElement.getStyle('color');
        }
        if (viewElement.getStyle('background-color')) {
          attrs.fontBackgroundColor = viewElement.getStyle('background-color');
        }
        if (viewElement.getStyle('text-decoration')) {
          attrs.underline = viewElement.getStyle('text-decoration').includes('underline');
          attrs.strikethrough = viewElement.getStyle('text-decoration').includes('line-through');
        }

        const customComponentClassName = Array.from(viewElement.getClassNames()).find((c) =>
          new RegExp(customComponentClassRegexp).test(c)
        );

        if (customComponentClassName) {
          attrs.customComponent = customComponentClassName;
        }

        return modelWriter.createElement(ATTRIBUTE_MODEL_NAME, attrs);
      },
    });

    conversion
      .for('editingDowncast')
      .elementToElement({
        model: ATTRIBUTE_MODEL_NAME,
        view: (modelItem, { writer: viewWriter }) => {
          const widgetElement = this.createAttributeView(modelItem, viewWriter);
          return toWidget(widgetElement, viewWriter);
        },
      })
      .add((dispatcher) =>
        dispatcher.on(`attribute`, (evt, data, { writer, consumable, mapper }) => {
          if (data.item.name !== ATTRIBUTE_MODEL_NAME) {
            return;
          }
          const model = data.item;
          consumable.consume(data.item, evt.name);
          const viewElement = mapper.toViewElement(model);
          writer.remove(writer.createRangeOn(viewElement.getChild(0)));
          this.createAttributeView(model, writer, true, viewElement);
        })
      );

    conversion.for('dataDowncast').elementToElement({
      model: ATTRIBUTE_MODEL_NAME,
      view: (modelItem, { writer: viewWriter, options = { useAttributeValue: false } }) => {
        return this.createAttributeView(modelItem, viewWriter, options.useAttributeValue, undefined, false);
      },
    });
  }

  createAttributeView(modelItem, viewWriter, useValue = true, viewElement = false, keepTooltip = true) {
    const id = modelItem.getAttribute('id');
    const meta = modelItem.getAttribute('meta');
    const customComponent = modelItem.getAttribute('customComponent');

    const attrs = {
      [ATTRIBUTE_VIEW_ATTRIBUTE_NAME]: id,
    };

    if (keepTooltip) {
      const translation = translateAttributeId(i18n, id);
      attrs[ATTRIBUTE_VIEW_TOOLTIP_TEXT] = translation;
    }

    if (meta) {
      attrs[ATTRIBUTE_VIEW_ATTRIBUTE_META_NAME] = meta;
    }
    if (!viewElement) {
      viewElement = viewWriter.createContainerElement('span', attrs);
    }

    [
      ['width'],
      ['fontSize', 'font-size'],
      ['bold', 'font-weight', 700],
      ['fontColor', 'color'],
      ['fontBackgroundColor', 'background-color'],
      ['italic', 'font-style', 'italic'],
    ].forEach(([attr, style = attr, value = modelItem.getAttribute(attr)]) => {
      if (modelItem.getAttribute(attr)) {
        viewWriter.setStyle(style, value, viewElement);
      } else {
        viewWriter.removeStyle(style, viewElement);
      }
    });

    const textDecoration = [['underline'], ['strikethrough', 'line-through']].filter(([e]) =>
      modelItem.getAttribute(e)
    );
    if (textDecoration.length > 0) {
      viewWriter.setStyle('text-decoration', textDecoration.map(([m, v = m]) => v).join(' '), viewElement);
    } else {
      viewWriter.removeStyle('text-decoration', viewElement);
    }

    if (customComponent) {
      const toRemove = Array.from(viewElement.getClassNames()).filter((c) =>
        new RegExp(customComponentClassRegexp).test(c)
      );

      viewWriter.removeClass(toRemove, viewElement);
      viewWriter.addClass(customComponent, viewElement);
    }

    if (useValue) {
      const value = modelItem.getAttribute('value') || '';
      viewWriter.insert(viewWriter.createPositionAt(viewElement, 0), viewWriter.createText(value));
    }
    return viewElement;
  }
}
