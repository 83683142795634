import { useAuth } from 'auth';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@material-ui/core';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';

import { HelpModal } from '../ModalHelp/ModalHelp';
import { AdminIcons } from './AdminIcons';
import { UserIcons } from './UserIcons';
import { OrganizationContextValue } from 'contexts/organization';

interface SidePanelProps {
  organization: OrganizationContextValue;
}

const SidePanel: FC<SidePanelProps> = ({ organization }) => {
  const { user } = useAuth();

  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <nav className="side-panel">
      <div className="side-panel__main-nav">
        <div className="side-panel__divider" />
        {!user.isAdmin && <UserIcons organization={organization} />}
        {user.isAdmin && <AdminIcons />}
      </div>
      <div className="side-panel__bottom-nav">
        <HelpModal />
        {organization && (
          <Tooltip title={t('tutorial', 'Tutorial') || ''} placement="bottom">
            <IconButton
              color="inherit"
              onClick={(): void => organization.setModalTutorialVisible(!organization.showModalTutorial)}
            >
              <LiveHelpRoundedIcon color="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </nav>
  );
};

export default SidePanel;
