// @ts-nocheck
import Command from '@ckeditor/ckeditor5-core/src/command';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import StickyPanelView from '@ckeditor/ckeditor5-ui/src/panel/sticky/stickypanelview';
import first from '@ckeditor/ckeditor5-utils/src/first';
import i18n from 'i18next';

import AllowListStartPlugin from './AllowListStartPlugin';
import { LIST_START } from './constants';

const equal = (a, b, attrs) => attrs.every((attr) => a.getAttribute(attr) === b.getAttribute(attr));
class SetListStartCommand extends Command {
  constructor(editor, attributeKey) {
    super(editor);
    this.attributeKey = attributeKey;
  }

  refresh() {
    const blocks = this.editor.model.document.selection.getSelectedBlocks();

    const { value } = blocks.next();
    const { done } = blocks.next();

    const isEnabled = Boolean(value && done) && value.name === 'listItem';

    this.isEnabled = isEnabled;

    this.value = isEnabled ? value.getAttribute(LIST_START) : undefined;
  }

  execute({ value }) {
    const editor = this.editor;
    const model = editor.model;
    const doc = model.document;

    model.change((writer) => {
      const block = first(doc.selection.getSelectedBlocks());

      let b = block;

      while (b.previousSibling && equal(b, b.previousSibling, ['listIndent', 'listStyle', 'listType'])) {
        b = b.previousSibling;
        writer.removeAttribute(this.attributeKey, b);
      }

      writer.setAttribute(this.attributeKey, value, b);

      while (b.nextSibling && equal(b, b.nextSibling, ['listIndent', 'listStyle', 'listType'])) {
        b = b.nextSibling;
        writer.removeAttribute(this.attributeKey, b);
      }
    });
  }
}

export class SetListStartPlugin extends Plugin {
  requires() {
    return [AllowListStartPlugin];
  }
  init() {
    const { editor } = this;
    const command = new SetListStartCommand(editor, LIST_START);
    editor.ui.componentFactory.add(LIST_START, (locale) => {
      const inputView = new InputTextView(locale);
      const buttonView = new ButtonView(locale);
      const parent = new StickyPanelView();

      buttonView.set({
        label: i18n.t('editor.list_start_plugin.dropdown_label', 'List start'),

        withText: true,
        tooltip: true,
        isToggleable: false,
      });

      buttonView.bind('isEnabled').to(command);
      inputView.bind('value').to(command);
      inputView.bind('isReadOnly').to(command, 'isEnabled', (v) => !v);
      this.listenTo(inputView, 'change:isReadOnly', (_a, _b, c) => {
        if (c) {
          this.hasError = false;
        }
      });

      this.listenTo(inputView, 'input', ({ source }) => {
        const v = source.element.value;
        const isNumber = /^\d+$/.test(v);
        inputView.hasError = !isNumber;
        if (isNumber) {
          inputView.value = v;
        }
      });

      parent.content.add(buttonView);
      parent.content.add(inputView);

      this.listenTo(buttonView, 'execute', () => {
        command.execute({ value: inputView.value });
        editor.editing.view.focus();
      });

      return parent;
    });
  }
}
