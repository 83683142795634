import { AttributeValueStrategy } from '../model';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';

export const booleanStrategy: AttributeValueStrategy = ({ value }: DocumentAttribute, { displayValue, metaData }) => {
  if (displayValue) {
    return value === metaData ? 'X' : '';
  } else {
    return metaData === 'true' ? 'T' : 'F';
  }
};
