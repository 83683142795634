import React, { FC, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { Action, useMutation, useQuery } from 'react-fetching-library';
import { useHistory } from 'react-router';

import { Notification, NotificationStatus } from 'pages/Notifications/model';

interface NotoficationsContextValue {
  numberOfUnreadNotifications: number;
  notifications: Notification[];
  readNotification: (action: any) => void;
  markAllAsRead: () => void;
  isSelected: (id: string) => boolean;
  // isAllSelected: () => boolean;
  handleSelect: (id: string) => void;
  // setNotificationsToRead: (ids: string[]) => void;
  // notificationsToRead: string[];
  reloadNotifications: () => void;
}

const NotificationsContext = createContext<NotoficationsContextValue>({
  numberOfUnreadNotifications: 0,
  notifications: [],
  readNotification: () => () => null,
  markAllAsRead: () => () => null,
  isSelected: () => true,
  // isAllSelected: () => true,
  handleSelect: () => () => null,
  reloadNotifications: () => () => null,
  // setNotificationsToRead: () => () => null,
  // notificationsToRead: []
});

export const NotificationsContextProvider: FC = ({ children }) => {
  const history = useHistory();
  const [notificationsToRead, setNotificationsToRead] = React.useState<string[]>([]);

  const isSelected = (id: string) => notificationsToRead.includes(id);
  const handleSelect = (id: string) => {
    const tempSet = new Set(notificationsToRead);

    if (tempSet.has(id)) {
      tempSet.delete(id);
    } else {
      tempSet.add(id);
    }

    setNotificationsToRead([...tempSet]);
  };

  const { payload, query } = useQuery<any>({
    // TODO: support paging with url parameters like: '/notification/list-user?page=5&pageSize=20',
    endpoint: '/notification/list-user',
    method: 'GET',
    credentials: 'include',
  });

  const readNotificationAction = (notificationId: string): Action => {
    return {
      endpoint: `/notification/${notificationId}`,
      method: 'POST',
      credentials: 'include',
    };
  };

  const readAllCheckedNotificationAction = (notificationIds: string[]): Action => {
    return {
      endpoint: '/notification/list',
      method: 'POST',
      credentials: 'include',
      body: [...notificationIds],
    };
  };

  const { mutate: readNotification } = useMutation<Notification[]>(readNotificationAction);
  const { mutate: readManyNotification } = useMutation<Notification[]>(readAllCheckedNotificationAction);

  useEffect(() => {
    query();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const readNotificationHandler = useCallback(
    (notificationId: string) => {
      readNotification(notificationId).then(query);
    },
    [readNotification, query]
  );

  // const readManyNotificationHandler = useCallback(() => {
  //   readManyNotification(notificationsToRead).then(() => query());
  // }, []);

  const usedNotifications = useMemo(() => {
    return payload && Array.isArray(payload.results)
      ? payload.results.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      : [];
  }, [payload]);

  const unreadNotifications = useMemo(() => {
    return usedNotifications?.filter((notification: any) => notification.status === NotificationStatus.UN_READ);
  }, [usedNotifications]);

  const markAllAsRead = () => readManyNotification(unreadNotifications.map((n: any) => n.id)).then(query);

  // const isAllSelected = () => usedNotifications.length === notificationsToRead.length;

  return (
    <NotificationsContext.Provider
      value={{
        numberOfUnreadNotifications: payload && payload.results
          ? usedNotifications?.filter((notification: any) => notification.status === NotificationStatus.UN_READ).length
          : 0,
        notifications: usedNotifications,
        readNotification: readNotificationHandler,
        isSelected,
        handleSelect,
        // setNotificationsToRead,
        // isAllSelected,
        // notificationsToRead,
        markAllAsRead,
        reloadNotifications: () => query(),
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
