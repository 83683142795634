import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import i18n from 'i18next';
import orderBy from 'lodash/orderBy';

import { ATTRIBUTE_COMMAND_NAME, ATTRIBUTE_EVENT_FEED_UPDATED, ATTRIBUTE_UI_VIEW_NAME } from './constants';
import { AttributeType } from 'models/Enums';
import { getAttributeBooleanTK } from 'shared/helpers/translationKeys';
import { translateAttributeId } from 'shared/hooks/useAttributeTranslation';

export class AttributeUI extends Plugin {
  itemDefinitions = new Collection();
  init() {
    const { editor } = this;

    // The "attribute" dropdown must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add(ATTRIBUTE_UI_VIEW_NAME, (locale) => {
      const dropdownView = createDropdown(locale);

      this.editor.on(ATTRIBUTE_EVENT_FEED_UPDATED, (_evt, attributes) => {
        updateItems(this.itemDefinitions, attributes);
      });

      addListToDropdown(dropdownView, this.itemDefinitions);

      dropdownView.buttonView.set({
        label: i18n.t('editor.attribute_plugin.dropdown_label', 'Attribute'),
        tooltip: true,
        withText: true,
      });

      // Disable the attribute button when the command is disabled.
      const command = editor.commands.get(ATTRIBUTE_COMMAND_NAME);

      dropdownView.bind('isEnabled').to(command, 'isEnabled');

      // Execute the command when the dropdown item is clicked (executed).
      this.listenTo(dropdownView, 'execute', (evt) => {
        editor.execute(ATTRIBUTE_COMMAND_NAME, { value: evt.source.commandParam });
        editor.editing.view.focus();
      });

      return dropdownView;
    });
  }
}

function prepareDefinition(id, attribute, label) {
  return {
    type: 'button',
    model: new Model({
      commandParam: { id, attribute },
      label,
      withText: true,
    }),
  };
}

function updateItems(itemDefinitions, attributes) {
  itemDefinitions.clear();

  for (const [id, attribute] of orderBy(Object.entries(attributes), ['category', 'name'])) {
    const translatedAttribute = translateAttributeId(i18n, id);

    if (attribute.type === AttributeType.Boolean) {
      const attrTrue = { ...attribute, meta: 'true' };
      const attrFalse = { ...attribute, meta: 'false' };

      itemDefinitions.add(
        prepareDefinition(id, attrTrue, `${translatedAttribute} (${i18n.t(getAttributeBooleanTK('true'))})`)
      );
      itemDefinitions.add(
        prepareDefinition(id, attrFalse, `${translatedAttribute} (${i18n.t(getAttributeBooleanTK('false'))})`)
      );
    } else {
      itemDefinitions.add(prepareDefinition(id, attribute, translatedAttribute));
    }
  }

  return itemDefinitions;
}
