import React, { FC, useMemo } from 'react';
import { Action, useQuery } from 'react-fetching-library';
import { useRouteMatch } from 'react-router';

import OrganizationStyleDetailsForm from './components/OrganizationStyleDetailsForm';
import { DocumentStyleFormData } from 'models/DocumentStyle';

const useGetStyleQuery = (id: string) => {
  const action: Action = useMemo(
    (): Action => ({
      endpoint: `/styles/${id}`,
      method: 'GET',
      credentials: 'include',
    }),
    [id]
  );

  return useQuery(action);
};
export const OrganizationStyleDetails: FC = () => {
  const {
    params: { id: organizationId, styleId },
  } = useRouteMatch<{ id: string; styleId: string }>();
  const updateStylesActionCreator = (body: DocumentStyleFormData): Action => ({
    endpoint: `/styles/${styleId}`,
    method: 'PUT',
    credentials: 'include',
    body: {
      ...body,
      organizationId,
    },
  });
  const { payload } = useGetStyleQuery(styleId);

  return payload ? (
    <OrganizationStyleDetailsForm
      title={`Update style: ${payload.name}`}
      data={payload}
      actionCreator={updateStylesActionCreator}
      organizationId={organizationId}
    />
  ) : null;
};

export default OrganizationStyleDetails;
