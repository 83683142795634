import commentsIcon from '@ckeditor/ckeditor5-comments/theme/icons/add-comment.svg';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';

export class CommentUI extends Plugin {
  itemDefinitions = new Collection();
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add('pactt-comment', (locale) => {
      const view = new ButtonView(locale);

      view.set({
        label: 'Add Comment',
        icon: commentsIcon,
        tooltip: true,
      });

      // Callback executed once the image is clicked.
      view.on('execute', () => {
        editor.execute('pactt-addCommentThread');
      });

      return view;
    });
  }
}
