import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardHeader } from '@material-ui/core';

import { AttributesList } from '../components/AttributesList';
import { useOrganization } from 'contexts/organization';
import { OrganizationPermission } from 'user/permissions/model';

const OrganizationAttributes: FC = () => {
  const { hasPermission } = useOrganization();
  const { t } = useTranslation();
  return (
    <Card>
      <CardHeader title={t('attributes_page.title', 'Attributes')}></CardHeader>
      <CardContent>
        <AttributesList
          canEdit={hasPermission(OrganizationPermission.EDIT_ATTRIBUTE)}
          canDelete={hasPermission(OrganizationPermission.DELETE_ATTRIBUTE)}
          canCreate={hasPermission(OrganizationPermission.CREATE_ATTRIBUTE)}
        />
      </CardContent>
    </Card>
  );
};
export default OrganizationAttributes;
