import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import { QueryProvider } from './api/QueryProvider';
import './i18n';
import { Routes } from './router/Routes';
import { ClientProvider } from 'api/ApiClientProvider';
import 'scss/index.scss';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';
import Loader from 'shared/components/Loader/Loader';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Mukta',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#392632',
    },
    secondary: {
      main: '#614455',
    },
    success: {
      main: '#6BCBB4',
    },
    error: {
      main: '#D1104A',
    },
  },
});

const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider preventDuplicate={true}>
        <BrowserRouter>
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>
              <QueryProvider>
                <ClientProvider>
                  <Routes />
                </ClientProvider>
              </QueryProvider>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
