import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useQuery } from 'react-fetching-library';

interface BillingsSummaryPriceProps {
  organizationId: string;
}

interface BillingsOrganizationSummaryView {
  sumCurrentMonthBilling: number;
  sumLastMonthBilling: number;
}

export const BillingsPriceSummaryComponent: FC<BillingsSummaryPriceProps> = ({
  organizationId,
}) => {
  const { t } = useTranslation();

  const { payload, loading, error } = useQuery<BillingsOrganizationSummaryView>({
    endpoint: `/organization/signing/${organizationId}`,
    method: 'GET',
    credentials: 'include'
  });

  const summaryStyles = {
    fontSize: "18px",
    fontWeight: 700,
  };
  const amountStyles = {
    fontSize: "14px",
    fontWeight: 700,
    color: "#888888",
  };

  if (loading || error) {
    return <></>;
  }

  return (
    <Box style={{ display: "flex", flexWrap: 'wrap', flexDirection: "column", width: 200 }}>
      <Box style={{ marginBottom: "16px" }}>
        <Typography style={summaryStyles}>
          <Box component="span">{t("org_configuration.billings.pricing.current.label")}</Box>
        </Typography>
        <Typography style={{ ...amountStyles, marginTop: "8px" }}>
          <Box component="span">{payload?.sumCurrentMonthBilling ?? 0} PLN</Box>
        </Typography>
      </Box>
      <Box style={{ marginBottom: "16px" }}>
        <Typography style={summaryStyles}>
          <Box component="span">{t("org_configuration.billings.pricing.last.label")}</Box>
        </Typography>
        <Typography style={{ ...amountStyles, marginTop: "8px" }}>
          <Box component="span">{payload?.sumLastMonthBilling ?? 0} PLN</Box>
        </Typography>
      </Box>
    </Box>
  );
};