import { UserType } from 'models/Enums';

export enum UserStatus {
  ACTIVE = 'active',
  AWAITING = 'awaiting',
}
export interface OrganizationUser {
  email: string;
  membershipId: string;
  tokenId: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  userType: UserType;
  userStatus: UserStatus;
}
