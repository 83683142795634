import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import Yup from 'libs/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'api/useMutation';
import { Action, useQuery } from 'react-fetching-library';
import { LoadingButton } from 'components/LoadingButton';
import { da } from 'date-fns/locale';

interface BillingsPricingDialogProps {
  organizationId: string;
}

interface BillingsOrganizationPriceDTO {
  autentiBasicPrice: number;
  signiusEasyPrice: number;
}

const savePricingAction = (organizationId: string) =>
  (body: BillingsOrganizationPriceDTO): Action => ({
    method: 'POST',
    endpoint: `/organization/signing/pricing/${organizationId}`,
    credentials: 'include',
    body,
  });

const validationSchema = Yup.object().shape({
  autentiBasicPrice: Yup.string()
    .required('common.validation_error.required')
    .matches(/^\d+(\.\d{1,2})?$/, 'common.validation_error.wrong_format'),
  signiusEasyPrice: Yup.string()
    .required('common.validation_error.required')
    .matches(/^\d+(\.\d{1,2})?$/, 'common.validation_error.wrong_format'),
});


export const BillingsPriceDialog: FC<BillingsPricingDialogProps> = ({
  organizationId,
}) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [opened, setOpened] = useState(false);

  const { control, errors, setValue, handleSubmit } = useForm<BillingsOrganizationPriceDTO>({
    defaultValues: {
      autentiBasicPrice: 0,
      signiusEasyPrice: 0,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit'
  });

  const { payload, query } = useQuery<BillingsOrganizationPriceDTO>({
    endpoint: `/organization/signing/pricing/${organizationId}`,
    method: 'GET',
    credentials: 'include'
  });

  useEffect(() => {
    if (payload && opened) {
      resetForm(payload);
    }
  }, [opened]);

  const resetForm = (data: BillingsOrganizationPriceDTO) => {
    setTimeout(() => {
      setValue('autentiBasicPrice', data?.autentiBasicPrice ?? 0);
      setValue('signiusEasyPrice', data?.signiusEasyPrice ?? 0);
    });
  };

  const { mutate } = useMutation(savePricingAction(organizationId));

  const saveBillingPricing = () => {
    handleSubmit((data) => {
      mutate(data).then(({ payload, status: status }) => {
        if (status === 200) {
          query();
          setOpened(false);
          snackbar.enqueueSnackbar(t("org_configuration.billings.pricing.success"), { variant: 'success' });
        } else {
          snackbar.enqueueSnackbar(payload?.message ?? "Internal error", { variant: 'error' });
        }
      });
    }, (err) => console.error(err))();
  };


  return (
    <Box style={{ display: 'flex', width: 'auto', flexDirection: 'column' }}>
      <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
        <Button onClick={() => setOpened(true)} color="secondary" style={{ marginLeft: '8px' }}>
          {t('org_configuration.billings.pricing.change.label')}
        </Button>
      </Box>
      <Dialog
        open={opened}
        fullWidth={true}
        maxWidth="xs"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={() => setOpened(false)}
      >
        <DialogTitle>
          <span>{t('export.agreement.csv.title')}</span>
        </DialogTitle>

        <Typography component="p" color="secondary" style={{ marginLeft: '20px', marginBottom: '10px' }}>
          {t("export.agreement.csv.description")}
        </Typography>

        <DialogContent>
          <Card>
            <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Typography>{t('org_configuration.billings.pricing.autentiData.label')}</Typography>
              <Controller
                name="autentiBasicPrice"
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label={t("org_configuration.billings.pricing.autentiData.basicPrice")}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors?.autentiBasicPrice)}
                    helperText={t(errors?.autentiBasicPrice?.message ?? "")}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    variant="standard"
                    fullWidth={true}
                  />
                )}
              />
              <Typography>{t('org_configuration.billings.pricing.signiusData.label')}</Typography>
              <Controller
                name="signiusEasyPrice"
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label={t("org_configuration.billings.pricing.signiusData.easyPrice")}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors?.signiusEasyPrice)}
                    helperText={t(errors?.signiusEasyPrice?.message ?? "")}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    value={value}
                    variant="standard"
                    fullWidth={true}
                  />
                )}
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" color="primary" onClick={() => {
            saveBillingPricing();
          }}>
            {t('export.agreement.csv.action')}
          </LoadingButton>
          <Button variant="outlined" onClick={() => setOpened(false)}>
            {t('export.agreement.csv.exit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
