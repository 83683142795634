import React, { FC } from 'react';
import { generatePath } from 'react-router';

import DocumentationCreateNew from '../components/DocumentationCreateNew';
import { useCreateAmendmentQueryParams } from '../hooks';
import { useOrganization } from 'contexts/organization';
import { Paths } from 'router/paths';

const DocumentationCreateNewPage: FC = () => {
  const { isAmendment, params: amendmentParams } = useCreateAmendmentQueryParams();
  const { organizationId, membershipId } = useOrganization();

  const onConfirm = (documentationId: string) => {
    const path = isAmendment
      ? generatePath(Paths.DOCUMENTATION_AMENDMENT_DETAILS, {
          organizationId,
          membershipId,
          documentationId: amendmentParams.rootDocumentationId!,
          amendmentId: documentationId,
        })
      : generatePath(Paths.DOCUMENTATION_DETAILS, {
          organizationId,
          membershipId,
          documentationId,
        });

    return path;
  };

  return (
    <DocumentationCreateNew
      parentDocumentationId={amendmentParams.parentDocumentationId}
      isAmendment={isAmendment}
      getOnConfirmRedirectPath={onConfirm}
    />
  );
};

export default DocumentationCreateNewPage;
