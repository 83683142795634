import { FC } from 'react';
import React from 'react';
import { Action, useQuery } from 'react-fetching-library';

import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { ConfirmButton, ConfirmButtonProps } from '../Confirm/ConfirmButton';

interface DeleteActionProps {
  endpoint: string;
  method?: Action['method'];
  onComplete: () => void;
  title: string;
  disabled?: boolean;
  button?: React.ComponentType;
  buttonProps?: ConfirmButtonProps<any>['buttonProps'];
}

export const DeleteAction: FC<DeleteActionProps> = ({
  endpoint,
  onComplete,
  method = 'DELETE',
  title,
  disabled,
  button = IconButton,
  children = <DeleteIcon />,
  buttonProps,
}) => {
  const { query } = useQuery(
    {
      endpoint,
      method,
      credentials: 'include',
    },
    false
  );

  const onDeleteConfirm = () => {
    query().then(() => {
      onComplete();
    });
  };

  return (
    <ConfirmButton
      onConfirm={() => onDeleteConfirm()}
      title={title}
      button={button}
      buttonProps={{
        color: 'primary',
        disabled,
        ...buttonProps,
      }}
    >
      {children}
    </ConfirmButton>
  );
};
