import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Backdrop, Box, Button, Fade, IconButton, Modal, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';

export const HelpModal = () => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Tooltip title={t('navigation_bar.help', 'Help') || ''} placement="bottom">
        <IconButton color="inherit" onClick={() => setOpened(true)}>
          <ReportIcon color="inherit" />
        </IconButton>
      </Tooltip>
      <Modal
        open={opened}
        onClose={() => setOpened(false)}
        className="modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opened}>
          <Box
            p={6}
            style={{
              borderRadius: '5px',
              width: '583px',
              height: '200px',
              backgroundColor: '#f9f7f5',
              position: 'relative',
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <CloseIcon className="modal__close" color="inherit" onClick={() => setOpened(false)} />
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <p className="modalHelp__title">{t('help_modal.help', 'Help')}</p>
              <Box display="flex" justifyContent="space-between" alignSelf="center" width="40%" m={4}>
                <Typography component="strong">{t('help_modal.phone_number', 'Phone number')}</Typography>
                <Typography component="span">+48 796 660 344</Typography>
              </Box>
              <a
                href="mailto:support@pactt-technology.com"
                style={{ display: 'block', textDecoration: 'none', alignSelf: 'center' }}
              >
                <Button variant="contained" color="primary">
                  {t('help_modal.report_problem', 'Report problem')}
                </Button>
              </a>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
