import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { ReactComponent as ArrowDown } from 'images/arrowdown.svg';
import { ReactComponent as ArrowRight } from 'images/arrowright.svg';
import { SigningOrganizationGraphInput } from './BillingsSummaryComponent';
import { capitalize } from 'lodash';

type SigningGraphPeriodType = "DAY" | "MONTH";
const graphPeriods: Array<SigningGraphPeriodType> = ["DAY", "MONTH"];

interface BillingsGraphsSearchComponentProps {
  form: UseFormMethods<SigningOrganizationGraphInput>
  searchGraph: Function
}

const calculateMinDate = (input: string, toDate: Date): string => {
  const minDate = new Date();
  if (input === 'MONTH') {
    minDate.setFullYear(new Date(toDate)?.getFullYear() - 2 ?? minDate.getFullYear() - 2);
  } else {
    minDate.setMonth(new Date(toDate)?.getMonth() - 6 ?? minDate.getMonth() - 6);
  }
  return minDate.toLocaleDateString('en-CA');
};

export const BillingsGraphsSearchComponent: FC<BillingsGraphsSearchComponentProps> = ({
  form,
  searchGraph
}) => {
  const { t } = useTranslation();

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { control, errors } = form;

  const toDate = form.watch('to');
  const graphPeriod = form.watch('graphPeriod');
  const minDate = calculateMinDate(graphPeriod, toDate);

  return (
    <>
      <Box>
        <Accordion expanded={isSearchOpen}>
          <AccordionSummary onClick={() => setIsSearchOpen(!isSearchOpen)}>
            <Box style={{ display: 'flex', justifyContent: 'start' }}>
              <Typography color="textSecondary">
                {t('org_configuration.billings.serach.label')}
              </Typography>
              {isSearchOpen ? <ArrowDown style={{ fill: 'gray' }} /> : <ArrowRight style={{ fill: 'gray' }} />}
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
            <Box style={{ display: 'flex', justifyContent: 'start' }}>
              <Controller
                name="from"
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label={t("common.from.label")}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: minDate, max: new Date().toLocaleDateString('en-CA') } }}
                    error={Boolean(errors?.from)}
                    helperText={errors?.from?.message}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    onBlur={onBlur}
                    value={value}
                    variant="standard"
                    fullWidth={true}
                    style={{ maxWidth: "120px", marginRight: "15px" }}
                  />
                )}
              />
              <Controller
                name="to"
                control={control}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    label={t("common.to.label")}
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: minDate, max: new Date().toLocaleDateString('en-CA') } }}
                    error={Boolean(errors?.to)}
                    helperText={errors?.to?.message}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    onBlur={onBlur}
                    value={value}
                    variant="standard"
                    fullWidth={true}
                    style={{ maxWidth: "120px", marginRight: "15px" }}
                  />
                )}
              />
              <Controller
                name="graphPeriod"
                control={control}
                render={({ onChange, value }) => (
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => onChange(newValue)}
                    options={graphPeriods ?? []}
                    getOptionLabel={(value) => capitalize(value)}
                    disableClearable={true}
                    style={{ marginRight: "30px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("org_configuration.billings.serach.periodType")}
                        placeholder={t('org_configuration.billings.graphs.period')}
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors?.graphPeriod)}
                        helperText={errors?.graphPeriod?.message}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        value={value}
                        variant="standard"
                        fullWidth={true}
                        style={{ maxWidth: "120px", marginRight: "15px" }}
                      />
                    )}
                  />
                )}
              />
              <Box style={{ width: "100%", display: 'flex', justifyContent: 'end' }}>
                <Button variant="contained" color="primary" onClick={() => searchGraph()}>
                  {t('org_configuration.billings.graphs.find')}
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
