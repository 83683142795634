import * as Yup from 'yup';

Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (!Array.isArray(this.parent)) {
      return true;
    }

    if (this.parent.filter((v) => v !== value).some((v) => v[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      });
    }

    return true;
  });
});

export const oneOfEnum = <T>(enumObject: { [s: string]: T } | ArrayLike<T>) =>
  Yup.mixed<T>().oneOf(Object.values(enumObject));

export * from 'yup';
export default Yup;
