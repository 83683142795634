import { UserType } from 'models/Enums';

export enum DocumentationPermission {
  VIEW = 'view',
  COMMENT = 'comment',
  EDIT = 'edit',
  SIGN = 'sign',
  FILL_UP_FORM = 'fill_up_form',
  ADD_ATTACHMENT_REF_DOCUMENT = 'add_attachment_ref_document',
}

export enum DocumentationRole {
  VIEWER = 'viewer',
  EDITOR = 'editor',
  SIGNER = 'signer',
  FORM_EDITOR = 'form_editor',
}
export enum DocumentationFlowRole {
  VIEWER = 'viewer',
  EDITOR = 'editor',
  SIGNER = 'signer',
  REVIEWER = 'reviewer',
  FORM_EDITOR = 'form_editor',
}

export enum OrganizationPermission {
  CREATE_ATTRIBUTE = 'create_attribute',
  EDIT_ATTRIBUTE = 'edit_attribute',
  DELETE_ATTRIBUTE = 'delete_attribute',
  CREATE_DOCUMENT_TEMPLATE = 'create_document_template',
  MODIFY_DOCUMENT_TEMPLATE = 'modify_document_template',
  MODIFY_OWN_DOCUMENT_TEMPLATE = 'modify_own_document_template',
  DELETE_DOCUMENT_TEMPLATE = 'delete_document_template',
  DELETE_OWN_DOCUMENT_TEMPLATE = 'delete_own_document_template',
  CREATE_DOCUMENTATION = 'create_documentation',
  DELETE_DOCUMENTATION = 'delete_documentation',
  DELETE_OWN_DOCUMENTATION = 'delete_own_documentation',
  CREATE_DOCUMENTATION_TEMPLATE = 'create_documentation_template',
  MODIFY_DOCUMENTATION_TEMPLATE = 'modify_documentation_template',
  MODIFY_OWN_DOCUMENTATION_TEMPLATE = 'modify_own_documentation_template',
  DELETE_DOCUMENTATION_TEMPLATE = 'delete_documentation_template',
  DELETE_OWN_DOCUMENTATION_TEMPLATE = 'delete_own_documentation_template',
  INVITE_MEMBER = 'invite_member',
  DELETE_MEMBER = 'delete_member',
  INVITE_CREATOR = 'invite_creator',
  DELETE_CREATOR = 'delete_creator',
  INVITE_CO_OWNER = 'invite_co_owner',
  DELETE_CO_OWNER = 'delete_co_owner',
  INVITE_OWNER = 'invite_owner',
  DELETE_OWNER = 'delete_owner',
  SHARE_DOCUMENTATION = 'share_documentation',
  GLOBAL_VIEW = 'global_view',
}

export enum MassActionsPermission {
  DISPLAY_MASS_ACTIONS = 'display_mass_actions',
  DISPLAY_OWN_MASS_ACTIONS = 'display_own_mass_actions',
  DELETE_MASS_ACTION = 'delete_mass_action',
  DELETE_OWN_MASS_ACTION = 'delete_own_mass_action',
  RUN_MASS_LEGAL_AGREEMENT_CREATION = 'run_mass_legal_agreement_creation',
}

export interface AppPermissions {
  documentationPermissions: Record<DocumentationRole | DocumentationFlowRole, DocumentationPermission[]>;
  organizationPermissions: Record<UserType, OrganizationPermission[]>;
  massActionsPermissions: Record<UserType, MassActionsPermission>;
}
