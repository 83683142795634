import { loggedInHandler, logoutHandler } from 'login-actions';
import React, { useCallback, useContext, useEffect } from 'react';
import { FC, createContext, useState } from 'react';
import { Action } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'api/useQuery';

export interface CurrentUser {
  readonly userId: string;
  readonly isAdmin: boolean;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly locale: string;
  readonly status: string;
}

interface AuthState {
  user: CurrentUser | null;
  isLoggedIn: boolean;
}

interface AuthContextValue {
  isLoggedIn: boolean;
  logout: () => void;
  refresh: () => void;
  user: CurrentUser | null;
}
export const AuthContext = createContext<AuthContextValue>({
  isLoggedIn: false,
  logout: () => null,
  refresh: () => null,
  user: null,
});

export const currentUserAction: Action = {
  endpoint: '/user/me',
  method: 'GET',
  credentials: 'include',
  skipErrorNotification: true,
};

export const AuthProvider: FC = ({ children }) => {
  const { query } = useQuery<CurrentUser>(currentUserAction, false);
  const [state, setState] = useState<AuthState | null>(null);

  const { i18n } = useTranslation();
  const logout = useCallback(() => {
    setState({
      isLoggedIn: false,
      user: null,
    });
    logoutHandler();
  }, []);

  useEffect(() => {
    const locale = state?.user?.locale;
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [i18n, state]);

  const getCurrentUser = useCallback(() => {
    query().then(({ payload, status }) => {
      if (status === 200) {
        setState({
          isLoggedIn: true,
          user: payload!,
        });
        loggedInHandler(`${payload!.firstName} ${payload!.lastName}`, payload!.email);
      } else {
        logout();
      }
    });
  }, [query, logout]);

  useEffect(() => {
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn: Boolean(state?.isLoggedIn), logout, refresh: getCurrentUser, user: state?.user! }}
    >
      {state === null ? null : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
