/* eslint-disable react/jsx-max-depth */
import { AuthProvider, useAuth } from 'auth';
import isArray from 'lodash/isArray';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import UserDashboard from '../pages/Dashboard/containers/UserDashboard';
import { GuestExternalShareAgreement } from '../pages/ExternalShareAgreement/GuestExternalShareAgreement';
import { PrivateRoute, PublicRoute } from './Route';
import { Paths } from './paths';
import { OrganizationContextProvider, useOrganization } from 'contexts/organization';
import OrganizationDetails from 'pages/Admin/pages/Organization/containers/OrganizationDetails';
import OrganizationStyleCreate from 'pages/Admin/pages/Organization/containers/OrganizationStyleCreate';
import OrganizationStyleDetails from 'pages/Admin/pages/Organization/containers/OrganizationStyleDetails';
import OrganizationStyleFontCreate from 'pages/Admin/pages/Organization/containers/OrganizationStyleFontCreate';
import OrganizationStyleFontDetails from 'pages/Admin/pages/Organization/containers/OrganizationStyleFontDetails';
import DocumentationCreateFromTemplate from 'pages/Documentation/containers/DocumentationCreateFromTemplate';
import DocumentationCreateNew from 'pages/Documentation/containers/DocumentationCreateNewPage';
import { useIsOwnerGroup } from 'pages/Documentation/hooks';
import OrganizationAttributes from 'pages/Organization/containers/OrganizationAttributes';
import { OrganizationRedirectToMembership } from 'pages/OrganizationRedirectToMembership/OrganizationRedirectToMembership';
import OrganizationUsers from 'pages/OrganizationUsers/containers/OrganizationUsers';
import { ShareDocumentationsLink } from 'pages/ShareLink/ShareDocumentationsLink';
import { DefaultLayout } from 'shared/layouts/DefaultLayout';
import { paths } from 'shared/paths';
import { PermissionsProvider } from 'user/permissions/PermissionsContext';
import { OrganizationPermission } from 'user/permissions/model';

const MemberSettings = React.lazy(() => import('pages/MemberSettings/MemberSettings'));

const DocumentationList = React.lazy(() => import('../pages/Documentation/components/DocumentationList'));
const DocumentationPage = React.lazy(() => import('../pages/Documentation/containers/DocumentationPage'));
const DocumentationDocumentDetails = React.lazy(
  () => import('../pages/Documentation/containers/DocumentationDocumentDetails')
);

const documentTemplateEditor = React.lazy(() => import('../pages/DocumentTemplate/DocumentTemplatePage'));
const leaseManagementView = React.lazy(() => import('../pages/LeaseManagement/containers/ManagementContainer'));
const documentTemplateManagementView = React.lazy(
  () => import('../pages/TemplateManagement/containers/DocumentTemplateManagement/DocumentTemplateManagement')
);
const agreementTemplateManagementView = React.lazy(
  () => import('../pages/TemplateManagement/containers/AgreementTemplateManagement/AgreementTemplateManagement')
);

const documentationTemplateCreator = React.lazy(
  () => import('../pages/DocumentationTemplateCreator/containers/DocumentationCreator')
);

const documentationTemplateEditor = React.lazy(
  () => import('../pages/DocumentationTemplateEditor/containers/DocumentationTemplateEditor')
);
const draftingAgreement = React.lazy(() => import('../pages/DraftingAgreement/containers/DraftingAgreement'));
const draftingAgreementTemplate = React.lazy(
  () => import('../pages/DraftingAgreementTemplate/containers/DraftingAgreementTemplate')
);
const documentationCreate = React.lazy(() => import('../pages/Documentation/containers/DocumentationCreatePage'));
const documentationCreateAmendment = React.lazy(
  () => import('../pages/Documentation/containers/DocumentationCreateAmendment')
);

const profile = React.lazy(() => import('../pages/Profile/containers/Profile/Profile'));
const settings = React.lazy(() => import('../pages/Settings/containers/Settings'));

const registration = React.lazy(() => import('../pages/Registration/containers/Registration'));
const login = React.lazy(() => import('../pages/Login/containers/Login'));
const registrationCompany = React.lazy(() => import('../pages/RegistrationCompany/RegistrationCompany'));
const passwordForgot = React.lazy(() => import('../pages/Password/containers/PasswordForgot'));
const PasswordReset = React.lazy(() => import('../pages/PasswordReset/containers/PasswordReset'));
const inviteTenant = React.lazy(() => import('../pages/InviteTenant/containers/InviteTenant'));
const logout = React.lazy(() => import('../pages/Login/containers/Logout'));
const createOrganization = React.lazy(() => import('../pages/Admin/pages/Organization/containers/CreateOrganization'));
const updateOrganization = React.lazy(() => import('../pages/Admin/pages/Organization/containers/UpdateOrganization'));
const organizations = React.lazy(() => import('../pages/Admin/pages/Organization/containers/Organizations'));
const globalConfig = React.lazy(() => import('../pages/Admin/pages/GlobalConfig/containers/GlobalConfig'));
const users = React.lazy(() => import('../pages/Admin/pages/User/containers/Users'));

const MassActions = React.lazy(() => import('../pages/MassActions/containers/MassActions'));

const adminRoutes = (
  <>
    <Route>
      <DefaultLayout>
        <Switch>
          <Redirect exact from="/" to={Paths.ADMIN_ORGANIZATIONS} />
          <Route exact path={Paths.ADMIN_MASS_ACTION} component={MassActions} />
          <Route exact path={Paths.ADMIN_ORGANIZATIONS} component={organizations} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_DETAILS} component={OrganizationDetails} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_CREATE} component={createOrganization} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_UPDATE} component={updateOrganization} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_STYLE_DETAILS} component={OrganizationStyleDetails} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_STYLE_CREATE} component={OrganizationStyleCreate} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_STYLE_FONT_DETAILS} component={OrganizationStyleFontDetails} />
          <Route exact path={Paths.ADMIN_ORGANIZATION_STYLE_FONT_CREATE} component={OrganizationStyleFontCreate} />
          <Route exact path={Paths.ADMIN_USERS} component={users} />
          <Route exact path={Paths.ADMIN_GLOBAL_CONFIG} component={globalConfig} />
        </Switch>
      </DefaultLayout>
    </Route>
  </>
);

interface OrganizationRouteProps extends RouteProps {
  permission: OrganizationPermission | OrganizationPermission[];
}

const OrganizationRoute: FC<OrganizationRouteProps> = ({ permission, ...routeProps }) => {
  const { hasPermission } = useOrganization();

  const permissions = isArray(permission) ? permission : [permission];

  return permissions.some(hasPermission) ? <Route {...routeProps} /> : null;
};

const OrganizationRoutes = () => {
  const isGroupOwner = useIsOwnerGroup();

  const sharedRoutes = (
    <>
      <Route
        exact
        path={[Paths.DOCUMENTATION_DETAILS, Paths.DOCUMENTATION_AMENDMENT_DETAILS]}
        component={DocumentationPage}
      />
      <Route exact path={Paths.DOCUMENTATION_DOCUMENT} component={DocumentationDocumentDetails} />
      <Route exact path={Paths.MEMBER_SETTINGS} component={MemberSettings} />
    </>
  );

  const memberRoutes = (
    <>
      <Route
        exact
        path={['/organization/:organizationId/:membershipId', Paths.DOCUMENTATION_LIST]}
        component={DocumentationList}
      />
    </>
  );
  const ownerRoutes = (
    <>
      <Switch>
        <Route exact path={Paths.DOCUMENTATION_LIST} component={DocumentationList} />
        <Route exact path={Paths.MASS_ACTIONS} component={MassActions} />
        <Redirect exact from={Paths.ORGANIZATION} to={Paths.DOCUMENTATION_LIST} />
        <Route exact path={Paths.ORGANIZATION_ATTRIBUTES} component={OrganizationAttributes} />
        <Route exact path={Paths.ORGANIZATION_USERS} component={OrganizationUsers} />
        <Route exact path={Paths.DOCUMENT_TEMPLATES} component={documentTemplateManagementView} />
        <Route exact path={Paths.AGREEMENT_TEMPLATES} component={agreementTemplateManagementView} />
        <Route
          exact
          path="/organization/:organizationId/:membershipId/document-template/:documentTemplateId"
          component={documentTemplateEditor}
        />
        <OrganizationRoute
          permission={OrganizationPermission.CREATE_DOCUMENTATION_TEMPLATE}
          exact
          path="/organization/:organizationId/:membershipId/documentation-template/create"
          component={documentationTemplateCreator}
        />

        <Route exact path={Paths.DOCUMENTATION_TEMPLATE_EDIT} component={documentationTemplateEditor} />
        <Route
          exact
          path={['/organization/:organizationId/:membershipId', Paths.MASS_ACTIONS]}
          component={MassActions}
        />
        <OrganizationRoute
          permission={OrganizationPermission.CREATE_DOCUMENTATION}
          exact
          path={Paths.DOCUMENTATION_CREATE}
          component={documentationCreate}
        />
        <OrganizationRoute
          permission={OrganizationPermission.CREATE_DOCUMENTATION}
          exact
          path={[Paths.DOCUMENTATION_CREATE_AMENDMENT_FROM_TEMPLATE, Paths.DOCUMENTATION_CREATE_FROM_TEMPLATE]}
          component={DocumentationCreateFromTemplate}
        />
        <OrganizationRoute
          permission={OrganizationPermission.CREATE_DOCUMENTATION}
          exact
          path={[Paths.DOCUMENTATION_CREATE_AMENDMENT_NEW, Paths.DOCUMENTATION_CREATE_NEW]}
          component={DocumentationCreateNew}
        />

        <OrganizationRoute
          permission={OrganizationPermission.CREATE_DOCUMENTATION}
          exact
          path={Paths.DOCUMENTATION_CREATE_AMENDMENT}
          component={documentationCreateAmendment}
        />
      </Switch>
    </>
  );
  return (
    <>
      {isGroupOwner ? ownerRoutes : memberRoutes}
      {sharedRoutes}
    </>
  );
};

const userRoutes = (
  <>
    <Switch>
      <Route path={Paths.ORGANIZATION_USERTYPE}>
        <OrganizationRedirectToMembership />
      </Route>
      <Route path="/organization/:organizationId/:membershipId">
        <OrganizationContextProvider>
          <DefaultLayout>
            <OrganizationRoutes />
          </DefaultLayout>
        </OrganizationContextProvider>
      </Route>
      <Route>
        <DefaultLayout>
          <Route exact path={Paths.USER_DASHBOARD} component={UserDashboard} />
          <Route exact path="/invite-tenant" component={inviteTenant} />
          <Route exact path="/lease-management" component={leaseManagementView} />
          <Route exact path="/profile" component={profile} />
          <Route exact path="/settings" component={settings} />
          <Route exact path={paths.agreementDraft} component={draftingAgreement} />
          <Route exact path={paths.agreementTemplateDraft} component={draftingAgreementTemplate} />
        </DefaultLayout>
      </Route>
    </Switch>
  </>
);

const ProtectedRoutes: FC = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <PublicRoute restricted exact path="/login" component={login} />
      <PublicRoute restricted exact path="/demo" component={registrationCompany} />
      <PrivateRoute>
        {user?.isAdmin && adminRoutes}
        {user && !user.isAdmin && userRoutes}

        <Route exact path="/logout" component={logout} />
      </PrivateRoute>
    </Switch>
  );
};
export const Routes = () => (
  <Switch>
    <Route
      path="/external-sharing/"
      component={() => {
        let be_url = window.location.href;
        var newurl = be_url.replace('://', '://api.');
        window.location.href = newurl;
        return null;
      }}
    />
    <PublicRoute path="/share-link/documentation">
      <ShareDocumentationsLink />
    </PublicRoute>
    <Route exact path="/guest/share/external/agreement" component={GuestExternalShareAgreement} />
    <Route exact path="/password/forgot" component={passwordForgot} />
    <Route exact path="/reset-password/:token">
      <PasswordReset endpoint="/credentials/reset-password" passwordField="newPassword" />
    </Route>
    <Route exact restricted path={Paths.INVITE_USER_ACCEPT_TOKEN}>
      <PasswordReset shouldAcceptConditions endpoint="/user/invite/confirm" />
    </Route>
    <Route exact path="/registration" component={registration} />
    <AuthProvider>
      <PermissionsProvider>
        <ProtectedRoutes />
      </PermissionsProvider>
    </AuthProvider>
  </Switch>
);
