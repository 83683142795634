import { useMemo } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';

import { Attribute, DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';

export const useDefaultAttributes = (organizationId: string, membershipId: string): [Attribute[], () => void] => {
  const {
    payload: defaultAttributes,
    query,
    error,
  } = useSuspenseQuery<Attribute[]>({
    endpoint: `/organization/${organizationId}/organizationMembership/${membershipId}/document-attribute`,
    method: 'GET',
    credentials: 'include',
  });
  return [defaultAttributes && !error ? defaultAttributes : [], query];
};
export const useDefaultDocumentAttributes = (
  organizationId: string,
  membershipId: string
): [DocumentAttribute[], () => void] => {
  const [attributes, reload] = useDefaultAttributes(organizationId, membershipId);
  const documentAttributes = useMemo(
    (): DocumentAttribute[] => attributes.map(({ id, ...attr }) => attr),
    [attributes]
  );
  return [documentAttributes, reload];
};
