export const fragmentFromString = (str: string) => {
  const temp = document.createElement('template');
  temp.innerHTML = str;
  return temp.content;
};

export const fragmentToString = (fragment: DocumentFragment): string => {
  const tmpEl = document.createElement('div');
  tmpEl.appendChild(fragment);
  return tmpEl.innerHTML;
};
