import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '30%',
      minWidth: '280px',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);
interface TutorialStepperProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  handleReset: () => void;
  setActiveStep: (step: number) => void;
}

export const TutorialStepper: FC<TutorialStepperProps> = ({
  activeStep,
  handleNext,
  handleBack,
  handleReset,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const stepsTopic = useMemo(
    () => [
      t('tutorial.platform_presentation', 'Platform presentation'),
      t('tutorial.organizations', 'Organizations'),
      t('tutorial.agreement_templates', 'Agreement Templates'),
      t('tutorial.attributes', 'Attributes'),
      t('tutorial.how_to_create_doc_template', 'How to create document template?'),
      t('tutorial.how_to_generate_agreement', 'How to generate agreement?'),
      t('tutorial.agreement_panel', 'Agreement panel'),
      t('tutorial.how_to_sign_agreement', 'How to sign agreement?'),
      t('tutorial.agreement_amendment', 'Agreement amendment'),
    ],
    [t]
  );

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsTopic.map((label, index) => (
          <Step key={label}>
            <StepLabel onClick={() => setActiveStep(index)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
