import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import React, { FC } from 'react';
import { Action, useMutation } from 'react-fetching-library';
import { useHistory } from 'react-router';

import { Box, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { OrganizationConfiguration } from '../containers/OrganizationDetails';

interface DemoParametersProps {
  organizationConfig?: OrganizationConfiguration;
  reloadConfig: () => void;
}

export const DemoParameters: FC<DemoParametersProps> = ({ organizationConfig, reloadConfig }) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    organizationConfig?.expirationDate ? new Date(organizationConfig.expirationDate) : null
  );
  const [demo, setDemo] = React.useState<boolean>(Boolean(organizationConfig?.demo));

  const history = useHistory();

  const { mutate } = useMutation((action: Action) => action);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const saveData = () => {
    const method = organizationConfig && organizationConfig.id ? 'PUT' : 'POST';
    const url =
      organizationConfig && organizationConfig.id
        ? '/organization_configuration/update'
        : '/organization_configuration/create';

    const saveBody = {
      ...(organizationConfig && organizationConfig),
      organizationId: organizationConfig?.organizationId ?? history.location.pathname.split('/')[4],
      demo,
      expirationDate: selectedDate?.toISOString(),
    };

    const saveAction: Action = {
      endpoint: url,
      method,
      credentials: 'include',
      body: saveBody,
    };

    mutate(saveAction).then(() => reloadConfig());
  };

  React.useEffect(() => {
    saveData();
  }, [selectedDate, demo]);

  return (
    <>
      <Box my={2}>
        <Typography variant="h5">Demo parameters</Typography>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            disabled={!demo}
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Expiration date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <FormControlLabel
          style={{ marginLeft: '16px' }}
          control={<Checkbox checked={demo} onChange={(e) => setDemo(e.target.checked)} name="checkedA" />}
          label="Demo mode"
        />
      </Box>
    </>
  );
};
