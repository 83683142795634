/* eslint-disable react/prop-types */
import { isEqual } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent, CardHeader, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/Power';

import { AttributeInput } from './AttributeInput';
import { groupAttributes } from 'helpers/leftPanelHelpers';
import { ReactComponent as Remove } from 'images/remove.svg';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { ConfirmButton } from 'shared/components/Confirm/ConfirmButton';
import { getDocumentAttributeId } from 'shared/helpers/attributes';
import { useAttributeTranslation } from 'shared/hooks/useAttributeTranslation';

export interface AttributesSectionProps {
  attributes: DocumentAttribute[];
  handleRemoveAttribute?: (value: DocumentAttribute) => void;
  removable?: boolean;
  attrValuesDisabled?: boolean;
  updateAttribute: (attribute: DocumentAttribute) => void;
}

const AttributesSection: FC<AttributesSectionProps> = ({ attributes, ...rest }) => {
  const { t } = useTranslation();
  const categorizedAttributes = groupAttributes(attributes);

  const attributeGroups = Object.keys(categorizedAttributes).map((key: string) => {
    return (
      <Box my={2} key={key}>
        <AttributesGroup sectionName={key} attributes={categorizedAttributes[key]} {...rest} />
      </Box>
    );
  });

  if (attributeGroups.length === 0) {
    return <Typography>{t('attributes_section.no_attributes', 'The attributes are empty')}</Typography>;
  }
  return <>{attributeGroups}</>;
};

export default AttributesSection;

interface AttributesGroupProps extends AttributesSectionProps {
  sectionName: string;
}

const AttributesGroup: FC<AttributesGroupProps> = React.memo(
  ({ handleRemoveAttribute, removable, attributes, sectionName, attrValuesDisabled, updateAttribute }): JSX.Element => {
    const { t } = useTranslation();
    const { translateAttributeCategory } = useAttributeTranslation();
    const inputs =
      attributes &&
      attributes.map((attr: DocumentAttribute) => {
        return (
          <Grid item key={getDocumentAttributeId(attr)}>
            <AttributeInput
              removable={removable}
              handleRemoveAttribute={handleRemoveAttribute}
              attrValuesDisabled={attrValuesDisabled}
              updateAttribute={updateAttribute}
              {...attr}
            />
          </Grid>
        );
      });

    const isSmartAttributes = React.useMemo(() => {
      return ['Contractor'].includes(sectionName);
    }, [sectionName]);

    const smartAttributeStyles = React.useMemo(
      () =>
        isSmartAttributes
          ? { position: 'relative' as 'relative', border: '2px solid orange', minWidth: '300px' }
          : { position: 'relative' as 'relative' },
      [isSmartAttributes]
    );

    const smartAttributeLabel = React.useMemo(() => {
      return isSmartAttributes ? (
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            fontSize: '10px',
            lineHeight: '20px',
            textAlign: 'center',
          }}
        >
          <Tooltip
            title={`${t(
              'attributes_section.smart_attributes_desc',
              'Enter a numeric attribute (e.g. tax number) to automatically fill in the rest of the fields'
            )}`}
          >
            <div
              style={{
                height: '18px',
                padding: '4px 0px 4px 8px',
                display: 'flex',
                alignItems: 'center',
                border: '2px solid #D2D0CC',
                borderRadius: '5px',
                backgroundColor: '#F9F7F5',
              }}
            >
              <p style={{ margin: '0' }}>{t('attributes_section.smart_attributes', 'Smart attributes')}</p>
              <div style={{ color: 'orange', fill: 'orange', display: 'flex' }}>
                <PowerIcon color="inherit" />
              </div>
            </div>
          </Tooltip>
        </div>
      ) : (
        <></>
      );
    }, [isSmartAttributes]);

    return (
      <Card elevation={0} style={smartAttributeStyles}>
        {smartAttributeLabel}
        <CardHeader
          title={
            <>
              <span>
                {translateAttributeCategory(sectionName)}{' '}
                {removable ? (
                  <ConfirmButton
                    button={IconButton}
                    title={t(
                      'attributes_section.category_confirm_delete',
                      'Are you sure to delete category {{category}}?',
                      {
                        category: sectionName,
                      }
                    )}
                    onConfirm={(): void => {
                      attributes.forEach((attr) => {
                        handleRemoveAttribute?.(attr);
                      });
                    }}
                  >
                    <Remove />
                  </ConfirmButton>
                ) : null}
              </span>
            </>
          }
        />
        <CardContent>
          <Grid container direction="column" spacing={2}>
            {inputs}
          </Grid>
        </CardContent>
      </Card>
    );
  },
  isEqual
);
