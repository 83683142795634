import { groupBy } from 'lodash';
import { useSuspenseQuery } from 'react-fetching-library';

import { useAuth } from '../auth';
import { MaybeFetchError } from '../models/FetchError';
import { OrganizationDto } from '../models/OrganizationDto';
import { UserMembershipDto } from '../models/UserMembershipDto';
import { getUserWithHighestRole } from '../user/userGroup';

export const findMembershipsByHighestRole = (
  userMemberships?: MaybeFetchError<UserMembershipDto[]>,
  organizations?: MaybeFetchError<OrganizationDto[]>
) => {
  if (!Array.isArray(userMemberships)) {
    return [];
  }

  // sometimes userMembership.companyName is an empty string, it's probably some legacy
  const userMembershipsWithCorrectCompanyNames = userMemberships?.map((membership) => ({
    ...membership,
    companyName: Array.isArray(organizations)
      ? organizations.find((org) => org.id === membership.organizationId)?.name
      : membership.companyName,
  }));

  return Object.values(groupBy(userMembershipsWithCorrectCompanyNames, 'organizationId')).map((groupedMemberships) => {
    return getUserWithHighestRole(groupedMemberships);
  });
};

export const useMemberships = () => {
  const { user } = useAuth();

  const { payload: organizations } = useSuspenseQuery<MaybeFetchError<OrganizationDto[]>>({
    endpoint: '/organization?',
    method: 'GET',
    credentials: 'include',
  });

  const { payload: userMemberships } = useSuspenseQuery<MaybeFetchError<UserMembershipDto[]>>({
    endpoint: `/orgmembership/user?id=${user?.userId}`,
    method: 'GET',
    credentials: 'include',
  });

  return findMembershipsByHighestRole(userMemberships, organizations);
};
