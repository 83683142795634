import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { axios } from '../../api';
import { LoadingButton } from '../../components/LoadingButton';
import { UploadDropzone } from '../../components/UploadDropzone';
import { SchemaOf, array, bool, boolean, mixed, object, string } from '../../libs/yup';
import { AttributeType } from '../../models/Enums';
import { Attribute } from '../../models/documentation/sub-models/attribute/Attribute';
import { PostGuestExternalShareAttributesDto } from '../../models/postGuestExternalShareAttributesDto';
import Loader from '../../shared/components/Loader/Loader';
import { getAttributeBooleanTK } from '../../shared/helpers/translationKeys';
import { useQueryParams } from '../../shared/hooks/useQueryParams';
import { GuestPageWrapper } from './GuestPageWrapper';

export function checkIfFilesAreTooBig(files: File[] = []): boolean {
  const totalSize = files.reduce((acc, file) => {
    return acc + file.size;
  }, 0);

  return totalSize / 1024 / 1024 < 10;
}

export function checkIfFilesAreCorrectType(files: File[] = []): boolean {
  return files.every((file) => file.type === 'application/pdf');
}

const attributeSchema = object().shape({
  id: string().required('validation.required'),
  value: string().required('validation.required'),
});

const validationSchema: SchemaOf<PostGuestExternalShareAttributesDto> = object().shape({
  attributes: array().of(attributeSchema).required('validation.required'),
  comment: string().trim(),
  files: mixed()
    .test('areFilesNotTooBig', 'guestExternalShare.validation.fileTooBig', checkIfFilesAreTooBig)
    .test('areFilesInCorrectFormat', 'guestExternalShare.validation.fileIncorrectFormat', checkIfFilesAreCorrectType),
  acceptTerms: boolean().required('Prosimy o zapoznanie się i zaakceptowanie regulaminu oraz polityki prywatności'),
});

interface Props {}

export const GuestExternalShareAgreement: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const translateIfExists = (value?: string) => value && t(value);
  const search = useQueryParams();

  const token = search.get('token');

  const { data, status } = useQuery<Attribute[]>({
    queryKey: ['externalShare'],
    queryFn: () => {
      return axios.get<Attribute[]>(`/guest/share/external/attributes?token=${token}`).then((res) => res.data);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!token,
  });
  const mutation = useMutation<unknown, unknown, PostGuestExternalShareAttributesDto>({
    mutationFn: (body) => {
      const formData = new FormData();

      formData.append('attributes', JSON.stringify(body.attributes));

      if (body.comment) {
        formData.append('comment', body.comment);
      }

      if (body.files) {
        Array.from(body.files).forEach((file) => {
          formData.append('files', file);
        });
      }

      return axios.post(`/guest/share/external/attributes?token=${token}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onError: () => {
      enqueueSnackbar(t('guestExternalShare.sendError'), { variant: 'error' });
    },
  });

  const form = useForm<PostGuestExternalShareAttributesDto>({
    reValidateMode: 'onChange',
    defaultValues: {
      attributes: [],
      comment: '',
      files: [],
      acceptTerms: false,
    },
    resolver: yupResolver(validationSchema),
  });

  if (mutation.status === 'success') {
    return (
      <GuestPageWrapper>
        <Alert>
          <AlertTitle>{t('guestExternalShare.sendSuccessTitle')}</AlertTitle>
          {t('guestExternalShare.sendSuccessBody')}
        </Alert>
      </GuestPageWrapper>
    );
  }

  if (!token || status === 'error') {
    return (
      <GuestPageWrapper>
        <Alert severity="error">
          <AlertTitle>{t('guestExternalShare.fetchAttributesErrorTitle')}</AlertTitle>
          {t('guestExternalShare.fetchAttributesErrorBody')}
        </Alert>
      </GuestPageWrapper>
    );
  }

  if (status === 'loading') {
    return (
      <GuestPageWrapper>
        <Loader />
      </GuestPageWrapper>
    );
  }

  return (
    <GuestPageWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          maxWidth: '400px',
          minWidth: '300px',
        }}
      >
        <Typography variant="h5" gutterBottom={true}>
          {t('guestExternalShare.title')}
        </Typography>
        <Typography gutterBottom={true}>{t('guestExternalShare.titleHint')}</Typography>
        {Object.entries(
          groupBy(
            data?.map((attr, _index) => ({ ...attr, _index })),
            'category'
          )
        ).map(([categoryName, attrs]) => (
          <Card elevation={0}>
            <CardHeader title={categoryName} titleTypographyProps={{ variant: 'h6' }} />
            <CardContent>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  minWidth: '350px',
                }}
              >
                {attrs.map((attr) => {
                  const menuItems =
                    attr.type === AttributeType.Boolean
                      ? ['true', 'false']
                          .map((v) => ({
                            label: t(getAttributeBooleanTK(v)),
                            value: v,
                          }))
                          .concat({
                            label: '',
                            value: '',
                          })
                      : null;

                  return (
                    <React.Fragment key={attr.id}>
                      <Controller
                        name={`attributes[${attr._index}].value`}
                        control={form.control}
                        defaultValue={attr.value || ''}
                        render={({ onChange, onBlur, value }) => (
                          <TextField
                            disabled={mutation.status === 'loading'}
                            type={attr.type === AttributeType.Boolean ? 'text' : attr.type}
                            select={attr.type === AttributeType.Boolean}
                            fullWidth={true}
                            size={'small'}
                            label={attr.type !== AttributeType.Date ? attr.name : null}
                            error={Boolean(form.errors?.attributes?.[attr._index]?.value)}
                            helperText={translateIfExists(form.errors?.attributes?.[attr._index]?.value?.message)}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            onBlur={onBlur}
                            value={value}
                            variant="outlined"
                          >
                            {menuItems?.map(({ label, value }) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      <Controller
                        name={`attributes[${attr._index}].id`}
                        defaultValue={attr.id}
                        control={form.control}
                        render={({ value }) => <TextField type="hidden" disabled={true} value={value} />}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </CardContent>
          </Card>
        ))}

        <Controller
          name="comment"
          control={form.control}
          render={({ onChange, onBlur, value }) => (
            <TextField
              disabled={mutation.status === 'loading'}
              error={Boolean(form.errors?.comment)}
              helperText={translateIfExists(form.errors?.comment?.message)}
              label={t('guestExternalShare.comment')}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              value={value}
              variant="outlined"
              multiline={true}
              rows={5}
            />
          )}
        />

        <Controller
          name="files"
          control={form.control}
          render={({ onChange }) => (
            <>
              <UploadDropzone
                disabled={mutation.status === 'loading'}
                onChange={(files) => {
                  onChange(files);
                }}
              />
              {Boolean(form.errors?.files) && (
                <FormHelperText error>{translateIfExists((form.errors?.files as any)?.message)}</FormHelperText>
              )}
            </>
          )}
        />

        <>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <span>
                Akceptuję{' '}
                <Link href="https://www.pactt-technology.com/regulamin-platformy-pactt/" target="_">
                  regulamin i politykę prywatności
                </Link>
              </span>
            }
            name="acceptTerms"
            inputRef={form.register({
              required: 'Prosimy o zapoznanie się i zaakceptowanie regulaminu oraz polityki prywatności',
            })}
          />
          <span className="text-invalid">{form.errors?.acceptTerms?.message}</span>
        </>

        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!form.watch('acceptTerms')}
          loading={mutation.status === 'loading'}
          onClick={form.handleSubmit(
            (data) => {
              mutation.mutate(data);
            },
            (e) => {
              console.error(e);
            }
          )}
        >
          {t('guestExternalShare.confirmButton')}
        </LoadingButton>
      </div>
    </GuestPageWrapper>
  );
};
