import { keyBy, omit } from 'lodash';
import uniq from 'lodash/uniq';
import { useCallback, useEffect, useMemo } from 'react';
import { Action, Client, useClient, useMutation, useQuery, useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useRouteMatch } from 'react-router';

import { Recipient, Sender, SignerDto } from './model';
import { API_BASE_URL } from 'api/config';
import { useOrganization } from 'contexts/organization';
import { DocCategory } from 'models/DocCategory';
import { Documentation } from 'models/documentation/Documentation';
import { DocumentationDocument } from 'models/documentation/sub-models/document/DocumentationDocument';
import { Paths } from 'router/paths';
import { Batch, BatchesForm } from 'shared/components/BatchesDialogForm/BatchDialogForm';
import { fetchDocumentPdfWithStyles } from 'shared/helpers/document/fetchPdf';
import { mergePdfs } from 'shared/helpers/document/mergePdfs';
import { isOwnerGroup } from 'user/userGroup';
import { Language } from 'config/language';
import { SigningProvider } from 'config/signing-provider';

export const useDocumentationQueryAction = (documentationId: string, skipErrorNotification: boolean = false) => {
  const {
    params: { organizationId, membershipId },
  } = useRouteMatch<{ organizationId: string; membershipId: string }>();

  const action = useMemo(
    (): Action => ({
      endpoint: `/documentation/${organizationId}/${membershipId}/${documentationId}`,
      method: 'GET',
      credentials: 'include',
      skipErrorNotification,
    }),
    [organizationId, membershipId, documentationId, skipErrorNotification]
  );

  return action;
};

export const useDocumentationQuery = (
  documentationId: string,
  validCodes: number[] = [200],
  skipErrorNotification = false
) => {
  const action = useDocumentationQueryAction(documentationId, skipErrorNotification);

  const { membershipId, organizationId } = useOrganization();
  const result = useSuspenseQuery<Documentation>(action);
  const history = useHistory();
  const { status } = result;

  useEffect(() => {
    if (!status || !validCodes.includes(status)) {
      history.push(generatePath(Paths.DOCUMENTATION_LIST, { membershipId, organizationId }));
    }
  }, [validCodes, history, membershipId, organizationId, status]);
  return result;
};
export const useDocumentationDocumentAction = (
  documentationId: string,
  documentId: string,
  method: Action['method']
) => {
  const {
    params: { organizationId, membershipId },
  } = useRouteMatch<{ organizationId: string; membershipId: string }>();

  const action = useMemo(
    (): Action => ({
      endpoint: `/documentation/${organizationId}/${membershipId}/${documentationId}/document/${documentId}`,
      method,
      credentials: 'include',
    }),
    [documentId, method, documentationId, membershipId, organizationId]
  );
  return action;
};
export const useDocumentationDocumentQuery = (documentationId: string, documentId: string) => {
  const action = useDocumentationDocumentAction(documentationId, documentId, 'GET');
  return useSuspenseQuery(action);
};

export const useDocumentationDocumentUpdate = (documentationId: string, documentId: string) => {
  const {
    params: { organizationId, membershipId },
  } = useRouteMatch<{ organizationId: string; membershipId: string }>();

  const actionCreator = useCallback(
    (document: DocumentationDocument): Action => ({
      endpoint: `/documentation/${organizationId}/${membershipId}/${documentationId}/document/${document.id}`,
      method: 'PUT',
      credentials: 'include',
      body: document,
    }),
    [documentationId, membershipId, organizationId]
  );

  return useMutation(actionCreator);
};

export const useDocumentationUpdateMutation = (
  organizationId: string,
  membershipId: string,
  documentationId: string
) => {
  return useMutation((body: Documentation) => ({
    endpoint: `/documentation/${organizationId}/${membershipId}/${documentationId}/update`,
    method: 'POST',
    credentials: 'include',
    body: omit(body, ['rootDocumentationId', 'parentDocumentationId', 'subDocumentationId', 'activeFlowId']),
  }));
};

interface ShareViaEmailMutationData {
  emails: string[];
  files: Array<{ blob: Blob; name: string }>;
}

const prepareBatchFileName = (documentationName: string, batchName: string) => `${documentationName}_${batchName}.pdf`;

export interface ShareViaEmailForm {
  batches: Batch[];
  users: { email: string }[];
}
export const useShareViaEmail = (documentation: Documentation) => {
  const { organizationId, membershipId } = useOrganization();
  const client = useClient();
  const { mutate } = useMutation((data: ShareViaEmailMutationData) => {
    const formData = new FormData();
    formData.append(
      'request',
      new Blob(
        [
          JSON.stringify({
            batches: [
              {
                recipients: data.emails,
                filenames: data.files.map((f) => f.name),
              },
            ],
          }),
        ],
        { type: 'application/json' }
      )
    );
    data.files.forEach(({ blob, name }) => {
      formData.append('file', blob, name);
    });
    return {
      endpoint: '/share',
      method: 'POST',
      credentials: 'include',
      body: formData,
    };
  });
  return (data: ShareViaEmailForm) => {
    return batchesToPdf(client, organizationId, membershipId, data.batches, documentation).then((files) => {
      const emails = data.users.map((u) => u.email);

      return mutate({
        files: files.map((b) => ({ ...b, name: prepareBatchFileName(documentation.name, b.name) })),
        emails,
      });
    });
  };
};

const batchesToPdf = async (
  client: Client,
  organizationId: string,
  membershipId: string,
  batches: BatchesForm['batches'],
  documentation: Documentation
) => {
  const ids = batches.map((b) => b.documents.map((d) => d.id)).flatMap((d) => d);
  const uniqueIds = uniq(ids);

  const documents = documentation!.documents.filter((d) => uniqueIds.includes(d.id));

  const docs: DocumentationDocument[] = documents;

  const pdfCategories = [DocCategory.PDFAttachment, DocCategory.PDFReferenceDocument];

  const getDocumentFileUrl = (d: DocumentationDocument) => {
    const resourceUrl = `/documentation/${organizationId}/${membershipId}/${documentation.id}/${d.id}/file`;
    return `${API_BASE_URL}${resourceUrl}`;
  };

  const fetchPdfDocument = (d: DocumentationDocument) =>
    fetch(getDocumentFileUrl(d), { credentials: 'include' })
      .then((r) => r.arrayBuffer())
      .then((blob) => ({ name: d.id, blob }));

  const pdfDocuments = docs.filter((d) => pdfCategories.includes(d.category));
  const regularDocuments = docs
    .filter((d) => !pdfCategories.includes(d.category))
    .map((regularDoc) => {
      const { commentThreads, ...rest } = regularDoc;
      return rest;
    });

  return Promise.all([
    Promise.all(pdfDocuments.map(fetchPdfDocument)),
    fetchDocumentPdfWithStyles(regularDocuments, client, organizationId, membershipId),
  ])
    .then(([pdfBlobs, docBlobs]) => {
      return pdfBlobs.concat(docBlobs);
    })
    .then((blobs) => {
      const indexed = keyBy(blobs, 'name');

      return Promise.all(
        batches.map((b) => {
          const pdfs = b.documents.map((d) => indexed[d.id].blob);
          return mergePdfs(pdfs).then((blob) => {
            return {
              name: b.name,
              blob,
            };
          });
        })
      );
    });
};
//@TODO: Get rid of this, once the PDF  process is supported by the backend
export const useSign = (documentation: Documentation) => {
  const { organizationId, membershipId } = useOrganization();
  const client = useClient();
  const { t } = useTranslation();

  const { mutate: startEsign } = useMutation(
    ({
      blobs,
      packs,
      signers,
      viewers,
      title,
      message,
      isSignaturesOrder,
      processLanguage,
      signingProvider,
      senderType,
    }: {
      blobs: Array<{ blob: Blob; name: string }>;
      packs: Array<{ name: string; filenames: string[] }>;
      signers: Array<Omit<SignerDto, 'isSignerAsRepresentative'>>;
      viewers: Array<Omit<Recipient, 'isSignerAsRepresentative'>>;
      title: string;
      message: string;
      isSignaturesOrder: boolean;
      processLanguage: Language;
      signingProvider: SigningProvider;
      senderType: Sender;
    }) => {
      const formData = new FormData();

      const createDocumentRequest = {
        title,
        message,
        signers,
        viewers,
        processLanguage,
        isSignaturesOrder,
        senderType,
        packs,
        signingProvider,
        signingPriceType: signingProvider == SigningProvider.AUTENTI ? 'AUTENTI_BASIC' : 'SIGNIUS_EASY'
      };

      blobs.forEach(({ name, blob }: any) => {
        formData.append('signingFiles', blob, name);
      });

      const request = {
        organizationId: organizationId,
        organizationMembershipId: membershipId,
        legalAgreementId: documentation.id,
      };
      formData.append('signingRequestMeta', new Blob([JSON.stringify(request)], { type: 'application/json' }));
      formData.append(
        'signingRequestInput',
        new Blob([JSON.stringify(createDocumentRequest)], { type: 'application/json' })
      );

      return {
        endpoint: '/signing/start-legal-agreement-signing-process',
        method: 'POST',
        credentials: 'include',
        body: formData,
      };
    }
  );

  return (data: BatchesForm) => {
    const { batches, signers = [], viewers = [], sender, isSignaturesOrder, title, message } = data;

    const batchList = batches.map((b) => ({
      name: b.name,
      filenames: [prepareBatchFileName(documentation.name, b.name)],
    }));

    return batchesToPdf(client, organizationId, membershipId, batches, documentation).then((blobs) =>
      startEsign({
        blobs: blobs.map(({ blob, name }) => ({ blob, name: prepareBatchFileName(documentation.name, name) })),
        packs: batchList,
        signers: signers.map((e) => omit(e, 'isSignerAsRepresentative')),
        viewers: viewers.map((v) => omit(v, 'isSignerAsRepresentative')),
        senderType: sender ? 'SIGNER' : 'VIEWER',
        title,
        message,
        isSignaturesOrder,
        processLanguage: data.processLanguage,
        signingProvider: data.signingProvider
      }).then((data: any) => {
        if (data.error) {
          let payloadAsJsonString = JSON.stringify(data.payload);
          throw new Error(payloadAsJsonString);
        }
        return data;
      })
    );
  };
};

export const useFlowEsign = (documentation: Documentation, flowId: string, autentiSignRequestId?: string | null) => {
  const { organizationId, membershipId } = useOrganization();
  const client = useClient();
  const { t } = useTranslation();

  const { mutate: startEsign } = useMutation(
    ({
      blobs,
      filenames,
      signers,
      viewers,
      sender,
      title,
      message,
      isSignaturesOrder,
      id,
      senderMembershipId,
    }: {
      blobs: Array<{ blob: Blob; name: string }>;
      filenames: Array<{ batchName: string; listFilename: string[] }>;
      signers: Array<Omit<SignerDto, 'isSignerAsRepresentative'>>;
      viewers: Array<Omit<Recipient, 'isSignerAsRepresentative'>>;
      sender: Sender;
      title: string;
      message: string;
      isSignaturesOrder: boolean;
      id?: string | null;
      senderMembershipId?: string | null;
    }) => {
      // const formData = new FormData();

      const temporarySigingData = {
        title,
        message,
        sender,
        signers,
        viewers,
        senderMembershipId,
      };

      return {
        endpoint: `/esign/${organizationId}/${membershipId}/${flowId}/autenti-document-request`,
        method: 'PUT',
        credentials: 'include',
        body: {
          createDocumentRequest: temporarySigingData,
          filenames: filenames,
          signatureOrder: isSignaturesOrder,
          id,
        },
      };
    }
  );

  return (data: BatchesForm) => {
    const { batches, signers = [], viewers = [], sender, isSignaturesOrder, title, message, senderMembershipId } = data;

    const batchList = batches.map((b) => ({
      batchName: b.name,
      listFilename: b.documents.map((d) => d.id),
    }));

    return batchesToPdf(client, organizationId, membershipId, batches, documentation).then((blobs) =>
      startEsign({
        blobs: blobs.map(({ blob, name }) => ({ blob, name: prepareBatchFileName(documentation.name, name) })),
        filenames: batchList,
        signers: signers.map((e) => omit(e, 'isSignerAsRepresentative')),
        viewers: viewers.map((v) => omit(v, 'isSignerAsRepresentative')),
        sender: sender ? 'SIGNER' : 'VIEWER',
        title,
        message,
        isSignaturesOrder,
        id: autentiSignRequestId,
        senderMembershipId,
      }).then((data: any) => {
        if (data.error) {
          throw new Error(`${t('common.error.unknown')}.\n ${JSON.stringify(data.payload, null, 2)}`);
        }
        return data;
      })
    );
  };
};
//@TODO: Get rid of this, once the PDF  process is supported by the backend
export const useFlowSign = (documentation: Documentation, flowId: string) => {
  const { organizationId, membershipId } = useOrganization();
  const { t } = useTranslation();
  const client = useClient();

  const { mutate: startEsign } = useMutation(
    ({
      blobs,
      filenames,
    }: {
      blobs: Array<{ blob: Blob; name: string }>;
      filenames: Array<{ batchName: string; listFilename: string[] }>;
    }) => {
      const formData = new FormData();

      blobs.forEach(({ name, blob }: any) => {
        formData.append('file', blob, name);
      });

      formData.append('filenames', new Blob([JSON.stringify(filenames)], { type: 'application/json' }));

      return {
        endpoint: `/esign/${organizationId}/${membershipId}/flow/${flowId}`,
        method: 'POST',
        credentials: 'include',
        body: formData,
      };
    }
  );

  return (batches: Batch[]) => {
    // const documents = ids.map((id) => documentation.documents.find((d) => d.id === id)!);

    const batchList = batches.map((b) => ({
      batchName: b.name,
      listFilename: [prepareBatchFileName(documentation.name, b.name)],
    }));

    return batchesToPdf(client, organizationId, membershipId, batches, documentation)
      .then((blobs) =>
        startEsign({
          blobs: blobs.map(({ blob, name }) => ({ blob, name: prepareBatchFileName(documentation.name, name) })),
          filenames: batchList,
        }).then((data: any) => {
          if (data.error) {
            throw new Error(JSON.stringify(data.payload, null, 2));
          }
          return data;
        })
      )
      .catch((e) => {
        throw new Error(`${t('common.error.unknown')}.\n ${e.message}`);
      });
  };
};

export const useIsOwnerGroup = () => {
  const organization = useOrganization();

  return isOwnerGroup(organization?.userType);
};

export const useSelectedColumns = (organizationId: string, membershipId: string): [string[], () => void] => {
  const { payload: columns, query } = useQuery<{ selectedColumns: string[] | null }>({
    endpoint: `/organization/${organizationId}/${membershipId}/columns`,
    method: 'GET',
    credentials: 'include',
  });

  return [columns?.selectedColumns || [], query];
};
export const useSelectedColumnsMutation = (organizationId: string, membershipId: string) => {
  const actionCreator = useCallback(
    (body: string[]): Action => ({
      endpoint: `/organization/${organizationId}/${membershipId}/columns`,
      method: 'PUT',
      credentials: 'include',
      body: {
        selectedColumns: body,
      },
    }),
    [membershipId, organizationId]
  );

  return useMutation(actionCreator);
};

export const useCreateAmendmentQueryParams = () => {
  const {
    params: { rootDocumentationId, parentDocumentationId },
  } = useRouteMatch<{ rootDocumentationId?: string; parentDocumentationId?: string }>();
  const isAmendment = Boolean(rootDocumentationId && parentDocumentationId);

  return {
    isAmendment,
    params: { parentDocumentationId, rootDocumentationId },
  };
};
