import React, { FC, Suspense } from 'react';

import { Box } from '@material-ui/core';

import Loader from '../components/Loader/Loader';
import { AdditionalNavbarProvider } from './AdditionalNavbarProvider';
import { NotificationsContextProvider } from 'contexts/notifications';
import NavigationBar from 'shared/components/NavigationBar/NavigationBar';

export const DefaultLayout: FC = ({ children }) => {
  return (
    <div>
      <NotificationsContextProvider>
        <NavigationBar />
        <AdditionalNavbarProvider>
          <Box p={2.5} paddingTop={10} paddingLeft={7.5} style={{ overflowY: 'auto' }} className="content-placeholder">
            <Suspense fallback={<Loader />}>{children}</Suspense>
          </Box>
        </AdditionalNavbarProvider>
      </NotificationsContextProvider>
    </div>
  );
};
