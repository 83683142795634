import { useAuth } from 'auth';
import { FC } from 'react';
import React from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { Redirect, useLocation, useParams } from 'react-router';

import { UserType } from 'models/Enums';

interface OrganizationRedirectToMembershipParams {
  userType: UserType;
  organizationId: string;
}
export const OrganizationRedirectToMembership: FC = () => {
  const { user } = useAuth();

  const { organizationId, userType } = useParams<OrganizationRedirectToMembershipParams>();
  const { payload: userMemberships } = useSuspenseQuery<any[]>({
    endpoint: `/orgmembership/user?id=${user?.userId}`,
    method: 'GET',
    credentials: 'include',
  });

  const found = userMemberships?.find((m) => m.organizationId === organizationId && m.userType === userType);

  const location = useLocation();

  if (!found) {
    return <Redirect to="/" />;
  }
  const newLocation = {
    ...location,
    pathname: location.pathname.replace(/^(\/organization\/\w+?)\/(\w+)/, `$1/${found.id}`),
  };
  return <Redirect to={newLocation} />;
};
