import * as ActionType from '../action-types/user-action-types';
import { ActionEnum } from '../models/action.enums';

export const reduxAddUserDataAfterLogin = (payload: any): ActionType.AddUserDataAfterLogin => {
  return {
    type: ActionEnum.ADD_USER_DATA_AFTER_LOGIN,
    payload,
  };
};

export const reduxClearUserDataAfterLogout = (): ActionType.ClearUserDataAfterLogout => {
  return {
    type: ActionEnum.CLEAR_USER_DATA_AFTER_LOGOUT,
  };
};

export const reduxSyncUserData = (payload: any): ActionType.SyncUserData => {
  return {
    type: ActionEnum.SYNC_USER_DATA,
    payload,
  };
};
