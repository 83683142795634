import React, { FC, useState } from 'react';
import { useSuspenseQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import { Badge, Divider, IconButton, Tab, Tabs, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';

import { useNotifications } from '../../contexts/notifications';
import { MaybeFetchError } from '../../models/FetchError';
import { OrganizationDto } from '../../models/OrganizationDto';
import { fontSizeBig, orangeBad } from '../../styles';
import { GeneralNotificationItem } from './GeneralNotificationItem';
import { InvitationNotificationItem } from './InvitationNotificationItem';
import { TabPanel, TabsLabel } from './Tabs';
import { InvitationNotification, Notification, NotificationKind, NotificationStatus } from './model';

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      backgroundColor: orangeBad,
    },
  })
);

interface Props {}

export const NotificationsMenu: FC<Props> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [value, setValue] = useState(0);

  const styles = useStyles();

  const { t } = useTranslation();
  const { notifications, markAllAsRead, readNotification } = useNotifications();

  const { payload: organizations } = useSuspenseQuery<MaybeFetchError<OrganizationDto[]>>({
    endpoint: '/organization?',
    method: 'GET',
    credentials: 'include',
  });
  const organizationHashMap: Record<string, string> = Array.isArray(organizations)
    ? organizations.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    : {};

  const { invitations, otherNotifications } = notifications.reduce<{
    invitations: Notification[];
    otherNotifications: Notification[];
  }>(
    (acc, item) => {
      if ([NotificationKind.INVITE_TO_ORGANIZATION].includes(item.notificationType)) {
        return {
          ...acc,
          invitations: [...acc.invitations, item],
        };
      }

      return {
        ...acc,
        otherNotifications: [...acc.otherNotifications, item],
      };
    },
    { invitations: [], otherNotifications: [] }
  );

  return (
    <div>
      <Tooltip title={t<string>('notifications.tooltip', 'Notifications')} placement="bottom">
        <IconButton style={{ color: 'white' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
            invisible={!notifications.some((n) => n.status === NotificationStatus.UN_READ)}
            classes={{
              badge: styles.badge,
            }}
          >
            <NotificationsActiveRoundedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <div style={{ maxHeight: '400px', width: '600px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            <div style={{ fontWeight: 700, fontSize: fontSizeBig }}>
              {t<string>('notifications.title', 'Notifications')}
            </div>
            <div>
              <Button
                variant="outlined"
                size="small"
                onClick={markAllAsRead}
                color="primary"
                startIcon={<DoneAllIcon />}
              >
                {t('notifications.read-all', 'Mark all as read').toUpperCase()}
              </Button>
            </div>
          </div>
          <Tabs
            value={value}
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
              setValue(newValue);
            }}
          >
            <Tab
              label={
                <TabsLabel
                  title={t('notifications.general', 'General')}
                  count={otherNotifications.filter((n) => n.status === NotificationStatus.UN_READ).length}
                />
              }
            />
            <Tab
              label={
                <TabsLabel
                  title={t('notifications.invitations', 'Invitations')}
                  count={invitations.filter((n) => n.status === NotificationStatus.UN_READ).length}
                />
              }
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            {otherNotifications.length < 1 && (
              <div style={{ padding: '20px', textAlign: 'center' }}>{t('notifications.noNotifications')}</div>
            )}
            {otherNotifications.map((n) => (
              <React.Fragment key={n.id}>
                <GeneralNotificationItem
                  notification={n}
                  organizationName={organizationHashMap[n.organizationId]}
                  onRead={() => readNotification(n.id)}
                  closeMenu={() => setAnchorEl(null)}
                />
                <Divider />
              </React.Fragment>
            ))}
          </TabPanel>
          <TabPanel index={1} value={value}>
            {invitations.length < 1 && (
              <div style={{ padding: '20px', textAlign: 'center' }}>{t('notifications.noNotifications')}</div>
            )}
            {(invitations as InvitationNotification[]).map((n) => (
              <React.Fragment key={n.id}>
                <InvitationNotificationItem notification={n} />
                <Divider />
              </React.Fragment>
            ))}
          </TabPanel>
        </div>
      </Menu>
    </div>
  );
};
