import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { Box, TextField } from '@material-ui/core';

interface DocumentStyleFontFormProps {
  register: UseFormMethods['register'];
  loading: boolean;
}

export const DocumentStyleFontBaseFormFields: FC<DocumentStyleFontFormProps> = ({ register, loading }) => {
  return (
    <>
      {[
        {
          name: 'fontFamily',
          label: 'Font family',
        },
        {
          name: 'weight',
          label: 'Font weight',
        },
        {
          name: 'style',
          label: 'Font style',
        },
      ].map(({ name, label }) => {
        return (
          <Box my={2}>
            <TextField
              disabled={loading}
              variant="outlined"
              fullWidth
              label={label}
              name={name}
              inputRef={register()}
            />
          </Box>
        );
      })}
    </>
  );
};
