import React, { FC } from 'react';

import { Avatar } from '@material-ui/core';

import { violet } from '../../styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  );
};

interface TabLabelProps {
  title: React.ReactNode;
  count: number;
}

export const TabsLabel: FC<TabLabelProps> = (props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
      <div>{props.title}</div>
      {props.count > 0 && (
        <Avatar
          variant="rounded"
          style={{
            width: '15px',
            height: '15px',
            fontSize: '10px',
            fontWeight: 700,
            backgroundColor: violet,
          }}
        >
          {props.count}
        </Avatar>
      )}
    </div>
  );
};
