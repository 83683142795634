import uniqBy from 'lodash/uniqBy';

import { Attachment } from 'models/Attachment';
import { DocumentTemplate } from 'models/DocumentTemplate';
import { Documentation } from 'models/documentation/Documentation';
import { DocumentationStatus } from 'models/documentation/Documentation';
import { DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { DocumentationDocument } from 'models/documentation/sub-models/document/DocumentationDocument';

export const generateDisplayId = (mongoId: string): string => {
  return mongoId.slice(mongoId.length - 6).toUpperCase();
};

export const generateShortedDocName = (name: string | undefined): string => {
  if (name === undefined) {
    return '';
  } else if (name.length < 25) {
    return name;
  } else {
    return name.substring(0, 25) + '...';
  }
};

export const flattenAttributes = <T extends DocumentTemplate | DocumentationDocument>(
  templates: T[]
): DocumentAttribute[] =>
  uniqBy(
    templates.flatMap((e: T) => e.attributes),
    getDocumentAttributeId
  );

//agreement helpers

/** Returns list of attribute name ids that should be blocked in  */
export const getBlockedAttributes = (agreement: Documentation): string[] => {
  const blockedIDs: string[] = [];

  agreement.documents.forEach((doc: DocumentationDocument) => {
    const highDocStatus =
      doc.documentStatus === DocumentationStatus.SignedByTenant || doc.documentStatus === DocumentationStatus.Active;
    if (highDocStatus) {
      doc.attributes.forEach((el: DocumentAttribute) => {
        if (!blockedIDs.includes(getDocumentAttributeId(el))) {
          blockedIDs.push(getDocumentAttributeId(el));
        }
      });
    }
  });
  agreement.attachments.forEach((doc: Attachment) => {
    const highDocStatus =
      doc.status === DocumentationStatus.SignedByTenant || doc.status === DocumentationStatus.Active;
    if (highDocStatus) {
      doc.attributes.forEach((el: DocumentAttribute) => {
        if (!blockedIDs.includes(getDocumentAttributeId(el))) {
          blockedIDs.push(getDocumentAttributeId(el));
        }
      });
    }
  });
  return blockedIDs;
};
export const getDocumentAttributeId = (attr: Pick<DocumentAttribute, 'category' | 'name'>) =>
  `${attr.category}:${attr.name}`;
