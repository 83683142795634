import React, { FC, useCallback } from 'react';
import { Action, useBulkMutation } from 'react-fetching-library';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';

import { DocCategory } from 'models/DocCategory';
import { BusyButton } from 'shared/components/BusyButton/BusyButton';
import { getDocCategoryTK } from 'shared/helpers/translationKeys';

interface PdfModalUploadProps {
  onConfirm: (ids: string[]) => void;
  onCancel: () => void;
  endpoint: string;
}
interface UploadForm {
  files: FileList;
  category: DocCategory;
}

export const PdfModalUpload: FC<PdfModalUploadProps> = ({ onConfirm, onCancel, endpoint }) => {
  const { control, register, handleSubmit } = useForm<UploadForm>({
    defaultValues: {
      category: DocCategory.PDFAttachment,
    },
  });

  const { t } = useTranslation();

  const actionCreator = useCallback(
    ({ file, request }: { file: File; request: any }): Action => {
      const formData = new FormData();

      formData.append('file', file, file.name);
      formData.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }));
      return {
        endpoint,
        method: 'POST',
        credentials: 'include',
        body: formData,
      };
    },
    [endpoint]
  );
  const { loading, mutate } = useBulkMutation(actionCreator);

  const onSubmit = (form: UploadForm) => {
    if (loading || !form.files || form.files.length < 1) {
      return;
    }

    mutate(
      Array.from(form.files).map((file) => ({
        file,
        request: {
          docCategory: form.category,
        },
      }))
    ).then((responses) => {
      onConfirm(responses.map((r) => r?.payload?.id).filter(Boolean));
    });
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>{t('upload_pdf_dialog.title', 'Upload PDF files')}</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="category"
          rules={{
            required: true,
          }}
          defaultValue={DocCategory.PDFAttachment}
          as={
            <TextField
              disabled={loading}
              variant="outlined"
              label={t('upload_pdf_dialog.category_label', 'Document Category')}
              fullWidth
              select
            >
              <MenuItem value={DocCategory.PDFAttachment}>{t(getDocCategoryTK(DocCategory.PDFAttachment))}</MenuItem>
              <MenuItem value={DocCategory.PDFReferenceDocument}>
                {t(getDocCategoryTK(DocCategory.PDFReferenceDocument))}
              </MenuItem>
            </TextField>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={onCancel}>
          {t('upload_pdf_dialog.cancel', 'Cancel')}
        </Button>
        <BusyButton busy={loading} color="primary" variant="contained" component="label">
          {t('upload_pdf_dialog.select_files', 'Select Files')}

          <input
            name="files"
            multiple
            ref={register({
              required: true,
              validate: (v) => v && v.length > 0,
            })}
            onChange={handleSubmit(onSubmit)}
            type="file"
            accept="application/pdf"
            hidden
          />
        </BusyButton>
      </DialogActions>
    </Dialog>
  );
};
