import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import { AttributeEditing } from './AttributeEditing';
import { AttributeUI } from './AttributeUI';

export class Attribute extends Plugin {
  static get requires() {
    return [AttributeEditing, AttributeUI];
  }
}
