import { AttributeType } from 'models/Enums';
import { Attribute, DocumentAttribute } from 'models/documentation/sub-models/attribute/Attribute';
import { getDocumentAttributeId } from 'shared/helpers/attributes';

const birRelatedNames = ['Name', 'Seat Address', 'Post Address', 'NIP', 'REGON', 'KRS', 'Registry Court'];
const defaultAttributeCategory = 'Contractor';
export const defaultDocumentAttributes: Readonly<DocumentAttribute[]> = birRelatedNames.map(
  (name): DocumentAttribute => ({
    category: defaultAttributeCategory,
    description: '',
    name,
    type: AttributeType.Text,
    value: '',
    blocked: false,
  })
);
export const defaultAttributes: Readonly<Attribute[]> = defaultDocumentAttributes.map((m) => ({
  ...m,
  id: getDocumentAttributeId(m),
}));
