import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GroupIcon from '@material-ui/icons/Group';
import LanguageIcon from '@material-ui/icons/Language';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';

import { Paths } from '../../../../../router/paths';
import { SidePanelIcon } from './SidePanelIcon';

export const AdminIcons: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <SidePanelIcon
        tooltip={t('navigation_bar.organizations', 'Organizations')}
        icon={<GroupIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
        to={Paths.ADMIN_ORGANIZATIONS}
      />
      <SidePanelIcon
        tooltip={t('navigation_bar.global-config', 'Global Configuration')}
        icon={<LanguageIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
        to={Paths.ADMIN_GLOBAL_CONFIG}
      />
      <SidePanelIcon
        tooltip={t('mass_actions.page.title', 'Mass actions')}
        icon={<OfflineBoltIcon style={{ fill: 'inherit', stroke: 'inherit' }} color="inherit" />}
        to={Paths.ADMIN_MASS_ACTION}
        exact={true}
      />
    </>
  );
};
