import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC } from 'react';

const queryClient = new QueryClient();

interface Props {}

export const QueryProvider: FC<Props> = (props) => {
  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};
