import * as React from 'react';
import { FC } from 'react';

import { Grid, IconButton, makeStyles } from '@material-ui/core';

import { DocumentListItemModel, DocumentationListItemModel } from '../../../pages/TemplateManagement/model';
import { ReactComponent as Remove } from 'images/remove.svg';

interface ListItemProps<T> {
  onRemove?: (id: string) => void;
  actionButton: JSX.Element | null;
  item: T;
}

interface ListItemGenericProps extends ListItemProps<any> {
  className: string;
}

const useStyles = makeStyles(() => ({
  deleteIcon: {
    flexShrink: 0,
    height: 20,
  },
}));

const ListItem: FC<ListItemGenericProps> = ({ item, onRemove, actionButton, className }) => {
  const { name, id } = item;
  const styles = useStyles();

  return (
    <div>
      <Grid container justify="flex-end">
        <Grid item className={styles.deleteIcon}>
          {onRemove ? (
            <IconButton size="small" onClick={() => onRemove(id)}>
              <Remove />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
      <div className={className}>{actionButton}</div>
      <p>{name}</p>
    </div>
  );
};

export const DocumentListItem: FC<ListItemProps<DocumentListItemModel>> = (props) => (
  <ListItem {...props} className="templatesList__item__existingDoc" />
);
export const DocumentationListItem: FC<ListItemProps<DocumentationListItemModel>> = (props) => (
  <ListItem {...props} className="templatesList__item__existingTemplate" />
);
