import { useSnackbar } from 'notistack';
import React, { FC } from 'react';
import { useMutation } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';

import { Typography } from '@material-ui/core';

import { CreateFromTemplateArgs, createFromTemplate } from '../api';
import DocumentationCreateFromTemplate from '../components/DocumentationCreateFromTemplate';
import { useCreateAmendmentQueryParams } from '../hooks';
import { useOrganization } from 'contexts/organization';
import { Paths } from 'router/paths';

const DocumentationCreateFromTemplatePage: FC = () => {
  const { t } = useTranslation();
  const { organizationId, membershipId } = useOrganization();
  const history = useHistory();

  const { mutate: createFromTemplateMutation } = useMutation(createFromTemplate);
  const { isAmendment, params: amendmentParams } = useCreateAmendmentQueryParams();

  const snackbar = useSnackbar();
  const onConfirm = (id: string) => {
    const params: CreateFromTemplateArgs = { organizationId, membershipId, id };
    if (isAmendment) {
      params.parentDocumentationId = amendmentParams.parentDocumentationId!;
    }
    createFromTemplateMutation(params).then(({ payload: newDocumentation, error }) => {
      if (error) {
        snackbar.enqueueSnackbar(t('common.error.unknown'), { variant: 'error' });
        return;
      }
      history.push(
        isAmendment
          ? generatePath(Paths.DOCUMENTATION_AMENDMENT_DETAILS, {
              organizationId,
              membershipId,
              documentationId: amendmentParams.rootDocumentationId!,
              amendmentId: newDocumentation.id,
            })
          : generatePath(Paths.DOCUMENTATION_DETAILS, {
              organizationId,
              membershipId,
              documentationId: newDocumentation.id,
            })
      );
    });
  };

  const title = isAmendment
    ? t('documentation_create_from_template.amendment_title', 'Create Amendment From Template')
    : t('documentation_create_from_template.documentation_title', 'Create Documentation From Template');
  return (
    <>
      <Typography variant="h3">{title}</Typography>

      <DocumentationCreateFromTemplate onConfirm={onConfirm} />
    </>
  );
};

export default DocumentationCreateFromTemplatePage;
