import { base64Encode } from 'helpers/base64';

export const download = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.href = url;
  link.click();
  link.remove();
};
export const downloadBlob = (content: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(content);
  download(url, fileName);
  window.URL.revokeObjectURL(url);
};
export const downloadDataUrl = (content: string, fileName: string, mimeType = 'text/plain') => {
  download(`data:${mimeType};charset=UTF-8;base64,` + base64Encode(content), fileName);
};
