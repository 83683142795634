import { BatchDialogActionEnum, BatchDialogActionType } from './actions/actions.models';
import { BatchDialogFormState } from './flow.state';

const initialState: BatchDialogFormState = {
  isOpen: false,
  canOpen: false,
};

const batchDialogFormFlow = (
  state: BatchDialogFormState = initialState,
  action: BatchDialogActionType
): BatchDialogFormState => {
  switch (action.type) {
    case BatchDialogActionEnum.OPEN_BATCH_DIALOG:
      return { ...state, isOpen: true };

    case BatchDialogActionEnum.CLOSE_BATCH_DIALOG:
      return { ...state, isOpen: false };

    case BatchDialogActionEnum.ALLOW_OPEN_DIALOG:
      return { ...state, canOpen: true };

    case BatchDialogActionEnum.PROHIBIT_OPEN_DIALOG:
      return { ...state, canOpen: false };

    default:
      return state;
  }
};

export default batchDialogFormFlow;
