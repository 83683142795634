import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Backdrop, Box, Button, Fade, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { TutorialStepper } from './TutorialStepper';

const TutorialModal = (props: any) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const stepsTopic = useMemo(
    () => [
      t('tutorial.platform_presentation', 'Platform presentation'),
      t('tutorial.organizations', 'Organizations'),
      t('tutorial.agreement_templates', 'Agreement Templates'),
      t('tutorial.attributes', 'Attributes'),
      t('tutorial.how_to_create_doc_template', 'How to create document template?'),
      t('tutorial.how_to_generate_agreement', 'How to generate agreement?'),
      t('tutorial.agreement_panel', 'Agreement panel'),
      t('tutorial.how_to_sign_agreement', 'How to sign agreement?'),
      t('tutorial.agreement_amendment', 'Agreement amendment'),
    ],
    [t]
  );

  const tutorialVideos = useMemo(
    () => [
      'https://www.youtube.com/embed/0t9OuDnp2ok',
      'https://www.youtube.com/embed/U7EPOCX5n6M',
      'https://www.youtube.com/embed/QcTTgghNDXw',
      'https://www.youtube.com/embed/kdnjCRPmzqc',
      'https://www.youtube.com/embed/9xAlYnzMFHg',
      'https://www.youtube.com/embed/8uAf7A01sVw',
      'https://www.youtube.com/embed/abH8x2kKvBM',
      'https://www.youtube.com/embed/UzM2rxc-2Fw',
      'https://www.youtube.com/embed/1fyUMc1TTDc',
    ],
    []
  );
  // const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    props.open && setOpen(true);
  }, [props.open]);

  const handleClose = (): void => {
    props.setModalTutorialVisible(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="modal"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box display="flex">
          <TutorialStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleNext={handleNext}
            handleBack={handleBack}
            handleReset={handleReset}
          />
          <Box
            p={6}
            style={{
              borderRadius: '5px',
              minWidth: '583px',
              minHeight: '200px',
              backgroundColor: '#f9f7f5',
              position: 'relative',
            }}
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <CloseIcon className="modal__close" color="inherit" onClick={handleClose} />
            <p className="modalHelp__title">{stepsTopic[activeStep]}</p>
            <iframe
              width="560"
              height="315"
              src={tutorialVideos[activeStep]}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <Box display="flex" justifyContent="space-around">
              <Button disabled={activeStep === 0} onClick={handleBack}>
                {t('tutorial.previous_step', 'Previous step')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={activeStep === stepsTopic.length - 1 ? handleClose : handleNext}
              >
                {activeStep === stepsTopic.length - 1
                  ? t('tutorial.finish', 'Finish')
                  : t('documentation_create_new.next_step_button', 'Next')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TutorialModal;
